import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Checkbox, Chip, Divider, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	notQueueSelected = false,
	allQueueSelected = false,
	onChange,
}) => {
	const handleChange = e => {
		if (e.currentTarget.dataset.value === 'allQueueSelected') {
			onChange('allQueueSelected');
		} else if (e.currentTarget.dataset.value === 'notQueueSelected') {
			onChange('notQueueSelected');
		} else {
			onChange(e.target.value);
		}
	};

	return (
		<Box style={{ width: "100%" }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={() => {
						return (
							<>
								{i18n.t("ticketsQueueSelect.placeholder") + " "}
								{(selectedQueueIds.length > 0 || notQueueSelected) && 
									(<Chip size="small" label={
										(notQueueSelected ? selectedQueueIds.length + 1 : selectedQueueIds.length).toString()
									} color="secondary" />)
								}
							</>
						)
					}}
				>
					{<MenuItem dense key={'allQueueSelected'} value={'allQueueSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={allQueueSelected}
						/>
						<ListItemText primary={"Selecionar todos"} />
					</MenuItem>}

					{<MenuItem dense key={'notQueueSelected'} value={'notQueueSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={notQueueSelected}
						/>
						<ListItemText primary={"Sem Departamento"} />
					</MenuItem>}

					{<Divider></Divider>}

					{userQueues?.length > 0 && userQueues.map(queue => (

						<MenuItem dense key={queue.id} value={queue.id}>
							<Checkbox
								style={{
									color: queue.color,
								}}
								size="small"
								color="primary"
								checked={selectedQueueIds.indexOf(queue.id) > -1}
							/>
							<ListItemText primary={queue.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default TicketsQueueSelect;
