export const formatDateTime = (date) => {
  date = new Date(date);
	const year = date.getFullYear();
	const month = toLeadingZero(date.getMonth()+1);
	const day = toLeadingZero(date.getDate());
	const hours = toLeadingZero(date.getHours());
	const minutes = toLeadingZero(date.getMinutes());

	return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatDate = (date) => {
  const meses = [
    "janeiro", "fevereiro", "março", "abril",
    "maio", "junho", "julho", "agosto",
    "setembro", "outubro", "novembro", "dezembro"
  ];

  const dia = date.getDate();
  const mes = meses[date.getMonth()];
  const ano = date.getFullYear();

  return `${dia} de ${mes} de ${ano}`;
}

const toLeadingZero = (number) => {
	if (number < 10) {
		return `0${number}`;
	}
	return number;
}

export const shouldUpdateTicketList = (ticket, filters, user, searchParam) => {
  const { canSeeAllTickets, canSeeTicketsWithoutAssignment, id } = user || {};
  const filaDoTicketEstaDentroDoFiltroDasFilas = ticket.queueId ? filters.selectedQueueIds?.indexOf(ticket.queueId) > -1 : true;
  const usuarioDoTicketEstaDentroDoFiltroDosUsuarios = ticket.userId ? filters.selectedUserIds?.indexOf(ticket.userId) > -1 : true;
  const conexaoDoTicketEstaDentroDoFiltroDasConexoes = ticket.whatsappId ? filters.selectedConnectionsIds?.indexOf(ticket.whatsappId) > -1 : true;
  const ticketNaoTemFila = !ticket.queueId;
  const ticketNaoTemUsuario = !ticket.userId;
  const ehOMesmoUsuarioDoTicket = ticket.userId === id;

  // if (canSeeAllTickets && !showAll && !ehOMesmoUsuarioDoTicket) {
  //   return false;
  // }
  
  if (searchParam) {
    return false;
  }

  // if (!filters) {
	// 	return true;
	// }

	// const ticketAttributes = {
	// 	queueId: "selectedQueueIds",
	// 	userId: "selectedUserIds",
	// 	tagId: "selectedTagIds",
	// 	connectionId: "selectedConnectionsIds",
	//   };
	
	// for (const attr in ticketAttributes) {
	// 	if (ticket[attr] !== undefined) {
	// 		const filterList = ticketAttributes[attr];
	// 		if (filters[filterList] && filters[filterList].some(id => id === ticket[attr])) {
	// 			return true;
	// 		}
	// 	}
	// }

	// const notSelectedFilters = ["notQueueSelected", "notUserSelected", "notTagSelected", "notConnectionSelected"];
	// if (notSelectedFilters.some((filterName, index) => filters[filterName] 
	// 	&& !ticket[ticketAttributes[index]])) {
  // 		return true;
	// }

	// return false;

  if (!usuarioDoTicketEstaDentroDoFiltroDosUsuarios || 
    !filaDoTicketEstaDentroDoFiltroDasFilas || 
    !conexaoDoTicketEstaDentroDoFiltroDasConexoes) {
    return false;
  }

  if (!canSeeTicketsWithoutAssignment && ticketNaoTemUsuario && ticketNaoTemFila) {
    return false;
  }

  if (!canSeeAllTickets && !ehOMesmoUsuarioDoTicket && !ticketNaoTemUsuario) {
    return false
  }

  return true;

}

export const shouldNotifyPopOver = (ticket, user) => {
  const { canSeeAllTickets, canSeeTicketsWithoutAssignment, whatsapps, queues, id } = user || {};
  const queueIds = queues.map(queue => queue.id);
  const filaDoTicketEstaDentroDasFilasDoUsuario = ticket.queueId ? queueIds.indexOf(ticket.queueId) > -1 : true;
  const conexaoDoTicketEstaDentroDasConexoesDoUsuario = whatsapps.map(whatsapp => whatsapp.id).indexOf(ticket.whatsappId) > -1;
  const ticketNaoTemFila = !ticket.queueId;
  const ticketNaoTemUsuario = !ticket.userId;
  const ehOMesmoUsuarioDoTicket = ticket.userId === id;

  if (!conexaoDoTicketEstaDentroDasConexoesDoUsuario) {
    return false;
  }

  if (!canSeeTicketsWithoutAssignment && ticketNaoTemUsuario && ticketNaoTemFila) {
    return false;
  }

  if (!canSeeAllTickets && !ehOMesmoUsuarioDoTicket) {
    return false
  }

  if (!filaDoTicketEstaDentroDasFilasDoUsuario) {
    return false;
  }

  return true;

}