import React, { useState, createContext, useEffect } from "react";

const TicketsActionsContext = createContext();

const TicketsActionsProvider = ({ children }) => {
	const [selectMode, setSelectMode] = useState(false);
	const [ticketOpened, setTicketOpened] = useState(false);
	const [newMessage, setNewMessage] = useState("");

	return (
		<TicketsActionsContext.Provider
			value={{
				selectMode,
				setSelectMode,
				ticketOpened,
				setTicketOpened,
				newMessage, 
				setNewMessage
			}}
		>
			{children}
		</TicketsActionsContext.Provider>
	);
};

export { TicketsActionsContext, TicketsActionsProvider };