import React from "react";

import PDFViewer from "../PDFViewer";
import { Button, Divider, makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

const useStyles = makeStyles(() => ({
	container: {
		marginBottom: 5
	},
	downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "inherit",
    padding: 10
  }
}));

const PDFMessage = ({ mediaUrl, handleOpenPdfModal }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<PDFViewer pdfUrl={mediaUrl} width={300} isThumbnail/>
			<div className={classes.downloadMedia}>
				<Button
					startIcon={<GetApp />}
					color="primary"
					variant="outlined"
					target="_blank"
					href={mediaUrl}
				>
					Download
				</Button>
				<Button
					startIcon={<AspectRatioIcon />}
					color="primary"
					variant="outlined"
					target="_blank"
					onClick={() => handleOpenPdfModal(mediaUrl)}
				>
					Abrir
				</Button>
			</div>
			<Divider />
		</div>
	);
};

export default PDFMessage;
