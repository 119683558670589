import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import axios from "axios";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: "100%",
		height: "285px",
	},
}));

const ModalImageCors = ({ imageUrl }) => {
	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");

	useEffect(() => {
		if (!imageUrl) return;
		const fetchImage = async () => {
			const { data, headers } = await axios.get(imageUrl, {
				responseType: "blob",
				withCredentials: true,
    		crossDomain: true,
			});
			const imageExtension = imageUrl.match(/\.(jpeg|jpg|png|gif|bmp|webp)/i)[0];
			const type = getImageType(imageExtension);
			const fileName = `image${imageExtension}`;
			const imageBlob = new Blob([data], { type: headers["content-type"] });
			const imageFile = new File([imageBlob], fileName, { type: type });
			const url = window.URL.createObjectURL(imageFile);
			setBlobUrl(url);
			setFetching(false);
		};
		fetchImage();
	}, [imageUrl]);

	return (
		<ModalImage
			className={classes.messageMedia}
			smallSrcSet={fetching ? imageUrl : blobUrl}
			medium={fetching ? imageUrl : blobUrl}
			large={fetching ? imageUrl : blobUrl}
			alt="image"
		/>
	);
};

const getImageType = (ext) => {
	ext = ext === '.jpg' ? '.jpeg' : ext;
	return `image/${ext.replace(".", "")}`;
}

export default ModalImageCors;
