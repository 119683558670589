import React, { createContext } from "react";

import useAuth from "../../hooks/useAuth.js";
import useWhatsApps from "../../hooks/useWhatsApps";

const WhatsAppsContext = createContext();

const WhatsAppsProvider = ({ children }) => {
	const { user } = useAuth();
	const { loading, whatsApps } = useWhatsApps({companyId: user.companyId});

	return (
		<WhatsAppsContext.Provider value={{ whatsApps, loading }}>
			{children}
		</WhatsAppsContext.Provider>
	);
};

export { WhatsAppsContext, WhatsAppsProvider };
