import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import { Box, Button, ButtonGroup, Checkbox, Collapse, Divider, Fab, IconButton, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { FilterList, MoreVert } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { useLocation } from "react-router-dom";
import { TicketsActionsContext } from "../../context/TicketsActionsContext/TicketsActionsContext";
import ForumIcon from '@material-ui/icons/Forum';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import api from "../../services/api";
import TicketsUserSelect from "../TicketsUserSelect";
import TicketsTagSelect from "../TicketsTagSelect";
import TicketsConnectionSelect from "../TicketsConnectionSelect";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ptLocale from "date-fns/locale/pt";
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },
  ticketsWrapperContainer: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },
  tabsHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#eee",
  },
  tab: {
    minWidth: 120,
    width: 120,
  },
  ticketOptionsBox: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    padding: theme.spacing(1),
  },
  serachInputWrapper: {
    flex: 1,
    background: "#eee",
    borderRadius: 8,
    display: "flex",
    marginRight: theme.spacing(1),
  },
  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
    background: "#eee",
  },
  searchInput: {
    flex: 1,
    border: "none",
  },
  badge: {
    right: "-10px",
  },
  actionButtons: {
    flex: "none",
    alignSelf: "center",
  },
  filterBox: {
    display: "flex",
    gap: 8,
    alignItems: "center"
  },
  floatingActionButton: {
    left: 16,
    bottom: 16,
    zIndex: 2,
    position: "absolute"
  },
  listItemIcon: {
		minWidth: 30
	},
  checkboxMenuOpions: {
    paddingLeft: 0,
    paddingTop: 3,
    paddingBottom: 3
  },
  buttonChecked: {
    backgroundColor: "#6747D7", 
    color: "#fff",
    '&:hover': {
      backgroundColor: "#593db9"
		}
  }
}));

const TicketsManager = () => {
  let searchTimeout;

  const classes = useStyles();
  const location = useLocation();
  const status = location.state?.status;
  const [searchParam, setSearchParam] = useState(null);
  const [sortAsc, setSortAsc] = useState(false);
  const [withUnreadMessages, setWithUnreadMessages] = useState(false);
  const [tabOpen, setTabOpen] = useState(status ? status : "open");
  const [newMessageOpen, setNewMessageOpen] = useState(false);
  const [newMessageGroup, setNewMessageGroup] = useState(false);
  const activeTab = useRef(status ? status : "open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const lsTabGroup = localStorage.getItem("isTabGroupEnabled");
	const isTabGroupEnabled = lsTabGroup ? JSON.parse(lsTabGroup) : null;
  const [showTabGroups, setShowTabGroups] = useState(!!isTabGroupEnabled);
  const { selectMode, setSelectMode, newMessage, setNewMessage} = useContext(TicketsActionsContext);
  const [openCount, setOpenCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  // const [selectedTags, setSelectedTags] = useState([]);
  const [userQueues, setUserQueues] = useState([]);
  const [listQueueIds, setListQueueIds] = useState([]);

  const [listUsers, setListUsers] = useState([]);

  const [listTags, setListTags] = useState([]);
  const [listTagIds, setListTagIds] = useState([]);

  const [listConnections, setListConnections] = useState([]);
  const [listConnectionIds, setListConnectionIds] = useState([]);

  // queue filter
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [notQueueSelected, setNotQueueSelected] = useState(false);
  const [allQueueSelected, setAllQueueSelected] = useState(false);

  // user filter
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [notUserSelected, setNotUserSelected] = useState(false);
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  // tag filter
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [notTagSelected, setNotTagSelected] = useState(false);
  const [allTagsSelected, setAllTagsSelected] = useState(false);

  // connection filter
  const [selectedConnectionsIds, setSelectedConnectionsIds] = useState([]);
  const [notConnectionSelected, setNotConnectionSelected] = useState(false);
  const [allConnectionsSelected, setAllConnectionsSelected] = useState(false);

  // date filter
  const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);

  useEffect(() => {
    loadConnections();
  }, [user.whatsapps]);

  useEffect(() => {
    if (newMessage !== "") {
      if (newMessage === 'open' && activeTab.current !== 'open') {
        setNewMessageOpen(true);
      }
      if (newMessage === 'groups' && activeTab.current !== 'groups') {
        setNewMessageGroup(true);
      }
      setNewMessage("");
    }
  }, [newMessage]);
  
  useEffect(() => {
    loadTags();
    const queueList = user.queues.map((q) => q.id);
    setUserQueues(user.queues);
    setListQueueIds(queueList);
    if (user.profile.toUpperCase() === "ADMIN" || user.canSeeAllTickets) {
      setShowAllTickets(false);
      loadUsers(user.queues.map((q) => q.id));
    } else {
      setSelectedUserIds([user.id]);
    }

  }, []);

  const loadUsers = async (queueIds) => {
    const { data } = await api.get("/users/filter", {
      params: { queueIds },
    });
    data.users.sort((a, b) => {
      if (a.id === user.id) {
        return -1;
      }

      if (b.id === user.id) {
        return 1
      }
      return 0;
    });
    setListUsers(data.users);
  }

	const loadTags = async () => {
    const { data } = await api.get(`/tags/list`, {
      params: { companyId: user.companyId },
    });
    setListTags(data);
    const tagIds = data.map(q => q.id);
    setListTagIds(tagIds);    
	}

  const loadConnections = async () => {
    const { whatsapps } = user;

    setListConnections(whatsapps);
    const connectionsIds = whatsapps.map(q => q.id);
    setListConnectionIds(connectionsIds);
  }

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(null);
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 100);
  };

  // filters queue
  const handleSelectAllQueues = async (values) => {
    if (allQueueSelected) {
      values = !allQueueSelected;
      setSelectedQueueIds([]);
      setNotQueueSelected(false);
      setAllQueueSelected(values);
    } else {
      values = await userQueues.map((q) => q.id);
      setSelectedQueueIds(values);
      setNotQueueSelected(true);
      setAllQueueSelected(true);
    }
  }

  const handleSelectNotQueueSelected = async (values) => {
    setAllQueueSelected((selectedQueueIds.length === userQueues.length && !notQueueSelected));
    values = !notQueueSelected; //this is necessary because of variable reference - TEST
    setNotQueueSelected(values);
  }

  // filters user
  const handleSelectAllUsers = async (values) => {
    if (allUsersSelected) {
      values = !allUsersSelected;
      setSelectedUserIds([]);
      setNotUserSelected(false);
      setAllUsersSelected(values);
    } else {
      values = listUsers.map((q) => q.id);
      setSelectedUserIds(values);
      setNotUserSelected(true);
      setAllUsersSelected(true);
    }
  }

  const handleSelectNotUserSelected = async (values) => {
    setAllUsersSelected((selectedUserIds.length === listUsers.length && !notUserSelected));
    values = !notUserSelected; //this is necessary because of variable reference - TEST
    setNotUserSelected(values);
  }

  // filters tag
  const handleSelectAllTags = async (values) => {
    if (allTagsSelected) {
      values = !allTagsSelected;
      setSelectedTagIds([]);
      setNotTagSelected(false);
      setAllTagsSelected(values);
    } else {
      values = await listTags.map((q) => q.id);
      setSelectedTagIds(values);
      setNotTagSelected(true);
      setAllTagsSelected(true);
    }
  }

  const handleSelectNotTagSelected = async (values) => {
    setAllTagsSelected((selectedTagIds.length === listTags.length && !notTagSelected));
    values = !notTagSelected; //this is necessary because of variable reference - TEST
    setNotTagSelected(values);
  }

  // filter connections
  const handleSelectAllConnections = async (values) => {
    if (allConnectionsSelected) {
      values = !allConnectionsSelected;
      setSelectedConnectionsIds([]);
      setNotConnectionSelected(false);
      setAllConnectionsSelected(values);
    } else {
      values = await listConnections.map((q) => q.id);
      setSelectedConnectionsIds(values);
      setNotConnectionSelected(true);
      setAllConnectionsSelected(true);
    }
  }

  const handleSelectNotConnectionSelected = async (values) => {
    setAllConnectionsSelected((selectedConnectionsIds.length === listConnections.length && !notConnectionSelected));
    values = !notConnectionSelected;
    setNotConnectionSelected(values);
  }

  // filter date

  const handleDateStartChange = (date) => {
    if (date) {
      if (date.toString() === 'Invalid Date') {
        setDateStart(null);
      } else {
        setDateStart(date.toISOString());
      }
    }
	};

	const handleDateEndChange = (date) => {
    if (date) {
      if (date.toString() === 'Invalid Date') {
        setDateEnd(null);
      } else {
        setDateEnd(date.toISOString());
      }
    }
	};

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
    setSelectMode(false);
    activeTab.current = newValue;
    if (newValue === 'groups') {
      setNewMessageGroup("");
    }
    if (newValue === 'open') {
      setNewMessageOpen("");
    }
  };

  const handleOpenOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelectMode = () => {
    setAnchorEl(null);
    setSelectMode(true);
  };

  const handleCancelSelectMode = () => {
    setAnchorEl(null);
    setSelectMode(false);
  }

  const handleCloseOptionsMenu = e => {
    setAnchorEl(null);
  };

  const handleButtonFilterClick = () => {
    setShowFilter(prevState => !prevState);
  };

  const handleButtonGroupsClick = () => {
    setShowTabGroups(prevState => !prevState);
    localStorage.setItem("isTabGroupEnabled", !showTabGroups);
  };

  return (
    <Paper elevation={0} square variant="outlined" className={classes.ticketsWrapperContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          style={{ width: "100%"}}
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
          variant={document.body.offsetWidth <= 600 && showTabGroups ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
        >
          <Tab
            value={"open"}
            icon={
              <Badge
                invisible={!newMessageOpen}
                color="secondary" 
                overlap="circular" 
                badgeContent=" " 
                variant="dot"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ForumIcon />
              </Badge>}
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            classes={{ root: classes.tab }}
          />
          {
            showTabGroups &&
            <Tab
            value={"groups"}
            icon={
              <Badge
                invisible={!newMessageGroup}
                color="secondary" 
                overlap="circular" 
                badgeContent=" " 
                variant="dot"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <GroupIcon />
              </Badge>}
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={groupCount}
                color="primary"
              >
                {i18n.t("tickets.tabs.groups")}
              </Badge>
            }
            classes={{ root: classes.tab }}
          />
          }
          
          <Tab
            value={"pending"}
            label={
              <Badge
                overlap="rectangular"
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            icon={<HourglassFullIcon />}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckCircleIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Box className={classes.ticketOptionsBox}>
        <Box style={{ display: "flex", alignItems: "center"}}>
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />
          </div>
          <Tooltip
            arrow
            placement="top"
            title="Filtros"
          >
            <IconButton
              size="small"
              onClick={handleButtonFilterClick}
              className={showFilter ? classes.buttonChecked : {}}
            >
              <FilterList />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem style={{ marginRight: 2, marginLeft: 5}} />
          <div className={classes.actionButtons}>
            <Tooltip
              arrow
              placement="top"
              title="Mais opções"
            >
              <IconButton size="small" onClick={handleOpenOptionsMenu}>
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseOptionsMenu}
            >
              {selectMode ? (
                <MenuItem onClick={handleCancelSelectMode}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CloseIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t('ticketsSelectMode.cancel')} />
                </MenuItem>
              ) : (
                <MenuItem onClick={handleSelectMode}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t('ticketsSelectMode.select')} />
                </MenuItem>
              )}
              <Divider />
              <MenuItem
                onClick={() => handleButtonGroupsClick()}
              >
                <Checkbox
                  className={classes.checkboxMenuOpions}
                  size="small"
                  color="primary"
                  checked={showTabGroups}
                />
                Habilitar aba de grupos
              </MenuItem>
              <MenuItem

                onClick={() => setSortAsc(prev => !prev)}
              >
                <Checkbox
                  className={classes.checkboxMenuOpions}
                  size="small"
                  color="primary"
                  checked={sortAsc}
                />
                Ordenar por mais antigas
              </MenuItem>
            </Menu>
          </div>
        </Box>
        <Collapse in={showFilter}>
        <Box className={classes.filterBox}>
          <ButtonGroup
            style={{ marginTop: 8, marginBottom: 4, width: "100%" }}
            variant="outlined"
            aria-label="split button"
          >
              <Button
                size="small"
                onClick={() => setWithUnreadMessages(false)}
                className={!withUnreadMessages ? classes.buttonChecked : {}}
                style={{ width: "100%" }}
              >
                Tudo
              </Button>
              <Button
                size="small"
                onClick={() => setWithUnreadMessages(true)}
                className={withUnreadMessages ? classes.buttonChecked : {}}
                style={{ width: "100%" }}
              >
                Não lidas
              </Button>
          </ButtonGroup>
        </Box>
          <Box className={classes.filterBox}>
            {
              userQueues.length > 1 &&
              <TicketsQueueSelect
                style={{ marginLeft: 6 }}
                selectedQueueIds={selectedQueueIds}
                notQueueSelected={notQueueSelected}
                allQueueSelected={allQueueSelected}
                userQueues={userQueues}
                onChange={async (values) => {
                  if (values === 'allQueueSelected') {
                    handleSelectAllQueues(values);
                  } else if (values === 'notQueueSelected') {
                    handleSelectNotQueueSelected(values);
                  } else {
                    setSelectedQueueIds(values);
                    if (allQueueSelected !== (values.length === userQueues.length && notQueueSelected)) {
                      setAllQueueSelected((values.length === userQueues.length && notQueueSelected));
                    }
                  }
                }}
              />
            }
            {
              (user.canSeeAllTickets || user.profile.toUpperCase() === "ADMIN") &&
              <TicketsUserSelect
                style={{ marginLeft: 6 }}
                selectedUserIds={selectedUserIds}
                notUserSelected={notUserSelected}
                allUsersSelected={allUsersSelected}
                listUsers={listUsers}
                onChange={(values) => {
                  if (values === 'allUsersSelected') {
                    handleSelectAllUsers(values);
                  } else if (values === 'notUserSelected') {
                    handleSelectNotUserSelected(values);
                  } else {
                    setSelectedUserIds(values);
                    if (allUsersSelected !== (values.length === listUsers.length && notUserSelected)) {
                      setAllUsersSelected((values.length === listUsers.length && notUserSelected));
                    }
                  }
                }}
            />              
            }
            {/* <TagsFilter onFiltered={handleSelectedTags} /> */}
          </Box>

          <Box className={classes.filterBox}>
            { listConnections.length > 1 &&
              <TicketsConnectionSelect
                style={{ marginLeft: 6 }}
                selectedConnectionsIds={selectedConnectionsIds}
                notConnectionSelected={notConnectionSelected}
                allConnectionsSelected={allConnectionsSelected}
                listConnections={listConnections}
                onChange={(values) => {
                  if (values === 'allConnectionsSelected') {
                    handleSelectAllConnections(values);
                  } else if (values === 'notConnectionSelected') {
                    handleSelectNotConnectionSelected(values);
                  } else {
                    setSelectedConnectionsIds(values);
                    if (allConnectionsSelected !== (values.length === listConnections.length && notConnectionSelected)) {
                      setAllConnectionsSelected((values.length === listConnections.length && notConnectionSelected));
                    }
                  }
                }}
              />
            }
            {
              <TicketsTagSelect
                style={{ marginLeft: 6 }}
                selectedTagIds={selectedTagIds}
                notTagSelected={notTagSelected}
                allTagsSelected={allTagsSelected}
                listTags={listTags}
                onChange={(values) => {
                  if (values === 'allTagsSelected') {
                    handleSelectAllTags(values);
                  } else if (values === 'notTagSelected') {
                    handleSelectNotTagSelected(values);
                  } else {
                    setSelectedTagIds(values);
                    if (allTagsSelected !== (values.length === listTags.length && notTagSelected)) {
                      setAllTagsSelected((values.length === listTags.length && notTagSelected));
                    }
                  }
                }}
              />
            }
          </Box>
          <Box className={classes.filterBox}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <Box style={{ width: "100%", marginTop: 8 }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                style={{ minWidth: '100%' }}
                inputVariant="outlined"
                size="small"
                label="De"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                value={dateStart}
                onChange={handleDateStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Box>
            <Box style={{ width: "100%", marginTop: 8 }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                style={{ minWidth: '100%' }}
                inputVariant="outlined"
                size="small"
                label="Até"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                value={dateEnd}
                onChange={handleDateEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Box>
            </MuiPickersUtilsProvider>
          </Box>
        </Collapse>
      </Box>
      <Paper square className={classes.ticketsWrapper}>
        <TicketsList
          withUnreadMessages={withUnreadMessages}
          sortAsc={sortAsc}
          status="open"
          showAll={showAllTickets}
          isTabGroupsEnabled={showTabGroups}
          dateStart={dateStart}
          dateEnd={dateEnd}

          selectedQueueIds={selectedQueueIds.length > 0 || notQueueSelected ? selectedQueueIds : listQueueIds}
          notQueueSelected={notQueueSelected ? 
            notQueueSelected : selectedQueueIds.length === 0 ? true : notQueueSelected
          }
          allQueueSelected={allQueueSelected}

          selectedConnectionsIds={selectedConnectionsIds.length > 0 || notConnectionSelected ? selectedConnectionsIds : listConnectionIds}
          notConnectionSelected={notConnectionSelected ?
            notConnectionSelected : selectedConnectionsIds.length === 0 ? true : notConnectionSelected
          }
          allConnectionsSelected={allConnectionsSelected}

          selectedTagIds={selectedTagIds}
          notTagSelected={notTagSelected ?
            notTagSelected : selectedTagIds.length === 0 ? true : notTagSelected
          }
          allTagsSelected={allTagsSelected}
          listUsers={listUsers}
          selectedUserIds={selectedUserIds}
          notUserSelected={notUserSelected}
          allUsersSelected={allUsersSelected}

          updateCount={(val) => setOpenCount(val)}
          style={ tabOpen !== 'open' ? { width: 0, height: 0 } : {} }
          searchParam={tabOpen !== 'open' ? null : searchParam}
          tabOpen={tabOpen}
        />
        <TicketsList
          withUnreadMessages={withUnreadMessages}
          sortAsc={sortAsc}
          status="open"
          groups={true}
          showAll={showAllTickets}
          dateStart={dateStart}
          dateEnd={dateEnd}
          selectedQueueIds={selectedQueueIds.length > 0 || notQueueSelected ? selectedQueueIds : listQueueIds}
          notQueueSelected={notQueueSelected ? 
            notQueueSelected : selectedQueueIds.length === 0 ? true : notQueueSelected
          }
          allQueueSelected={allQueueSelected}
          selectedConnectionsIds={selectedConnectionsIds.length > 0 || notConnectionSelected ? selectedConnectionsIds : listConnectionIds}
          notConnectionSelected={notConnectionSelected ?
            notConnectionSelected : selectedConnectionsIds.length === 0 ? true : notConnectionSelected
          }
          allConnectionsSelected={allConnectionsSelected}
          selectedTagIds={selectedTagIds}
          notTagSelected={notTagSelected ?
            notTagSelected : selectedTagIds.length === 0 ? true : notTagSelected
          }
          allTagsSelected={allTagsSelected}
          listUsers={listUsers}
          selectedUserIds={selectedUserIds}
          notUserSelected={notUserSelected}
          allUsersSelected={allUsersSelected}
          updateCount={(val) => setGroupCount(val)}
          style={ tabOpen !== 'groups' ? { width: 0, height: 0 } : {} }
          searchParam={tabOpen !== 'groups' ? null : searchParam}
          tabOpen={tabOpen}
        />
        <TicketsList
          withUnreadMessages={withUnreadMessages}
          sortAsc={sortAsc}
          status="pending"
          showAll={showAllTickets}
          isTabGroupsEnabled={showTabGroups}
          dateStart={dateStart}
          dateEnd={dateEnd}

          selectedQueueIds={selectedQueueIds.length > 0 || notQueueSelected ? selectedQueueIds : listQueueIds}
          notQueueSelected={notQueueSelected ? 
            notQueueSelected : selectedQueueIds.length === 0 ? true : notQueueSelected
          }
          allQueueSelected={allQueueSelected}

          selectedConnectionsIds={selectedConnectionsIds.length > 0 || notConnectionSelected ? selectedConnectionsIds : listConnectionIds}
          notConnectionSelected={notConnectionSelected ?
            notConnectionSelected : selectedConnectionsIds.length === 0 ? true : notConnectionSelected
          }
          allConnectionsSelected={allConnectionsSelected}

          selectedTagIds={selectedTagIds}
          notTagSelected={notTagSelected ?
            notTagSelected : selectedTagIds.length === 0 ? true : notTagSelected
          }
          allTagsSelected={allTagsSelected}
          listUsers={listUsers}
          selectedUserIds={selectedUserIds}
          notUserSelected={notUserSelected}
          allUsersSelected={allUsersSelected}
          updateCount={(val) => setPendingCount(val)}
          style={ tabOpen !== 'pending' ? { width: 0, height: 0 } : {} }
          searchParam={tabOpen !== 'pending' ? null : searchParam}
          tabOpen={tabOpen}
        />
        <TicketsList
          withUnreadMessages={withUnreadMessages}
          sortAsc={sortAsc}
          status="closed"
          showAll={showAllTickets}
          isTabGroupsEnabled={showTabGroups}
          dateStart={dateStart}
          dateEnd={dateEnd}

          selectedQueueIds={selectedQueueIds.length > 0 || notQueueSelected ? selectedQueueIds : listQueueIds}
          notQueueSelected={notQueueSelected ? 
            notQueueSelected : selectedQueueIds.length === 0 ? true : notQueueSelected
          }
          allQueueSelected={allQueueSelected}

          selectedConnectionsIds={selectedConnectionsIds.length > 0 || notConnectionSelected ? selectedConnectionsIds : listConnectionIds}
          notConnectionSelected={notConnectionSelected ?
            notConnectionSelected : selectedConnectionsIds.length === 0 ? true : notConnectionSelected
          }
          allConnectionsSelected={allConnectionsSelected}

          selectedTagIds={selectedTagIds}
          notTagSelected={notTagSelected ?
            notTagSelected : selectedTagIds.length === 0 ? true : notTagSelected
          }
          allTagsSelected={allTagsSelected}
          listUsers={listUsers}
          selectedUserIds={selectedUserIds}
          notUserSelected={notUserSelected}
          allUsersSelected={allUsersSelected}
          style={ tabOpen !== 'closed' ? { width: 0, height: 0 } : {} }
          searchParam={tabOpen !== 'closed' ? null : searchParam}
          tabOpen={tabOpen}
        />
        <Tooltip
          arrow
          placement="right"
          title="Nova conversa"
        >
          <Fab
            className={classes.floatingActionButton}
            size="large"
            color="primary"
            aria-label="add"
            onClick={() => setNewTicketModalOpen(true)}>
            <AddIcon />
          </Fab>

        </Tooltip>
      </Paper>
    </Paper>
  );
};

export default TicketsManager;
