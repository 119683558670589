import React, { useState, useEffect, useRef, useContext } from "react";
import platform from 'platform';
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import GroupIcon from '@material-ui/icons/Group';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Chip, Tooltip, Checkbox, IconButton, Menu, MenuItem, Grid } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import TagsTicket from "../TagsTicket";
import SpyModal from "../SpyModal";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { TicketsActionsContext } from "../../context/TicketsActionsContext/TicketsActionsContext";
import { KeyboardArrowDown } from "@material-ui/icons";
import FlagIcon from '@material-ui/icons/Flag';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
	ticket: {
		height: 72,
		position: "relative",
		'&:hover': { 
			'& $arrowButton': {
				marginLeft: "0px",
				opacity: 1,
				visibility: "visible",
			}
		}
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
		color: "#000000",
		// position: "absolute",  //'ajuste da data/hora tickets'
		// left: "45%",
	},

	closedBadge: {
		position: "absolute",
		right: 130,
		alignSelf: "center",
		justifySelf: "flex-end",
		marginLeft: "auto",
		// bottom: 27,
		// marginRight: 40,
	},

	contactLastMessage: {
		paddingRight: 20,
		color: "#000000",
	},

	newMessagesCount: {
		alignSelf: "center",
		margin: 15
		// marginRight: 15,
	},
	badgeStyle: {
		backgroundColor: "#3CB371",
		color: "#FFFFFF",
	},
	chipUser: {
		fontSize: "0.75rem",
		height: 22
	},
	acceptButton: {
		minWidth: "unset",
		marginLeft: 12
	},

	ticketQueueColor: {
		flex: "none",
		width: "10px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	arrowButton: {
		marginLeft: "-25px",
		right: "0px",
		opacity: 0,
		visibility: "hidden",
		transition: "all 0.25s ease-in-out",
		'&:hover': {
			marginRight: "0px",
			opacity: 1
		}
	},
	arrowButtonMobile: {
		display: "flex",
		right: "0px",
		opacity: 1
	},

}));

const isMobile = platform.os.family.toLowerCase() === 'android' || platform.os.family.toLowerCase() === 'ios';

const TicketListItem = ({ ticket, selected, dispatch, isNotificationList, onPinButtonClick }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [modalSpyOpen, setModalSpyOpen] = useState(false);
	const { whatsApps } = useContext(WhatsAppsContext);
	const { selectMode, setTicketOpened } = useContext(TicketsActionsContext);
	const [anchorEl, setAnchorEl] = useState(null);

	const pinnedTicketsLocalStorage = !isNotificationList ? localStorage.getItem("pinnedTickets") : null;
	let pinnedTickets = JSON.parse(pinnedTicketsLocalStorage || '[]');
	pinnedTickets = Array.isArray(pinnedTickets) ? pinnedTickets : [];
	const [ isTicketPinned ] = useState(!!(pinnedTickets).find(id => id === ticket.id));

	useEffect(() => {
		setTicketOpened(!!ticketId)
	},[ticketId])

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleCloseOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleOpenOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	  };

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleViewTicket = async id => {
		setAnchorEl(null);
		setModalSpyOpen(true);
	};

	const handleUnreadTicket = async id => {
		setAnchorEl(null);
		setLoading(true);
		await api.put(`/tickets/mark-as-unread/${id}`);
		setLoading(false);
	}

	const handlePinTicket = async () => {
		setAnchorEl(null);
		let pinnedIds = pinnedTicketsLocalStorage ? JSON.parse(pinnedTicketsLocalStorage) : [];

		if (!Array.isArray(pinnedIds)) {
			pinnedIds = [];
		}
		const index = pinnedIds.indexOf(ticket.id);
		if (index > -1) {
			pinnedIds.splice(index, 1);
		} else {
			pinnedIds.push(ticket.id);
		}
		localStorage.setItem("pinnedTickets", JSON.stringify(pinnedIds));
		onPinButtonClick();
	}

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const handleSelectItem = (event, id) => {
		const { checked } = event.target;
		dispatch({
			type: "CHECK_ITEM",
			payload: { checked, id }
		});
	};

	return (
		<React.Fragment key={ticket.id}>
			<SpyModal
				open={modalSpyOpen}
				ticket={ticket}
				onClose={() => setModalSpyOpen(false)}
			/>
			<ListItem
				dense
				button
				onClick={e => {
					if (ticket.status === "pending") return;
					if (!selectMode) {
						handleSelectTicket(ticket.id);
					}
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				{selectMode ? <Checkbox
					size="small"
					color="primary"
					onChange={(event) => handleSelectItem(event, ticket.id)}
					checked={selected ? selected : false}
				/> : ""
				}
				<ListItemAvatar style={{ width: 60 }}>
					<Avatar style={{ width: 49, height: 49 }} src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<Tooltip
					arrow
					placement="right"
					// open={false}
					disableHoverListener={!ticket.user || !user.profile.toUpperCase() === "ADMIN"}
					title={`Atribuído à ${ticket.user?.name}`}
			>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact.name}
							</Typography>
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.lastMessageAt), new Date()) ? (
										<>{format(parseISO(ticket.lastMessageAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.lastMessageAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{
									ticket.lastMessage?.includes("BEGIN:VCARD") ?
										<Grid container >
											<Grid item>
												<PersonIcon color="action" fontSize="small" style={{ marginRight: 5}}></PersonIcon>
											</Grid>
											<Grid item style={{ paddingTop: 2}}>Contato</Grid>
										</Grid> :
										ticket.lastMessage &&
										<div title={ticket.lastMessage}>
											<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
										</div>
								}
							</Typography>
							<div style={{ display: "flex", alignItems: 'center' }}>
								<div style={{ display: "flex", alignItems: 'right', marginRight:"-10px", "alignItems": "center" }}>
									{
										whatsApps.length > 1 &&
										<Chip
											size="small"
											color="primary"
											style={{ height: 20, marginRight: 3 }}
											label={ticket.whatsapp?.name}
										/>
									}
									{
										ticket.isGroup &&
										<Tooltip
											arrow
											placement="top"
											title="Grupo"
										>
											<GroupIcon color="primary" />
										</Tooltip>
									}
									<TagsTicket ticket={ticket} />

									{isTicketPinned && (
										<Tooltip
											arrow
											placement="top"
											title="Fixada"
										>
											<FlagIcon color="primary" />
										</Tooltip>
									)}
									{
										ticket.unreadMessages > 0 &&
										<Badge
											overlap="rectangular"
											className={classes.newMessagesCount}
											badgeContent={ticket.unreadMessages}
											classes={{
												badge: classes.badgeStyle,
											}}
										/>
									}
									{
										<IconButton className={isMobile ? classes.arrowButtonMobile: classes.arrowButton} size="small" onClick={(e) => {
											e.stopPropagation();
											handleOpenOptionsMenu(e);
										}}>
											<KeyboardArrowDown />
										</IconButton>
									}
								</div>

								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									getContentAnchorEl={null}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(anchorEl)}
									onClose={e => {
										e.stopPropagation();
										handleCloseOptionsMenu();
									}}
								>
									<MenuItem 
										onClick={e => { 
											e.stopPropagation();
											handleViewTicket(ticket.id);
										}}>
										{i18n.t("ticketsList.spyTooltip")}
									</MenuItem>

									{!isNotificationList &&
										<MenuItem
											onClick={e => {
												e.stopPropagation();
												handleUnreadTicket(ticket.id);
											}}>
											{i18n.t("ticketsList.items.unreadButton")}
										</MenuItem>
									}
									{!isNotificationList &&
										<MenuItem
											onClick={e => {
												e.stopPropagation();
												handlePinTicket();
											}}
										>
											{!isTicketPinned ?
												i18n.t("ticketsList.items.pinButton") :
												i18n.t("ticketsList.items.unpinButton")}
										</MenuItem>
									}
								</Menu>
							</div>
						</span>
					}
				/>
				</Tooltip>
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
