import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import platform from 'platform';

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { deleteNotificationToken, getNotificationToken, initializeFirebase, onMessageListener, requestPermission } from "../../firebase";
import openSocket from "../../services/socket-io";

const useAuth = () => {
	const history = useHistory();
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});

	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			const { url } = config;
			if (token && !url.includes('https://integgri')) {
				config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
				setIsAuth(true);
			}
			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;

				const { data } = await api.post("/auth/refresh_token");
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
				}
				return api(originalRequest);
			}
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		const token = localStorage.getItem("token");
		(async () => {
			if (token) {
				try {
					const { data } = await api.post("/auth/refresh_token");
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					setIsAuth(true);
					setUser(data.user);
          requestNotificationPermission(data);
					setPartner(data);
				} catch (err) {
					toastError(err);
				}
			}
			setLoading(false);
		})();
	}, []);

	useEffect(() => {
		const socket = openSocket();
		socket.on(`user${user.companyId}`, data => {
			if (data.action === "update" && data.user.id === user.id) {
				setUser(data.user);
			}
		});
		return () => {
			socket.disconnect();
		};
	}, [user]);

  const requestNotificationPermission = async (user) => {
    await requestPermission();
    initializeFirebase().then(async () => {
      onMessageListener();
      const company = user?.company;
			if (company?.active === 'S' && company?.pushNotifications) {
				if (platform.os.family.toLowerCase() === 'android'
					|| platform.os.family.toLowerCase() === 'ios') {
					await getNotificationToken(user.id)
				}
			}
    });
  };

	const setPartner = (data) => {
		const { company } = data.user;
		if (company?.partner) {
			const { partner } = company;
			localStorage.setItem("partner", partner.name?.toLowerCase());
		}
	}

	const handleLogin = async userData => {
		setLoading(true);
		try {
			const { data } = await api.post("/auth/login", userData);
			localStorage.setItem("token", JSON.stringify(data.token));
			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			setUser(data.user);
      requestNotificationPermission(data.user);
			setPartner(data);
			setIsAuth(true);
			toast.success(i18n.t("auth.toasts.success"));
			history.push("/tickets");
			setLoading(false);
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

	const handleLogout = async () => {
		setLoading(true);

		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			api.defaults.headers.Authorization = undefined;
			setLoading(false);
			const partner = localStorage.getItem("partner");
			let path = "/login";
			if (partner) {
				path = `/${partner}${path}`;
			}
			history.push(path);
			deleteNotificationToken();

			window.indexedDB.deleteDatabase('firebase-heartbeat-database');
			window.indexedDB.deleteDatabase('firebase-installations-database');
			await window.indexedDB.deleteDatabase('firebase-messaging-database');
			// window.location.reload();		
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

	return { isAuth, user, loading, handleLogin, handleLogout };
};

export default useAuth;
