import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import UploadAttachment from "../UploadAttachment/UploadAttachment";
import { QueueOptions } from "../QueueOptions";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		paddingRight: "4px",
		width: "50%"
	},
	textFieldColor: {
		paddingLeft: "4px",
		width: "50%"
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	customTextarea: {
		marginBottom: "30px",
	},
	fixedActions: {
		position: 'sticky',
		bottom: 0,
		backgroundColor: theme.palette.background.paper,
		zIndex: 10,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Nome é muito curto!")
		.max(50, "Nome é muito longo!")
		.required("Obrigatório"),
	color: Yup.string()
		.min(3, "O código da cor é muito curto!")
		.max(9, "O código da cor é muito longo!")
		.required("Obrigatório"),
	greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		color: "",
		greetingMessage: ""
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [attachmentName, setAttachmentName] = useState(null);
	const [attachmentUrl, setAttachmentUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const { user } = useContext(AuthContext);
	const [blocked, setBlocked] = useState(false);
	const [queuesOptionIds, setQueuesOptionIds] = useState([]);

	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
				setAttachmentName(data?.attachmentName);
				setAttachmentUrl(data?.attachmentUrl);
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				name: "",
				color: "",
				greetingMessage: ""
			});
			setAttachmentName(null);
			setAttachmentUrl(null);
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setBlocked(false);
		setQueue(initialState);
	};

	const deleteAttachment = async (e) => {
		const fileName = queue.attachmentName || attachmentName;
		if (fileName) {
			try {
				setIsLoading(true);

				await api.post(`/queue/delete-attachment`, {
					name: fileName
				});

				setIsLoading(false);
				setAttachmentName(null);
				setAttachmentUrl(null);

			} catch (err) {
				toastError(err);
				setIsLoading(false);
			}
		}
	}

	const uploadAttachment = async (selectedMedia) => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("file", selectedMedia);
		const file = formData.get("file");
		try {
			if (!file) {
				throw new Error("Arquivo não encontrado no formData");
			}
			const response = await api.post(`/queue/upload-attachment`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			const { fileName, url } = response.data;
			setAttachmentName(fileName)
			setAttachmentUrl(url)

		} catch (err) {
			console.log("Resposta inválida do servidor:", err);
			toastError(err);
		}
		setIsLoading(false);

	}

	const handleOptionsUpdate = (updatedOptions) => {

		const checkSaved = (options) => {
			let hasBlocked = false;
	
			const recursiveCheck = (option) => {
				if (option.saved === false) {
					hasBlocked = true;
					return;
				}
	
				if (option.children && option.children.length > 0) {
					for (const child of option.children) {
						recursiveCheck(child);
						if (hasBlocked) break;
					}
				}
			};
	
			for (const option of options) {
				recursiveCheck(option);
				if (hasBlocked) break;
			}
			return hasBlocked;
		};
		const hasBlocked = checkSaved(updatedOptions);	
		setBlocked(hasBlocked);
	};

	const handleQueuesOptionIds = async(values) => {
		setQueuesOptionIds(values);
	}
	
	const handleSaveQueue = async values => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, { ...values, companyId: user.companyId, attachmentName, attachmentUrl });
			} else {
				await api.post("/queue", { ...values, companyId: user.companyId, attachmentName, attachmentUrl, queuesOptionIds });
			}
			toast.success("Departamento salvo com sucesso");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleDeletedOptions = async() => {
		if(!queueId) {
			try {
				await api.request({
				  url: `/queue-options/${queuesOptionIds}`,
				  method: "DELETE",
				});
			  } catch (e) {
				toastError(e);
			  }
		}
		handleClose();
	}

	return (
		<div className={classes.root}>
			<Dialog
				maxWidth="md"
				fullWidth={true}
				open={open}
				onClose={handleClose}
				scroll="paper"
			>
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers style={{borderBottom: 'none'}}>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textFieldName}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									className={classes.textFieldColor}
									onClick={() => {
										setColorPickerModalOpen(true);
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								<div style={{ position: "relative", marginTop: 15 }}>
									<Field
										name="greetingMessage"
										render={({ field, form }) => (
											<div style={{ position: "relative" }}>
												<TextField
													label={i18n.t("queueModal.form.greetingMessage")}
													multiline
													minRows={4}
													fullWidth
													error={touched.greetingMessage && Boolean(errors.greetingMessage)}
													helperText={touched.greetingMessage && errors.greetingMessage}
													variant="outlined"
													margin="dense"
													{...field}
													InputProps={{
														classes: {
															input: classes.customTextarea,
														},
													}}
												/>
												<div style={{ position: 'absolute', bottom: 15, left: 6 }}>
													<UploadAttachment
														loading={isLoading}
														currentAttachmentName={attachmentName}
														currentAttachmentUrl={attachmentUrl}
														onUploadAttachment={uploadAttachment}
														onDeleteAttachment={deleteAttachment}
													/>
												</div>
											</div>
										)}
									/>
								</div>
								{
									user.company?.companyPlan?.botModule &&
									<QueueOptions queueId={queueId} onOptionsUpdate={handleOptionsUpdate} queuesOptionIds={handleQueuesOptionIds} />
								}
							</DialogContent>
							<DialogActions className={classes.fixedActions}>
								<Button
									onClick={handleDeletedOptions}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={blocked}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
