import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Checkbox, Chip, Divider, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsConnectionSelect = ({
	listConnections,
	selectedConnectionsIds = [],
	notConnectionSelected = false,
	allConnectionsSelected = false,
	onChange,
}) => {
	const handleChange = e => {
		if (e.currentTarget.dataset.value === 'allConnectionsSelected') {
			onChange('allConnectionsSelected');
		} else if (e.currentTarget.dataset.value === 'notConnectionSelected') {
			onChange('notConnectionSelected');
		} else {
			onChange(e.target.value);
		}
	};

	return (
		<Box style={{ width: "100%" }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedConnectionsIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={() => {
						return (
							<>
								{i18n.t("ticketsConnectionSelect.placeholder") + " "}
								{(selectedConnectionsIds.length > 0 || notConnectionSelected) && 
									(<Chip size="small" label={
										(notConnectionSelected ? selectedConnectionsIds.length + 1 : selectedConnectionsIds.length).toString()
									} color="secondary" />)
								}
							</>
						)
					}}
				>
					{<MenuItem dense key={'allConnectionsSelected'} value={'allConnectionsSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={allConnectionsSelected}
						/>
						<ListItemText primary={"Selecionar todos"} />
					</MenuItem>}

					{<MenuItem dense key={'notConnectionSelected'} value={'notConnectionSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={notConnectionSelected}
						/>
						<ListItemText primary={"Sem conexão"} />
					</MenuItem>}

					{<Divider></Divider>}

					{listConnections?.length > 0 &&
						listConnections.map(connection => (
							<MenuItem dense key={connection.id} value={connection.id}>
								<Checkbox
									style={{
										color: connection.color,
									}}
									size="small"
									color="primary"
									checked={selectedConnectionsIds.indexOf(connection.id) > -1}
								/>
								<ListItemText primary={connection.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default TicketsConnectionSelect;
