import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";

import {
  makeStyles,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Button,
  DialogActions,
  ListItem,
  Box,
  Typography,
  Switch,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import FormHelperText from '@material-ui/core/FormHelperText';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
  boxContainer: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
	}
}));

const N8nSettingsModal = ({
  open,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [webhookN8nQueue, setWebhookN8nQueue] = useState("");
  const [loading, setLoading] = useState(false);
  const [webhookN8nQueueEnabled, setWebhookN8nQueueEnabled] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      return;
    } 
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/settings/n8n`);
        const { webhookN8nQueue, webhookN8nQueueEnabled } = data;
        if (isMounted.current) {
          if (webhookN8nQueue?.value) {
            setWebhookN8nQueue(webhookN8nQueue.value);
          }
          if (webhookN8nQueueEnabled?.value) {
            setWebhookN8nQueueEnabled(webhookN8nQueueEnabled.value === "enabled" ? true : false);
          }
        }
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };

    fetchSettings();
  }, [open]);

  const handleToggle = (event) => {
    setWebhookN8nQueueEnabled(event.target.checked);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSaveRdSettings = async () => {
    setLoading(true);
    try {
      const enabled = webhookN8nQueueEnabled ? "enabled" : "disabled";
      const { data } = await api.post("/settings/n8n", { webhookN8nQueue, webhookN8nQueueEnabled: enabled });
      if (onSave) {
        onSave(data);
      }
      handleClose();
      setLoading(false);
      toast.success(i18n.t("n8nSettingsModal.success"));
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t("n8nSettingsModal.title")}
        </DialogTitle>
        <DialogContent dividers>
          <ListItem disableGutters={true} className={classes.root}>
            <Box className={classes.boxContainer}>
              <Typography variant="subtitle1">Após escolha do departamento</Typography>
              <Box>
                <Switch
                  color="primary"
                  checked={webhookN8nQueueEnabled}
							    onChange={handleToggle}
                />
              </Box>
            </Box>
            <FormHelperText>Quando um contato fizer a escolha de um departamento, esse webhook será chamado</FormHelperText>
          </ListItem>
          <TextField
            style={{ marginTop: 20 }}
            disabled={!webhookN8nQueueEnabled}
						variant="outlined"
						margin="dense"
						size="small"
						fullWidth
            onChange={(e) => setWebhookN8nQueue(e.target.value)}
            value={webhookN8nQueue}
            label={i18n.t("n8nSettingsModal.form.webhook")}/>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            {i18n.t("n8nSettingsModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            onClick={() => handleSaveRdSettings()}
          >
            {i18n.t("n8nSettingsModal.buttons.okEdit")}
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default N8nSettingsModal;
