import React, { useState, createContext } from "react";

const QuickAnswerContext = createContext();

const QuickAnswerProvider = ({ children }) => {
	const [pressedKey, setPressedKey] = useState(null);
	const [quickAnswer, setQuickAnswer] = useState(null);
	const [ quickAnswerAttachment, setQuickAnswerAttachment ] = useState(null);
	const [typeBar, setTypeBar] = useState();

	return (
		<QuickAnswerContext.Provider
			value={{ pressedKey, setPressedKey, quickAnswer, setQuickAnswer, typeBar, setTypeBar, quickAnswerAttachment, setQuickAnswerAttachment }}
		>
			{children}
		</QuickAnswerContext.Provider>
	);
};

export { QuickAnswerContext, QuickAnswerProvider };
