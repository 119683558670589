import React from "react";
import Typography from "@material-ui/core/Typography";

const textColorSecondary = "rgba(0, 0, 0, 0.87)";

const TitleChart = props => {
	return (
		<Typography component="h2" variant="h6" style={{ color: textColorSecondary }} gutterBottom>
			{props.children}
		</Typography>
	);
};

export default TitleChart;
