import React from "react";
import { Tooltip } from "@material-ui/core";
import LabelIcon from '@material-ui/icons/Label';

const TagsTicket = ({ ticket }) => {
	const contactTags = ticket.contact?.tags;
	const ticketTags = ticket.tags;
	const tags = ticketTags || contactTags;
	if (tags) {
		return (
			<>
				{tags.map(tag => (
					<Tooltip
						key={tag.id}
						arrow
						placement="top"
						title={tag.name}
					>
						<LabelIcon style={{ color: tag.color }} />
					</Tooltip>
				))}
			</>
		);
	} else {
		return (
			<>
			</>
		);
	}
};

export default TagsTicket;
