import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Box,
  Tooltip,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import logoInteggri from "../assets/logo-integgri.png";
import { useTheme } from "@material-ui/core/styles";
import VolumePopOver from "../components/VolumePopOver";
import AlertsPopOver from "../components/AlertsPopOver";
import ButtonHelp from "../components/ButtonHelp";

const drawerWidth = 220;

const LoggedInLayout = ({ children }) => {
  const theme = useTheme();

  const { user } = useContext(AuthContext);
  if (user.company?.partner) {
    const { partner } = user.company;
    const { 
      primaryMainColor, 
      secondaryMainColor, 
      backgroundMenuColor 
    } = partner;
    theme.palette.primary.main = primaryMainColor;
    theme.palette.secondary.main = secondaryMainColor;
    theme.palette.backgroundMenu = backgroundMenuColor;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "100vh",
    },
    headerLeft: {
      display: "flex",
      justifyContent: "space-between"
    },
    containerLogo: {
      marginTop: 7,
      marginLeft: 16
    },
    logo: {
      width: 104,
      height: 34,
    },
    alerts: {
      marginLeft: "auto",
    },
    toolbar: {
      paddingRight: 30, // keep right padding when drawer closed
      // // background: "#483D8B",  // // "pinta a barra do cabeçalho"
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      minHeight: "48px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 5,
      marginLeft: -14,
      padding: 8,
    },
    
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      background: `${theme.palette.backgroundMenu}`,
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...theme.scrollbarStyles
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7.2),
      },
    },
    appBarSpacer: {
      minHeight: "48px",
    },
    content: {
      flex: 1,
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.22)"
    },
    chevronLeftIcon: {
      color: "#fff"
    }
  }));

  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton className={classes.chevronLeftIcon} onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={classes.divider}/> 
        <List>
          <MainListItems drawerClose={drawerClose} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
        </List>
        <Divider className={classes.divider} />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        style={{ boxShadow: "rgba(140, 152, 164, 0.25) 0px 3px 6px 0px"}}
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === "development" ? "inherit" : "inherit"}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {!user.company?.partner && 
          <Box
            component="img"
            src={logoInteggri}
            className={classes.logo}/>}

          <div className={classes.alerts}>
            {user.id && document.body.offsetWidth > 600 && <ButtonHelp/>}
            {
              user.id &&
              document.body.offsetWidth > 600 && 
              <VolumePopOver />
            }
            {user.id && <AlertsPopOver />}
            {user.id && <NotificationsPopOver/>}
          </div>
          <div>
            <Tooltip
              arrow
              placement="top"
              title="Minha Conta"
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
