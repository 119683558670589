import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TagsContainer } from "../TagsContainer";
import QuickAnswersBar from "../QuickAnswersBar/QuickAnswersBar";
import { QuickAnswerProvider } from "../../context/QuickAnswer/QuickAnswerContext";
import QuickAnswersAttachmentBar from "../QuickAnswersAttachmentBar";
import { SendMediaProvider } from "../../context/SendMediaContext/SendMediaContext";
import { DragFileProvider } from "../../context/DragFileContext/DragFileContext";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    padding: 0
  },

  ticketInfo: {
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [appMessageData, setAppMessageData] = useState({});
  const [tagSocketData, setTagSocketData] = useState({});
  const [contactSocketData, setContactSocketData] = useState({});
  const [ticket, setTicket] = useState({});
  const [isLastMessageWithin24hours, setIsLastMessageWithin24hours] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);
          setContact(data.ticket.contact);
          setTicket(data.ticket);
          setIsLastMessageWithin24hours(data.isLastMessageWithin24hours);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
          history.push("/tickets");
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => {
      setTimeout(() => {
        socket.emit(`${user.companyId}:joinChatBox`, ticketId);
      }, 1000);
    });

    socket.on(`appMessage${user.companyId}`, (data) => {
      if (!data.message?.fromMe) {
        setIsLastMessageWithin24hours(true);
      }
      setAppMessageData(data);
    });

    socket.on(`tag${user.companyId}`, (data) => {
      setTagSocketData(data);
    });

    socket.on(`ticket${user.companyId}`, (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Conversa excluída com sucesso");
        history.push("/tickets");
      }
    });

    socket.on(`contact${user.companyId}`, (data) => {
      setContactSocketData(data);
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          {
            document.body.offsetWidth > 600 &&
            <TagsContainer
              contact={contact}
              ticket={ticket}
              contactSocketData={contactSocketData}
              tagSocketData={tagSocketData}
              gray />
          }
          <div className={classes.ticketActionButtons}>
            { ticket?.id && <TicketActionButtons ticket={ticket} /> }
          </div>
        </TicketHeader>
        <SendMediaProvider>
          <DragFileProvider>
            <ReplyMessageProvider>
              <QuickAnswerProvider>
                {
                  ticket?.id &&
                  <MessagesList
                    ticketId={ticketId}
                    isGroup={ticket.isGroup}
                    appMessageData={appMessageData}
                  ></MessagesList>
                }
                <QuickAnswersBar/>
                <QuickAnswersAttachmentBar ticketId={ticketId}/>
                <MessageInput isLastMessageWithin24hours={isLastMessageWithin24hours} ticket={ticket} />
              </QuickAnswerProvider>
            </ReplyMessageProvider>
          </DragFileProvider>
        </SendMediaProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        ticket={ticket}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
