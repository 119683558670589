import React, { useContext, useEffect, useState } from "react";
import { Checkbox, TextField
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function TagsContacts({ onSelectTags, isWithoutTag, size, width }) {
  const [tags, setTags] = useState([{ id: "withoutTag", name: "Sem etiqueta" }]);
  const { user } = useContext(AuthContext);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      await loadTags();
    }
    fetchData();
  }, []);

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`, {
        params: { companyId: user.companyId },
      });
      if (isWithoutTag) {
        data.unshift({ id: "withoutTag", name: "Sem etiqueta" });
      }
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeTags = (event, val) => {
    onSelectTags(val);
    setFilterCount(val.length);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={tags}
      limitTags={2}
      size={size}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      onChange={handleChangeTags}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            size="small"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      style={{ minWidth: '300px', width: {width} }}
      renderTags={() => {}}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={filterCount > 0 ? 'Etiquetas: ' + filterCount : 'Etiquetas'} placeholder={filterCount > 0 ? 'Etiquetas: ' + filterCount : 'Etiquetas'} />
      )}
    />
  );
}
