import React, { useContext, useEffect, useReducer, useState } from "react";

import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { AccessTime, DeleteOutline, Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import ShiftModal from "../../components/ShiftModal";
import ScheduleConsultModal from "../../components/ScheduleConsultModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_SHIFTS") {
    const shifts = action.payload;
    const newShifts = [];

    shifts.forEach((shift) => {
      const shiftIndex = state.findIndex((q) => q.id === shift.id);
      if (shiftIndex !== -1) {
        state[shiftIndex] = shift;
      } else {
        newShifts.push(shift);
      }
    });

    return [...state, ...newShifts];
  }

  if (action.type === "UPDATE_SHIFTS") {
    const shift = action.payload;
    const shiftIndex = state.findIndex((u) => u.id === shift.id);

    if (shiftIndex !== -1) {
      state[shiftIndex] = shift;
      return [...state];
    } else {
      return [shift, ...state];
    }
  }

  if (action.type === "DELETE_SHIFT") {
    const shiftId = action.payload;
    const shiftIndex = state.findIndex((q) => q.id === shiftId);
    if (shiftIndex !== -1) {
      state.splice(shiftIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Shifts = () => {
  const classes = useStyles();

  const [shifts, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [shiftModalOpen, setShiftModalOpen] = useState(false);
  const [scheduleModalConsultOpen, setScheduleModalConsultOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/shift/list/${user.companyId}`);
        dispatch({ type: "LOAD_SHIFTS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, [user.companyId]);

  useEffect(() => {
    const socket = openSocket();

    socket.on(`shift${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SHIFTS", payload: data.shift });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SHIFT", payload: data.shiftId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  const handleOpenShiftModal = () => {
    setShiftModalOpen(true);
    setSelectedShift(null);
  };

  const handleCloseShiftModal = () => {
    setShiftModalOpen(false);
    setSelectedShift(null);
  };

  const handleCloseScheduleModalConsult = () => {
    setScheduleModalConsultOpen(false);
    setSelectedShift(null);
  };

  const handleEditShift = (shift) => {
    setSelectedShift(shift);
    setShiftModalOpen(true);
  };

  const handleEditSchedule = (shift) => {
    setSelectedShift(shift);
    setScheduleModalConsultOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedShift(null);
  };

  const handleDeleteShift = async (shiftId) => {
    try {
      await api.delete(`/shift/${shiftId}`, {
        data: { 
          companyId: user.companyId 
        } 
      });
      toast.success(i18n.t("Plantão excluído com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedShift(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedShift &&
          `${i18n.t("shifts.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteShift(selectedShift.id)}
      >
        {i18n.t("shifts.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ShiftModal
        modalOpen={shiftModalOpen}
        onClose={handleCloseShiftModal}
        shiftId={selectedShift?.id}
      />
      <ScheduleConsultModal
        modalOpen={scheduleModalConsultOpen}
        onClose={handleCloseScheduleModalConsult}
        shiftId={selectedShift?.id}
      />
      <MainHeader>
        <Title>{i18n.t("shifts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenShiftModal}
          >
            {i18n.t("shifts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("shifts.table.user")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("shifts.table.queue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("shifts.table.greeting")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("shifts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {shifts.map((shift) => (
                <TableRow key={shift.id}>
                  <TableCell align="center">{shift.user.name}</TableCell>
                  <TableCell align="center">{shift.queue.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {shift.greetingMessage}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditShift(shift)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleEditSchedule(shift)}
                    >
                      <AccessTime />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedShift(shift);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Shifts;
