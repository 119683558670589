import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import planilhaExemplo from "../../assets/planilha.xlsx";
import api from "../../services/api";
import {
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Typography,
    Checkbox,
    Grid,
    FormControlLabel,
    Link,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.60)',
        boxShadow: theme.shadows[3],
        fontSize: 16,
    },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    textTypography: {
        whiteSpace: "pre-line",
        margin: "15px 100px 25px 30px",
    },
    gridStyle: {
        marginLeft: "30px",
        color: "#555555",
    },
    btnFile: {
        color: "#555555",
        borderRadius: "5px",
        marginTop: "20px",
        border: "1px solid #DFD1E8",
        paddingLeft: "35px",
        paddingRight: "20px",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#fafafa",
        cursor: "pointer",
        "& input[type=file]": {
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer",
            zIndex: 1
        },
    },
    icons: {
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
        width: "20px",
        height: "auto",
    },
    link: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(4),
        display: 'block',
        textDecoration: 'none',
        width: "32%"
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ModalContactsFile = ({ open, onClose }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [checked, setChecked] = useState(false);
    const [fileName, setFileName] = useState('');
    const fileUploadRef = useRef(null);
    const [blockImportButton, setBlockImportButton] = useState(true);

    const text = `Selecione o arquivo para adicionar vários
    contatos de uma só vez. Caso deseja atualizar os dados
    de contatos já cadastrados de maneira massiva,
    então assinale a opção "Sobrescreva dados existentes"
   `;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleFileChange = (event) => {
        setBlockImportButton(true);
        setFileName('');
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const fullFileName = file.name;
            const truncatedFileName = truncateFileName(fullFileName, 20);
            setFileName(truncatedFileName);
            setBlockImportButton(false);
        }
    };

    const truncateFileName = (name, maxLength) => {
        if (name.length > maxLength) {
            return name.slice(0, maxLength) + '...';
        }
        return name;
    };

    const handleImportContacts = async () => {
        try {
            const formData = new FormData();
            const file = fileUploadRef.current.files[0];
            formData.append("file", file)
            formData.append("checked", checked.toString());
            await api.post(`contacts/xlsxImport`, formData);
            handleClose();
            toast.success(i18n.t("fileContactModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setChecked(false);
        setFileName('');
        setBlockImportButton(true);
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <Paper variant="outlined">
                    <DialogTitle id="form-dialog-title">
                        {i18n.t("fileContactModal.title")}
                    </DialogTitle>
                </Paper>
                <Formik
                    initialValues={{}}
                    onSubmit={handleImportContacts}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Typography className={classes.textTypography}>
                                {text}
                            </Typography>

                            <Grid className={classes.gridStyle}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            name="overwrite"
                                            color="primary"
                                        />
                                    }
                                    label={i18n.t("fileContactModal.checked")}
                                />
                                <Grid container>
                                    <Button
                                        className={classes.btnFile}
                                    >
                                        <AttachFileIcon className={classes.icons} />
                                        {i18n.t("fileContactModal.buttons.selectFile")}
                                        <input
                                            type="file"
                                            ref={fileUploadRef}
                                            onClick={(e) => { e.stopPropagation(); }}
                                            onChange={handleFileChange}
                                            accept=".xlsx, .xls, .csv"
                                        />
                                    </Button>

                                    {fileName ? (
                                        <Grid style={{ margin: "35px 0 0 15px" }}>
                                            <Typography variant="body1" className={classes.fileName}>
                                                {fileName}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <LightTooltip title={i18n.t("fileContactModal.tipsOnImportingFile")}>
                                            <HelpOutlineIcon style={{ marginTop: 20, marginLeft: 3 }} fontSize="small" />
                                        </LightTooltip >
                                    )}
                                </Grid>
                            </Grid>
                            <Link
                                href={planilhaExemplo}
                                download="planilha.xlsx"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                {i18n.t("fileContactModal.buttons.linkDownload")}
                            </Link>
                            <Paper variant="outlined">
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("fileContactModal.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={blockImportButton}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {i18n.t("fileContactModal.buttons.import")}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ModalContactsFile;
