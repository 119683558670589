import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { SendMediaContext } from "../../context/SendMediaContext/SendMediaContext";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typographyStyle: {
        fontSize: "25px",
        [theme.breakpoints.up('xs')]: {
            fontSize: "18px",
        },
        [theme.breakpoints.up('md')]: {
            height: 200
       },
        [theme.breakpoints.up('lg')]: {
            fontSize: "25px",
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "25px",
        },
    },
}));

const DragFile = ({ onClose }) => {
    const classes = useStyles();
    const [file, setFile] = useState();
    const { setSendMedia } = useContext(SendMediaContext);

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFile(droppedFiles);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (file) {
            setSendMedia(file);
            handleClose();
        }
    }, [file]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Grid container
            className={classes.gridContainer}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <Grid item>
                <Typography align="center" className={classes.typographyStyle}>
                    Arraste o arquivo aqui
                </Typography>
            </Grid>
        </Grid>
    );
}

export default DragFile;