import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { formatDate } from '../../helper/helper';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    display: "flex"
  },
  chip: {
    marginBottom: 12
  },
  titleIcon: {
    marginRight: 5
  },
  date: {
    marginBottom: 12
  }
});

const AlertIcon = ({ type }) => {
  const classes = useStyles();
  return (
    type === 'notification' ? (
      <InfoIcon style={{ color: "#2196f3" }} className={classes.titleIcon} />
    ) : type === 'update' ? (
      <SystemUpdateAltIcon style={{ color: "#4caf50" }} className={classes.titleIcon} />
    ) : null
  )
}

const AlertCard = ({ alert }) => {
  const { title, message, type, link, createdAt } = alert;
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.pos}>
          <AlertIcon type={type}/>
          <Typography variant="body1">
            { title }
          </Typography>
        </div>
        <Typography variant="body2" color="textSecondary" className={classes.date}>
          {formatDate(new Date(createdAt))}
        </Typography>
        {/* <Chip
          className={classes.chip}
          size="small"
          label={tagName}
          style={{backgroundColor:"#4caf50"}}
          color="primary"
        /> */}
        <Typography variant="body2" component="p">
          {message}
        </Typography>
      </CardContent>
      {
        link &&
        <CardActions>
          <Button href={link} target="_blank" size="small">Ler mais</Button>
        </CardActions>
      }
    </Card>
  );
}

export default AlertCard;