import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import LoopIcon from '@material-ui/icons/Loop';
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Chip, CircularProgress, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import CompanyUsers from "../../components/CompanyUsers";
import { grey } from '@material-ui/core/colors';
import ModulesList from "../../components/ModulesList";

const useStyles = makeStyles((theme) => ({
  root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},
  mainPaper: {
    flex: 1,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "100%"
  },
  boxContainer: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
		[theme.breakpoints.up("sm")]: {
			marginTop: 5
		}
	},
  rootCardConnection: {
    flexGrow: 1,
  },
  cardSubtitle: {
		color: grey[600],
		fontSize: '14px'
	},
  cardTitle: {
		fontSize: '18px',
		color: theme.palette.text.primary
	},
}));

const CompanySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nome muito curto!")
    .required("Obrigatório"),
  whatsappType: Yup.string()
  .required("Obrigatório")
});


const CompanyEdit = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  let { companyId } = match.params;
  if (companyId === "new") {
    companyId = null;
  }
  const initialState = {
    name: "",
    cnpj: "",
    whatsappType: "",
    pushNotifications: false,
    companyPlan: {
      price: 0,
      planId: 0,
      period: "",
      addWhatsapps: 0,
      addUsers: 0,
      addQueues: 0,
      addWhatsappPrice: 0,
      addUserPrice: 0,
      addQueuePrice: 0,
      marketingModule: false,
      botModule: false
    }
  };

  const [company, setCompany] = useState(initialState);
  const [partners, setPartners] = useState([]);
  const [plans, setPlans] = useState([]);
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [tokenApi, setTokenApi] = useState("");
  const [messageTab, setMessageTab] = useState(0);

  useEffect(() => {
    const fetchCompany = async () => {
      if (!companyId) return;

      try {
        const { data } = await api.get(`/companies/${companyId}`);
        setCompany(data.company);
        setTokenApi(data.tokenApi);
        setSelectedPartner(data.company.partnerId);
      } catch (err) {
        toastError(err);
      }
    };
    const fetchWhatsapps = async () => {
      if (!companyId) return;
      try {
        const { data } = await api.get(`/companies-whatsapps/${companyId}`);
        setWhatsapps(data)
      } catch (err) {
        toastError(err);
      }
    };

    fetchWhatsapps();
    fetchCompany();
  }, [companyId]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const { data } = await api.get("/partners");
        setPartners(data)
      } catch (err) {
        toastError(err);
      }
    };

    fetchPartners();
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const { data } = await api.get("/companies-plans");
        setPlans(data)
      } catch (err) {
        toastError(err);
      }
    };

    fetchPlans();
  }, []);

  const handleSaveCompany = async values => {
		try {
			if (companyId) {
				const { data } = await api.put(`/companies/${companyId}`, { ...values, partnerId: selectedPartner });
        if (!tokenApi) {
          setTokenApi(data.tokenApi);
        }
        toast.success(i18n.t("companies.toasts.edited"));
			} else {
				const { data } = await api.post("/companies", { ...values, partnerId: selectedPartner });
        setTokenApi(data.tokenApi);
        history.push(`/companies/${data.company.id}`);
        toast.success(i18n.t("companies.toasts.created"));
			}
		} catch (err) {
			toastError(err);
		}
	};

  const handleGenerateToken = async () => {
    try {
      const { data } = await api.post(`/companies/token`, { companyId });
      setTokenApi(data.tokenApi);
      toast.success("Novo token gerado com sucesso!");
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <MainHeader>
          <Title>{company ? company.name : ''}</Title>
        </MainHeader>
        <Paper
          className={classes.mainPaper}
        >
          <Tabs
            value={messageTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v) => setMessageTab(v)}
            variant="fullWidth"
            centered
            style={{
              background: "#f2f2f2",
              border: "1px solid #e6e6e6",
              borderRadius: 2,
            }}
          >
            <Tab label="Dados gerais" index={0} />
            <Tab label="Plano" index={1} />
            <Tab label="Usuários" index={2} />
            <Tab label="Conexões" index={3} />
          </Tabs>
          <Formik
            initialValues={company}
            enableReinitialize={true}
            validationSchema={CompanySchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveCompany(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                {messageTab === 0 && (
                  <>
                    <Box className={classes.formContainer}>
                      <Grid spacing={1} container>
                        <Grid item sm={6} xs={12}>
                          <Field
                            as={TextField}
                            label={i18n.t("contactModal.form.name")}
                            name="name"
                            autoFocus
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Field
                            as={TextField}
                            fullWidth
                            name="cnpj"
                            label="CNPJ"
                            helperText={touched.cnpj && errors.cnpj}
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                            <InputLabel>Parceiro</InputLabel>
                            <Field
                              as={Select}
                              value={selectedPartner ? selectedPartner : ""}
                              onChange={(e) => setSelectedPartner(e.target.value)}
                              name="partnerId"
                              label="Parceiro"
                            >
                              {partners?.map((partner, index) => {
                                return (
                                  <MenuItem key={index} value={partner.id}>{partner.name}</MenuItem>
                                )
                              })}
                              <MenuItem value={null}>&nbsp;</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                          <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                            <InputLabel>Lib padrão para novas conexões</InputLabel>
                            <Field
                              as={Select}
                              name="whatsappType"
                              label="Lib padrão para novas conexões"
                            >
                              <MenuItem value="wwebjs">whatsapp-web.js</MenuItem>
                              <MenuItem value="evolution">Evolution API</MenuItem>
                              <MenuItem value="baileys">Baileys</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Box className={classes.boxContainer}>
                            <Typography variant="subtitle1">Notificações Push</Typography>
                            <Field
                              as={Checkbox}
                              checked={values.pushNotifications || false}
                              label="Notificações Push"
                              name="pushNotifications"
                              variant="outlined"
                              margin="dense"
                            />
                          </Box>
                        </Grid>
                        {
                          (companyId || tokenApi) &&
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              fullWidth
                              label="Token API"
                              variant="outlined"
                              margin="dense"
                              value={tokenApi}
                              InputProps={{
                                endAdornment: (
                                  <Tooltip
                                    arrow
                                    placement="right"
                                    title="Gerar Token API"
                                  >
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => handleGenerateToken()}>
                                        <LoopIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  </Tooltip>
                                ),
                              }}
                            />
                          </Grid>
                        }
                      </Grid>
                      <div style={{ display: "flex" }}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          style={{ marginLeft: "auto", marginTop: "10px" }}
                        >
                          Salvar
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </div>
                    </Box>
                  </>
                )}
                {messageTab === 1 && (
                  <>
                    <Box className={classes.formContainer}>
                      <Grid spacing={1} container>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Valor"
                            name="companyPlan.price"
                            type="number"
                            value={values?.companyPlan?.price}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                            <InputLabel>Plano</InputLabel>
                            <Field
                              as={Select}
                              name="companyPlan.planId"
                              label="Plano"
                            >
                              {plans?.map((plan, index) => {
                                return (
                                  <MenuItem key={index} value={plan.id}>{plan.name}</MenuItem>
                                )
                              })}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                            <InputLabel>Recorrência</InputLabel>
                            <Field
                              as={Select}
                              name="companyPlan.period"
                              label="Recorrência"
                            >
                              <MenuItem value="mensal">Mensal</MenuItem>
                              <MenuItem value="semestral">Semestral</MenuItem>
                              <MenuItem value="anual">Anual</MenuItem>
                              <MenuItem value="semanal">Semanal</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Conexões adicionais"
                            name="companyPlan.addWhatsapps"
                            type="number"
                            value={values?.companyPlan?.addWhatsapps}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Usuários adicionais"
                            name="companyPlan.addUsers"
                            type="number"
                            value={values?.companyPlan?.addUsers}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Departamentos adicionais"
                            name="companyPlan.addQueues"
                            type="number"
                            value={values?.companyPlan?.addQueues}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Valor conexão adicional"
                            name="companyPlan.addWhatsappPrice"
                            type="number"
                            value={values?.companyPlan?.addWhatsappPrice}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Valor usuário adicional"
                            name="companyPlan.addUserPrice"
                            type="number"
                            value={values?.companyPlan?.addUserPrice}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Valor departamento adicional"
                            name="companyPlan.addQueuePrice"
                            type="number"
                            value={values?.companyPlan?.addQueuePrice}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <ModulesList
                            companyPlan={values?.companyPlan}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ display: "flex" }}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          style={{ marginLeft: "auto", marginTop: "10px" }}
                        >
                          Salvar
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </div>
                    </Box>
                  </>
                )}
              </Form>
            )}
          </Formik>
          {messageTab === 2 && companyId && (
            <Grid container>
              <Grid item xs={12}>
                <div style={{ padding: 10}}>
                  <CompanyUsers companyId={companyId} />
                </div>
              </Grid>
            </Grid>
          )}
          {messageTab === 3 && companyId && (
            <div style={{ padding: 10 }}>
              <Grid container className={classes.rootCardConnection} spacing={2}>
                <Grid item xs={12} >
                  <Grid container spacing={2}>
                    {whatsapps?.map((whatsapp, index) => {
                      return (
                        <Grid xs={4} key={whatsapp.id} item>
                          <Card style={{ width: "100%", height: "100%" }}>
                            <CardHeader
                              style={{ paddingBottom: 0 }}
                              title={
                                <Typography variant="h6" component="h2" className={classes.cardTitle}>
                                  {whatsapp.name}
                                </Typography>
                              }
                            />
                            <CardContent>
                              <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                ID: {whatsapp.id}
                              </Typography>
                              <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                Status: {whatsapp.status}
                              </Typography>
                              <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                Canal: {whatsapp.channel}
                              </Typography>
                              <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                Tipo: {whatsapp.type}
                              </Typography>
                              {
                                whatsapp.type === "wwebjs" &&
                                <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                  Versão do Whatsapp Web: {whatsapp.webVersion}
                                </Typography>
                              }
                              <br></br>
                              <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                                Departamentos:
                              </Typography>
                              {whatsapp.queues?.map((queue, index) => {
                                return (<Chip style={{ margin: 2 }} key={queue.id} size="small" label={queue.name} />)
                              })}
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>

                </Grid>

              </Grid>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default CompanyEdit;
