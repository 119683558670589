import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const BannerModal = (props) => {
  const { onClose, open, title, banner } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      {
        title && 
        <DialogTitle id="dialog-title">{title}</DialogTitle>
      }
      <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/m9av6pTnrNWQnXJu5">
        <img style={{ display: "flex", width: "100%"}} src={banner}/>
      </a>
    </Dialog>
  );
}

export default BannerModal;