import React, { useState, useEffect, useContext } from "react";
import {
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    Typography,
    Avatar,
    Button,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const filter = createFilterOptions({
    trim: true,
});

const SendContactModal = ({ modalOpen, onClose, ticketId }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const { user } = useContext(AuthContext);

    useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam, companyId: user.companyId },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen, user.companyId]);

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const renderOption = (option) => {
        let label = option.number
            ? `${option.name} - ${option.number}`
            : `${i18n.t("newTicketModal.add")} ${option.name}`;
        return (
            <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Avatar variant="circular" src={option.profilePicUrl} style={{ marginRight: 8 }} />
                <Typography>{label}</Typography>
            </Grid>
        );
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }
        return filtered;
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        }
    };

    const hanldeVcardMessage = async (contact) => {
		try {
            setSendLoading(true);
			await api.post('/vcard', {
				ticketId,
				contactNumber: contact.number,
                contactName: contact.name,
				isGroup: contact.isGroup,
			});
			toast.success("Contato enviado com sucesso!");
			handleClose();
            setSendLoading(false);
		} catch (err) {
			toastError(err);
            setSendLoading(false);
		} 
	};

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedContact(null);
    };

    return (
        <Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{i18n.t("sendContactModal.title")}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={options}
                    loading={loading}
                    style={{ width: "100%" }}
                    clearOnBlur
                    autoHighlight
                    freeSolo
                    clearOnEscape
                    getOptionLabel={renderOptionLabel}
                    renderOption={renderOption}
                    filterOptions={createAddContactOption}
                    onChange={(e, newValue) => handleSelectOption(e, newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={i18n.t("sendContactModal.fieldLabel")}
                            variant="outlined"
                            autoFocus
                            onChange={(e) => setSearchParam(e.target.value)}
                            onKeyPress={e => {
                                if (loading || !selectedContact) return;
                                else if (e.key === "Enter") {
                                    hanldeVcardMessage(selectedContact);
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={sendLoading}
                    variant="outlined"
                >
                    {i18n.t("sendContactModal.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    type="button"
                    disabled={!selectedContact}
                    onClick={() => hanldeVcardMessage(selectedContact)}
                    color="primary"
                    loading={sendLoading}
                >
                    {i18n.t("sendContactModal.buttons.ok")}
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    );
};

export default SendContactModal;
