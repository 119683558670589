import React, { useEffect, useState } from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Typography, Link, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from "react-toastify";

const UPLOAD_ATTACHMENT_MAX_SIZE = process.env.UPLOAD_ATTACHMENT_MAX_SIZE || 15;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "15px",
    },

    icons: {
        margin: "-10px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
        }
    },

    uploadInput: {
        display: "none",
    },
}));

const UploadAttachment = (props) => {
    const { currentAttachmentUrl, currentAttachmentName, loading } = props;

    const classes = useStyles();
    const [attachmentName, setAttachmentName] = useState(null);

    useEffect(() => {
        if (currentAttachmentName) {
            setAttachmentName(currentAttachmentName)
        }
    }, [currentAttachmentName])

    const handleUploadMedia = (e) => {
        if (!e.target.files) {
            return;
        }
        const selectedMedia = e.target.files[0];
        const maxSizeInBytes = +UPLOAD_ATTACHMENT_MAX_SIZE * 1024 * 1024;
        if (selectedMedia.size > maxSizeInBytes) {
            const warningSize = `O arquivo selecionado excede o tamanho máximo de ${UPLOAD_ATTACHMENT_MAX_SIZE}MB.`;
            toast.warning(warningSize, {
                toastId: warningSize,
            });
            return;
        }
        if (selectedMedia) {
            props.onUploadAttachment(selectedMedia);
            setAttachmentName(selectedMedia.name);
        }
    };

    const handleDeleteAttachment = (e) => {
        props.onDeleteAttachment(e);
        //setAttachmentName(null);
    };

    return (
        <div className={classes.root}>
            <>
                <input
                    multiple={false}
                    type="file"
                    id="upload-button"
                    className={classes.uploadInput}
                    onChange={handleUploadMedia}
                />
                <Tooltip
                    arrow
                    placement="top"
                    title="Adicionar arquivo"
                >

                    <label htmlFor="upload-button">
                        <IconButton
                            aria-label="upload"
                            component="span"
                            className={classes.icons}
                        >
                            {loading === false ? (
                                <AttachFileIcon />

                            ) : (<CircularProgress size={"20px"} />)}
                        </IconButton>
                    </label>
                </Tooltip>
            </>
            <>
                {currentAttachmentName && (
                    <Tooltip
                        arrow
                        placement="top"
                        title="Remover arquivo"
                    >
                        <DeleteOutlineIcon className={classes.icons} onClick={(e) => handleDeleteAttachment(e)} />
                    </Tooltip>
                )}
                {currentAttachmentName && (
                    <Typography>
                        <Link
                            target="_blank"
                            href={currentAttachmentUrl}
                            variant="body2"
                            download={false}
                        >
                            {`${attachmentName?.substring(0, 20)}...`}
                        </Link>
                    </Typography>
                )}
            </>
        </div>
    );
};

export default UploadAttachment;