import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Checkbox, Chip, Divider, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsTagSelect = ({
	listTags,
	selectedTagIds = [],
	notTagSelected = false,
	allTagsSelected = false,
	onChange,
}) => {
	const handleChange = e => {
		if (e.currentTarget.dataset.value === 'allTagsSelected') {
			onChange('allTagsSelected');
		} else if (e.currentTarget.dataset.value === 'notTagSelected') {
			onChange('notTagSelected');
		} else {
			onChange(e.target.value);
		}
	};

	return (
		<Box style={{ width: "100%" }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedTagIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={() => {
						return (
							<>
								{i18n.t("ticketsTagSelect.placeholder") + " "}
								{(selectedTagIds.length > 0 || notTagSelected) && 
									(<Chip size="small" label={
										(notTagSelected ? selectedTagIds.length + 1 : selectedTagIds.length).toString()
									} color="secondary" />)
								}
							</>
						)
					}}
				>
					{<MenuItem dense key={'allTagsSelected'} value={'allTagsSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={allTagsSelected}
						/>
						<ListItemText primary={"Selecionar todos"} />
					</MenuItem>}

					{<MenuItem dense key={'notTagSelected'} value={'notTagSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={notTagSelected}
						/>
						<ListItemText primary={"Sem Etiqueta"} />
					</MenuItem>}

					{<Divider></Divider>}

					{listTags?.length > 0 &&
						listTags.map(tag => (
							<MenuItem dense key={tag.id} value={tag.id}>
								<Checkbox
                                    style={{
                                        color: tag.color,
                                    }}                                
									size="small"
									color="primary"
									checked={selectedTagIds.indexOf(tag.id) > -1}
								/>
								<ListItemText primary={tag.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default TicketsTagSelect;
