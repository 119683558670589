import React, { useState, useRef, useEffect, useContext } from "react";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Notifications from "@material-ui/icons/Notifications";

import AlertCard from "../AlertCard";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 500,
        ...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 350,
		[theme.breakpoints.down("sm")]: {
			maxWidth: 300,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
	listItem: {
		justifyContent: "center"
	}
}));

const AlertsPopOver = () => {
	const classes = useStyles();
	const [alerts, setAlerts] = useState([]);
	const [unreadCount, setUnreadCount] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const anchorEl = useRef();
	const { user } = useContext(AuthContext);

	useEffect(() => {
		const fetchAlerts = async () => {
			try {
				const { data } = await api.get("/alerts");
				setAlerts(data.alerts);
				setUnreadCount(data.unreadCount);
			} catch (err) {
				toastError(err);
			}
		};
		if (user.companyId !== 558) {
			fetchAlerts();
		}
	}, []);

	const handleClick = () => {
		const fetchAlertRead = async () => {
			try {
				await api.post("/alert-read");
				setUnreadCount(0);
			} catch (err) {
				toastError(err);
			}
		};
		fetchAlertRead();
		setIsOpen(!isOpen);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Tooltip
      	arrow
      	placement="top"
      	title="Alertas do Sistema"
    	>
				<IconButton
					onClick={handleClick}
					ref={anchorEl}
					aria-label="Open Notifications"
					color="inherit"
				>
					<Badge overlap="rectangular" badgeContent={unreadCount} color="secondary">
						<Notifications />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<List dense className={classes.tabContainer}>
					{alerts.length === 0 ? (
						<ListItem>
							<ListItemText>Nenhum alerta.</ListItemText>
						</ListItem>
					) : (
						alerts.map(alert => (
							<ListItem key={alert.id} className={classes.listItem}>
								<AlertCard
									alert={alert}>
								</AlertCard>
							</ListItem>
						))
					)}
				</List>
			</Popover>
		</>
	);
};

export default AlertsPopOver;