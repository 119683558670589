import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import { Avatar, FormControl, InputLabel, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import { TagsContacts } from "../TagsContacts";


const filter = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
}));

const NewTicketModal = ({ modalOpen, onClose }) => {
	const history = useHistory();

	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedUser, setSelectedUser] = useState('');
	const [selectedTags, setSelectedTags] = useState([]);
	const [ oldTagsContacts, setOldTagsContacts] = useState([])
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
	const classes = useStyles();

	const validationWhatsapp = user.whatsapps.length > 1 ? true : false

	useEffect(() => {
		if (!validationWhatsapp) {
			setSelectedWhatsapp(user.whatsapps[0]?.id);
		}
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam, companyId: user.companyId },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen, user.companyId]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		setLoading(true);
		try {
			const { data: ticketExist } = await api.get(`/tickets/getTicketByContactId/${contactId}`);

			if (ticketExist) {
				let message = "";
				if (ticketExist.status === "pending") {
					message = i18n.t(`contacts.toasts.ticketPending`);
					toast.error(message);
				} else {
					const userCanOpenTicket = user.queues.filter((q) => q.id === ticketExist.queueId).length > 0;
					const toastClick = () => {
						if ((user.profile.toUpperCase() === "ADMIN")
							|| (user.canSeeAllTickets && userCanOpenTicket)) {
							history.push(`/tickets/${ticketExist.id}`);
						}
					};

					message = i18n.t(`contacts.toasts.ticketExist`)
						+ `${ticketExist.user ? ticketExist.user.name + '. ' : ''}`;
					if ((user.profile.toUpperCase() === "ADMIN")
						|| (user.canSeeAllTickets && userCanOpenTicket)) {
						message = message + i18n.t(`contacts.toasts.clickText`);
					}

					toast.error(message, {
						onClick: toastClick,
					});
				}

			} else {
				const combinedTags = selectedTags.concat(oldTagsContacts);
				const { data: ticket } = await api.post("/tickets", {
					contactId: contactId,
					status: "open",
					whatsappId: selectedWhatsapp,
					queueId: selectedQueue || undefined,
					tagsId: combinedTags

				});
				history.push(`/tickets/${ticket.id}`);
				setSelectedTags([]);
				setOldTagsContacts([]);
			}
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {			
			setSelectedContact(newValue);
			setOldTagsContacts(newValue?.tags);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		let label = option.number ?
			`${option.name} - ${option.number}` : `${i18n.t("newTicketModal.add")} ${option.name}`;
		return (
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<Avatar variant="circle" src={option.profilePicUrl} style={{ marginRight: 8 }} />
				<Typography>{label}</Typography>
			</div>
		);
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	const handleSelectTags = async (tags) => {
		setSelectedTags(tags);
	}

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: '100%' }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					{validationWhatsapp && (
						<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
							<InputLabel>{i18n.t("newTicketModal.fieldConnectionLabel")}</InputLabel>
							<Select
								value={selectedWhatsapp}
								onChange={(e) => setSelectedWhatsapp(e.target.value)}
								label={i18n.t("newTicketModal.fieldConnectionPlaceholder")}
							>
								{user.whatsapps.map((whasapp) => (
									<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20, width: '100%' }}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => {
								setSelectedQueue(e.target.value);
								setSelectedUser('');
							}}
							label={i18n.t("transferTicketModal.fieldQueueLabel")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{user.queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}

						</Select>
					</FormControl>
					<div style={{ marginTop: 20 }}>
						<TagsContacts
							onSelectTags={handleSelectTags} isWithoutTag={false} size={'medium'} width={'100%'}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
