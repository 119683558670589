import React, { useState, useEffect } from "react";
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import Badge from '@material-ui/core/Badge';
import { useTheme } from '@material-ui/core/styles';
import { ExpandMore, Height } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonScrollDown: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(1.5),
    '@media (max-width: 600px)': {
      opacity: 0.5,
    },
  },
  badgeStyle: {
    backgroundColor: "#3CB371",
		color: "#FFFFFF",
    top: 4,
    left: 4
  }
}));

const ButtonScrollDown = ({ showButtonDown, newMessageCount, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Badge
      className={classes.buttonScrollDown}
      color="secondary"
      badgeContent={newMessageCount}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        badge: classes.badgeStyle,
      }}>
      <Zoom
        in={showButtonDown}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${showButtonDown ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          style={{ backgroundColor: '#fff' }}
          size="small"
          onClick={onClick}
          aria-label="Deslizar para o fim da página">
          <ExpandMore />
        </Fab>
      </Zoom>
    </Badge>
  );
};

export default ButtonScrollDown;