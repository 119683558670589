import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";

function connectToSocket() {
        return openSocket(getBackendUrl(), {
        transports: ["websocket", "polling", "flashsocket"],
        pingTimeout: 180000,
        pingInterval: 60000,
        auth: (cb) => {
            const tokenItem = localStorage.getItem('token');
            const token = tokenItem ? JSON.parse(tokenItem) : null;
            cb({ token });
        }
    });
}

export default connectToSocket;