import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsSelectBar from "../TicketsSelectBar/TicketsSelectBar";
import { shouldUpdateTicketList } from "../../helper/helper";
import { TicketsActionsContext } from "../../context/TicketsActionsContext/TicketsActionsContext";

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	ticketsList: {
		flex: 1,
		overflowY: "auto",
		...theme.scrollbarStyles,
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 4,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const orderByPinnedTickets = (tickets) => {
	const p = localStorage.getItem("pinnedTickets");
	const pinnedIds = p ? JSON.parse(p) : null;

	if (Array.isArray(pinnedIds) && Array.isArray(tickets)) {
		tickets.map(() => {
			pinnedIds.forEach(id => {
				const item = tickets.find(t => t.id === id);
				const index = tickets.indexOf(item);
				if (index > -1) {
					tickets.splice(index, 1);
					tickets.unshift(item);
				}
			});
		});
	}
	return tickets;
}

const reducer = (state, action) => {
	const sortAsc = action.sortAsc;
	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) {
					if (sortAsc) {
						state.push(state.splice(ticketIndex, 1)[0]);
					} else {
						state.unshift(state.splice(ticketIndex, 1)[0]);
					}
				}
			} else {
				state.push(ticket);
			}
		});
		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
		}

		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "CHECK_ALL") {
		const { selectAll } = action.payload;
		const updatedState = state.map((item) => {
			return {
				...item, selected: selectAll
			};
		});
		return [...updatedState];
	}

	if (action.type === "CHECK_ITEM") {
		const { id, checked } = action.payload;
		const updatedState = state.map((ticket) => {
			if (ticket.id === id) {
				return { ...ticket, selected: checked };
			}
			return ticket;
		});

		return [...updatedState];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			if (sortAsc) {
				state.push(ticket);
			} else {
				state.unshift(ticket);
			}
		}
		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "BULK_UPDATE_TICKET") {
		const tickets = action.payload;
		if (sortAsc) {
			state.push(...tickets);
		} else {
			state.unshift(...tickets);
		}
		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			if (sortAsc) {
				state.push(state.splice(ticketIndex, 1)[0]);
			} else {
				state.unshift(state.splice(ticketIndex, 1)[0]);
			}
		} else {
			if (sortAsc) {
				state.push(ticket);
			} else {
				state.unshift(ticket);
			}
		}
		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}

		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		state = orderByPinnedTickets(state);
		return [...state];
	}

	if (action.type === "BULK_DELETE_TICKETS") {
		const ticketIds = action.payload;
		if (ticketIds) {
			const updatedState = state.filter((ticket) => !ticketIds.includes(ticket.id));

			state = orderByPinnedTickets(state);
			return [...updatedState];
		}
	}

	if (action.type === "RESET") {
		return [];
	}
};

const TicketsList = (props) => {
	const {
		withUnreadMessages,
		sortAsc,
		status,
		searchParam,
		showAll,
		selectedQueueIds,
		updateCount,
		style,
		tabOpen,
		selectedTagIds,
		listUsers,
		selectedUserIds,
		selectedConnectionsIds,
		notQueueSelected,
		allQueueSelected,
		notUserSelected,
		allUsersSelected,
		notTagSelected,
		allTagsSelected,
		notConnectionSelected,
		groups,
		isTabGroupsEnabled,
		allConnectionsSelected,
		dateStart,
		dateEnd
	} = props;
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const [ pinButtonClick, setPinButtonClick ] = useState(false);
	const { user } = useContext(AuthContext);
	const { setNewMessage } = useContext(TicketsActionsContext);

	const { tickets, hasMore, loading } = useTickets({
		pinButtonClick,
		withUnreadMessages,
		sortAsc,
		pageNumber,
		searchParam,
		status,
		showAll,
		notQueueSelected: notQueueSelected,
		allQueueSelected: allQueueSelected,
		notUserSelected: notUserSelected,
		allUsersSelected: allUsersSelected,
		notTagSelected: notTagSelected,
		allTagsSelected: allTagsSelected,
		notConnectionSelected: notConnectionSelected,
		allConnectionsSelected: allConnectionsSelected,
		groups,
		isTabGroupsEnabled,
		dateStart: dateStart,
		dateEnd: dateEnd,
		queueIds: JSON.stringify(selectedQueueIds),
		tagIds: JSON.stringify(selectedTagIds),
		listUserIds: JSON.stringify(selectedUserIds),
		listConnectionsIds: JSON.stringify(selectedConnectionsIds)
	});

	useEffect(() => {
		dispatch({ type: "RESET", sortAsc: sortAsc });
		setPageNumber(1);
	}, [
		withUnreadMessages,
		sortAsc,
		status,
		searchParam,
		dispatch,
		showAll,
		selectedQueueIds,
		selectedTagIds,
		selectedUserIds,
		selectedConnectionsIds,
		notQueueSelected,
		allQueueSelected,
		notUserSelected,
		allUsersSelected,
		notTagSelected,
		allTagsSelected,
		notConnectionSelected,
		allConnectionsSelected,
		isTabGroupsEnabled,
		dateStart,
		dateEnd,
		pinButtonClick
	]);

	useEffect(() => {
		dispatch({
			type: "LOAD_TICKETS",
			payload: tickets,
			sortAsc: sortAsc
		});
	}, [ tickets ]);

	useEffect(() => {
		const socket = openSocket();

		const shouldUpdateTicket = ticket => {

			const filters = {
				selectedQueueIds,
				selectedUserIds: selectedUserIds.length > 0 ?
					selectedUserIds : listUsers.map(u => u.id),
				selectedTagIds,
				selectedConnectionsIds
			};

			if (groups && !ticket.isGroup) {
				return false;
			}

			if (status === "open" && isTabGroupsEnabled && ticket.isGroup) {
				return false;
			}
			return shouldUpdateTicketList(ticket, filters, user, searchParam);
		}

		const notBelongsToUserQueues = ticket =>
			ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

		socket.on("connect", () => {
			if (status) {
				socket.emit(`${user.companyId}:joinTickets`, status);
			} else {
				socket.emit(`${user.companyId}:joinNotification`);
			}
		});

		socket.on(`ticket${user.companyId}`, data => {
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
					sortAsc: sortAsc
				});
			}

			if (data.action === "bulk_update") {
				const ticketsFiltered = data.ticketsUpdated.filter(t => shouldUpdateTicket(t));
				dispatch({
					type: "BULK_UPDATE_TICKET",
					payload: ticketsFiltered,
					sortAsc: sortAsc
				});
			}

			if (data.action === "bulk_resolve" && shouldUpdateTicket(data.ticketsResolved)) {
				dispatch({
					type: "BULK_UPDATE_TICKET",
					payload: data.ticketsResolved,
					sortAsc: sortAsc
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
					sortAsc: sortAsc
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({ type: "DELETE_TICKET", payload: data.ticket.id, sortAsc: sortAsc });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.ticketId, sortAsc: sortAsc });
			}

			if (data.action === "bulkDelete") {
				dispatch({ type: "BULK_DELETE_TICKETS",	payload: data.ticketIds, sortAsc: sortAsc });
			}
		});

		socket.on(`appMessage${user.companyId}`, data => {
			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				setNewMessage(groups ? "groups" : status);
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
					sortAsc: sortAsc
				});
			}
		});

		socket.on(`contact${user.companyId}`, data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
					sortAsc: sortAsc
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [
		withUnreadMessages,
		sortAsc,
		status,
		searchParam,
		showAll,
		user,
		selectedQueueIds,
		notQueueSelected,
		allQueueSelected,
		listUsers,
		selectedUserIds,
		notUserSelected,
		allUsersSelected,
		selectedTagIds,
		notTagSelected,
		allTagsSelected,
		selectedConnectionsIds,
		notConnectionSelected,
		allConnectionsSelected,
		dateStart,
		dateEnd
	]);

	useEffect(() => {
		if (typeof updateCount === "function") {
			updateCount(ticketsList.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketsList]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			e.currentTarget.scrollTop = scrollTop - 100;
			loadMore();
		}
	};

	const handlePinButtonClick = () => {
		setPinButtonClick((prevState) => !prevState);
	}

	return (
		<Paper square className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<TicketsSelectBar dispatch={dispatch} ticketsList={ticketsList} tabOpen={tabOpen} />
				<List style={{ paddingTop: 0, paddingBottom: 0 }}>
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (
						<div>
							{ticketsList.map(ticket => (
									<TicketListItem
										onPinButtonClick={handlePinButtonClick}
										dispatch={dispatch}
										ticket={ticket}
										key={ticket.id}
										selected={ticket.selected}
									/>
							))}
						</div>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
		</Paper>
	);
};

export default TicketsList;
