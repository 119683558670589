import React, { useCallback, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core";

import './style.css';
import { IconButton } from '@material-ui/core';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true
};

const useStyles = makeStyles(() => ({
  document: {
    width: "100%",
    height: "100%"
  },
  documentThumbnail: {
    width: 340,
    height: 390
  },
	pdfCanvas: {
    '& .react-pdf__Page__canvas': {
      height: [['100%'], '!important'],
      width: [['100%'], '!important'],
    },
  },
	pdfCanvasThumbnail: {
    width: 340,
    height: 390,
    '& .react-pdf__Page__canvas': {
      width: [['340px'], '!important'],
      height: [['390px'], '!important'],
    },
  },
}));

const PDFViewer = ({ pdfUrl, width, isThumbnail }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  const onDocumentLoadSuccess = useCallback((document) => {
    const { numPages: nextNumPages } = document;
    setNumPages(nextNumPages);
    setPageNumber(1);
  }, []);

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  const onPassword = (callback, reason) => {
    const callbackProxy = (password) => {
      if (password === null) {
        return this.props.onCancel();
      }
      callback(password);
    }
  
    switch (reason) {
      case 1: {
        const password = prompt('Este arquivo está protegido por senha. Digite uma senha para abrir o arquivo.');
        callbackProxy(password);
        break;
      }
      case 2: {
        const password = prompt('Senha inválida. Por favor tente novamente.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  return (
    <Document 
      className={isThumbnail ? classes.documentThumbnail : classes.document} 
      file={pdfUrl} 
      onLoadSuccess={onDocumentLoadSuccess}
      onPassword={onPassword}
      options={options}>
      <Page
        width={width}
        pageNumber={pageNumber}
        className={isThumbnail ? classes.pdfCanvasThumbnail : classes.pdfCanvas}
      />
      <div className="page-controls">
        <IconButton
          disabled={pageNumber <= 1}
          onClick={previousPage}
          type="button"
          size='small'
          aria-label="Previous page">
          <ArrowBackIosIcon />
        </IconButton>
        <span>
          {pageNumber}
          {' '}
          de
          {' '}
          {numPages}
        </span>
        <IconButton
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          type="button"
          size='small'
          aria-label="Next page">
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </Document>
  );
}

export default PDFViewer;