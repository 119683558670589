import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";


import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { formatDateTime } from '../../helper/helper';
import ConfirmationModal from "../../components/ConfirmationModal";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

const reducer = (state, action) => {
  if (action.type === "LOAD_COMPANIES") {
    const companies = action.payload;
    const newCompanies = [];

    companies.forEach((company) => {
      const companyIndex = state.findIndex((c) => c.id === company.id);
      if (companyIndex !== -1) {
        state[companyIndex] = company;
      } else {
        newCompanies.push(company);
      }
    });

    return [...state, ...newCompanies];
  }

  if (action.type === "UPDATE_COMPANIES") {
    const company = action.payload;
    const companyIndex = state.findIndex((c) => c.id === company.id);

    if (companyIndex !== -1) {
      state[companyIndex] = company;
      return [...state];
    } else {
      return [company, ...state];
    }
  }

  if (action.type === "DELETE_COMPANY") {
    const companyId = action.payload;

    const companyIndex = state.findIndex((c) => c.id === companyId);
    if (companyIndex !== -1) {
      state.splice(companyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    flexDirection: "column"
  },
  infiniteScrollContainer: {
    height: "100%",
    overflow: 'auto',
    display: 'flex',
    ...theme.scrollbarStyles,
    scrollBehavior: "smooth",
    flexDirection: "column"
  },
  infiniteScroll: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  circleLoading: {
    color: "#483D8B",
    position: "absolute",
    opacity: "100%",
    top: 200,
    left: "50%",
    marginTop: 12,
  }
}));

const Companies = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [searchParam, setSearchParam] = useState("");
  const [companies, dispatch] = useReducer(reducer, []);
  const [hasMore, setHasMore] = useState(true);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const pageNumberRef = useRef(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    pageNumberRef.current = 1;
    fetchCompanies();
  }, [searchParam]);

  const fetchCompanies = async () => {
    setIsLoading(true); 
    try {
      const pgNumber = pageNumberRef.current;
      const { data } = await api.get("/companies/", {
        params: { searchParam, pageNumber: pgNumber },
      });
      pageNumberRef.current = pgNumber + 1;
      dispatch({ type: "LOAD_COMPANIES", payload: data.companies });
      setHasMore(data.hasMore);
    } catch (err) {
      toastError(err);
    } finally {
      setIsLoading(false); 
    }
  }

  useEffect(() => {
    const socket = openSocket();

    socket.on(`company${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPANIES", payload: data.company });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPANY", payload: +data.companyId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditCompany = (company) => {
    history.push(`/companies/${company.id}`);
  }

  const handleNewCompany = () => {
    history.push(`/companies/new`);
  }

  const handleDeleteCompany = async (companyId) => {
    try {
      await api.delete(`/companies/${companyId}`);
      toast.success(i18n.t("companies.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingCompany(null);
    setSearchParam("");
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ConfirmationModal
        title={
          deletingCompany ?
            `${i18n.t("companies.confirmationModal.deleteTitle")} ${deletingCompany.name
            }?` : ''
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          handleDeleteCompany(deletingCompany.id)
        }
      >
        {i18n.t("companies.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("companies.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("companies.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewCompany}
          >
            {i18n.t("companies.newCompanyButton")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <div
          id="companiesList"
          className={classes.infiniteScrollContainer}>
          <InfiniteScroll
            className={classes.infiniteScroll}
            dataLength={companies.length}
            next={fetchCompanies}
            hasMore={hasMore}
            loader={isLoading ?<CircularProgress className={classes.circleLoading} /> : null}
            scrollableTarget="companiesList"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("companies.table.id")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.name")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.cnpj")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.createdAt")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.plan")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell align="center">{company.id}</TableCell>
                    <TableCell align="center">{company.name}</TableCell>
                    <TableCell align="center">{company.cnpj}</TableCell>
                    <TableCell align="center">{formatDateTime(company.createdAt)}</TableCell>
                    <TableCell align="center">{company.companyPlan?.plan?.name}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditCompany(company)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmOpen(true);
                          setDeletingCompany(company);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Companies;
