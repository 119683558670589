import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Checkbox, Chip, Divider, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsUserSelect = ({
	listUsers,
	selectedUserIds = [],
	notUserSelected = false,
	allUsersSelected = false,
	onChange,
}) => {
	const handleChange = e => {
		if (e.currentTarget.dataset.value === 'allUsersSelected') {
			onChange('allUsersSelected');
		} else if (e.currentTarget.dataset.value === 'notUserSelected') {
			onChange('notUserSelected');
		} else {
			onChange(e.target.value);
		}
	};

	return (
		<Box style={{ width: "100%" }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedUserIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={() => {
						return (
							<>
								{i18n.t("ticketsUserSelect.placeholder") + " "}
								{(selectedUserIds.length > 0 || notUserSelected) && 
									(<Chip size="small" label={
										(notUserSelected ? selectedUserIds.length + 1 : selectedUserIds.length).toString()
									} color="secondary" />)
								}
							</>
						)
					}}
				>
					{<MenuItem dense key={'allUsersSelected'} value={'allUsersSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={allUsersSelected}
						/>
						<ListItemText primary={"Selecionar todos"} />
					</MenuItem>}

					{<MenuItem dense key={'notUserSelected'} value={'notUserSelected'}>
						<Checkbox
							size="small"
							color="primary"
							checked={notUserSelected}
						/>
						<ListItemText primary={"Sem Usuário"} />
					</MenuItem>}

					{<Divider></Divider>}

					{listUsers?.length > 0 &&
						listUsers.map(user => (
							<MenuItem dense key={user.id} value={user.id}>
								<Checkbox
									size="small"
									color="primary"
									checked={selectedUserIds.indexOf(user.id) > -1}
								/>
								<ListItemText primary={user.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default TicketsUserSelect;
