function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    return getConfig('REACT_APP_BACKEND_URL');
}

export function getHoursCloseTicketsAuto() {
    return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO');
}

export function getVapidKey() {
    return getConfig('REACT_APP_FIREBASE_VAPIDKEY');
}


export async function getFirebaseConfig() {
    const obj = {
        apiKey: getConfig('REACT_APP_FIREBASE_APIKEY'),
        authDomain: getConfig('REACT_APP_FIREBASE_AUTHDOMAIN'),
        projectId: getConfig('REACT_APP_FIREBASE_PROJECTID'),
        storageBucket: getConfig('REACT_APP_FIREBASE_STORAGEBUCKET'),
        messagingSenderId: getConfig('REACT_APP_FIREBASE_MESSAGINGSENDERID'),
        appId: getConfig('REACT_APP_FIREBASE_APPID')
    }
    return obj;
}