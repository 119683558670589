import React, { useEffect, useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import HeadsetMicTwoToneIcon from '@material-ui/icons/HeadsetMicTwoTone';

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"
import { useHistory } from "react-router-dom"
import api from "../../services/api"

const textColorSecondary = "rgba(0, 0, 0, 0.54)";

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		height: 120,
		cursor: "pointer"
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
		cursor: "pointer"
	},
	waiting: {
		color: theme.palette.warning.main
	},
	finished: {
		color: theme.palette.success.main
	}
}))

const Dashboard = () => {
	const classes = useStyles()
	const history = useHistory();

	const [openTicketCount, setOpenTicketCount] = useState(0);
	const [pendingTicketCount, setPendingTicketCount] = useState(0);
	const [closedTicketCount, setClosedTicketCount] = useState(0);

	useEffect(() => {
		const fetchTicketCounts = async () => {
			const { data: tickets } = await api.get("/ticketsDashboard");
			setOpenTicketCount(tickets?.openCount);
			setPendingTicketCount(tickets?.pendingCount);
			setClosedTicketCount(tickets?.closedCount);
		};
		fetchTicketCounts();
	}, []);

	const goToTickets = (status) => {
		history.push("/tickets", { status });
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<Paper onClick={() => goToTickets("open")} className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<div>
								<Grid item>
									<Typography color="primary" component="h1" variant="h4">
										{openTicketCount}
										{/* {GetTickets("open")} */}
									</Typography>
								</Grid>
								<Typography component="h3" variant="h6" paragraph 
									style={{ color: textColorSecondary }}>
									{i18n.t("dashboard.messages.inAttendance.title")}
								</Typography>
							</div>
							<HeadsetMicTwoToneIcon style={{ fontSize: "3.8rem" }} color="primary"/>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper onClick={() => goToTickets("pending")} className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<div>
								<Grid item>
									<Typography className={classes.waiting} component="h1" variant="h4">
										{pendingTicketCount}
									</Typography>
								</Grid>
								<Typography component="h3" variant="h6" color="primary" paragraph
									style={{ color: textColorSecondary }}>
									{i18n.t("dashboard.messages.waiting.title")}
								</Typography>
							</div>
							<HourglassFullTwoToneIcon style={{ fontSize: "3.8rem" }} className={classes.waiting}/>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper onClick={() => goToTickets("closed")} className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<div>
								<Grid item>
									<Typography className={classes.finished} component="h1" variant="h4">
										{closedTicketCount}
									</Typography>
								</Grid>
								<Typography component="h3" variant="h6" color="primary" paragraph
									style={{ color: textColorSecondary }}>
									{i18n.t("dashboard.messages.closed.title")}
								</Typography>
							</div>
							<CheckCircleTwoToneIcon style={{ fontSize: "3.8rem" }} className={classes.finished}/>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart />
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard;