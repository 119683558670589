import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 90 90 6px rgba(90, 0, 0, 0.3)",
          backgroundColor: "#483D8B",
        },
      },
      palette: {
        primary: {
          main: "#6747D7",
          light: "#9d74ff",
          dark: "#2b1ba4"
        },
        secondary: {
          main: "#ffb000",
          light: "#ffe24b",
          dark: "#c68100"
        },
        backgroundMenu: "#2f2550",
      },
    },
    locale
  );

  useEffect(() => {    
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
