import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { Box, Grid, IconButton, TextField } from "@material-ui/core";

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Field } from "formik";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const WorkingHourTime = ({ touched, errors, workingHour, setWorkingHour, name, index, isWorkingHourEnabled }) => {
	const classes = useStyles();

  const onChangeTimeStart = (e) => {
    e.persist();
    (async () => {
      setWorkingHour(prevState => {
        const newState = prevState;
          newState.days[name][index].start = e.target.value;
        return { ...newState };
      });
    })();   
  }

  const onChangeTimeEnd = (e) => {
    e.persist();
    (async () => {
      setWorkingHour(prevState => {
        const newState = prevState;
          newState.days[name][index].end = e.target.value;
        return { ...newState };
      });
    })();   
    
  }

  const handleRemoveHour = () => {
		setWorkingHour(prevState => {
      const newState = prevState;
      newState.days[name].splice(index, 1);
      return { ...newState };
    });
	}

	return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Field
              as={TextField}
              value={workingHour.days[name][index].start ? workingHour.days[name][index].start : ''}
              type="time"
              onChange={(e) => onChangeTimeStart(e)}
              InputLabelProps={{
								shrink: true,
							}}
              disabled={!isWorkingHourEnabled}
              name={`days.[${name}][${index}].start`}
              fullWidth
              error={touched.days?.[name]?.[index]?.start && Boolean(errors.days?.[name]?.[index]?.start)}
              helperText={touched.days?.[name]?.[index]?.start && errors.days?.[name]?.[index]?.start}
            />
          </Grid>
          <Grid item>
            <Field
              as={TextField}
              value={workingHour.days[name][index].end ? workingHour.days[name][index].end : ''}
              type="time"
              onChange={(e) => onChangeTimeEnd(e)}
              InputLabelProps={{
								shrink: true,
							}}
              disabled={!isWorkingHourEnabled}
              name={`days.[${name}][${index}].end`}
              fullWidth
              error={touched.days?.[name]?.[index]?.end && Boolean(errors.days?.[name]?.[index]?.end)}
              helperText={touched.days?.[name]?.[index]?.end && errors.days?.[name]?.[index]?.end}>
            </Field>
          </Grid>
          {
          workingHour.days[name].length > 1 &&
          <Grid item>
            <IconButton
              onClick={handleRemoveHour}
              aria-label="Remover"
              color="primary"
              disabled={!isWorkingHourEnabled}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Grid>
          }
        </Grid>
      </Box>
    </>
  );
};

export default WorkingHourTime;
