import React from "react";
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip } from "@material-ui/core";

const ButtonHelp = () => {

  const handleClick = () => {
    window.open("https://www.integgri.com.br/central-de-ajuda-integgri/", "_blank");
  }
  return (
    <Tooltip
      arrow
      placement="top"
      title="Central de Ajuda"
    >
      <IconButton
        onClick={handleClick}
        aria-label="Ajuda"
        color="inherit"
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ButtonHelp;