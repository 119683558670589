import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import whatsBackground from "../../assets/wa-background.png";

const useStyles = makeStyles((theme) => ({
    containerText: {
        padding: "15px",
        flexDirection: "column",
        display: "flex",
    },
    templateText: {
        backgroundColor: "#ffffff",
        padding: "10px",
        borderRadius: "10px",
        position: "relative",
        "&::before": {
            content: '""',
            position: "absolute",
            top: -10,
            left: -0,
            borderStyle: "solid",
            borderWidth: "25px 0 0 25px",
            borderColor: "transparent transparent transparent #ffffff",
        },

    },
    blinkingDotOne: {
        animation: "$blinkOne 2.0s infinite",
    },
    blinkingDotTwo: {
        animation: "$blinkTwo 2.0s infinite",
    },
    blinkingDotThree: {
        animation: "$blinkThree 2.0s infinite",
    },
    "@keyframes blinkOne": {
        "0%": {
            opacity: 0,
        },
        "25%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0,
        },
    },
    "@keyframes blinkTwo": {
        "0%": {
            opacity: 0,
        },
        "50%": {
            opacity: 1,
        },
        "75%": {
            opacity: 0,
        },
    },
    "@keyframes blinkThree": {
        "0%": {
            opacity: 0,
        },
        "75%": {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
        },
    },
    messageWabaBoxStyle: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${whatsBackground})`,
        backgroundColor: "#efeae2",
        borderRadius: "5px",
        paddingTop: "10px",
        overflow: "auto",

        [theme.breakpoints.down("xl")]: {
            height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            height: "200px",
            marginTop: "10px",
        },
    },
}));

const MessagePreview = ({ message, headerMessage, footerMessage, placeholder, placeholderEllipsis }) => {
    const classes = useStyles();
    const [useText, setUseText] = useState("");
    const [useHeaderText, setUseHeaderText] = useState("");

    useEffect(() => {
        let simpleBodyMessage = message || placeholder;
        if (placeholderEllipsis && !message) {
            simpleBodyMessage += '<span class="' + classes.blinkingDotOne + '">.</span>';
            simpleBodyMessage += '<span class="' + classes.blinkingDotTwo + '">.</span>';
            simpleBodyMessage += '<span class="' + classes.blinkingDotThree + '">.</span>';
        }
        setUseText(simpleBodyMessage);
        setUseHeaderText(headerMessage)
    }, [message, placeholderEllipsis, headerMessage, placeholder, classes.blinkingDotOne, classes.blinkingDotTwo, classes.blinkingDotThree]);
    
    return (
        <div className={classes.messageWabaBoxStyle}>
            <Box className={classes.containerText}>
                <Grid item className={classes.templateText}>
                    <Typography variant="body1"
                        gutterBottom
                        style={{
                            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                            fontSize: "17px",
                            fontWeight: 'bold',
                        }}
                        dangerouslySetInnerHTML={{ __html: useHeaderText}}
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                        color = {message ? "" : "textSecondary"}
                        style={{
                            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}
                        dangerouslySetInnerHTML={{ __html: useText || placeholder }}
                    />
                    <Typography variant="body1"
                        gutterBottom
                        style={{ color: 'rgba(0, 0, 0, 0.45)', fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }}
                    >
                        {footerMessage}
                    </Typography>
                </Grid>
            </Box>
        </div>
    );
};

export default MessagePreview;
