import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/Auth/AuthContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	withStyles,
	InputAdornment,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import UploadAttachment from "../UploadAttachment/UploadAttachment";
import {
	getFacebookLoginStatus,
	initFacebookSdk,
	fbLogin,
} from "../../utils/FacebookSDK";

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.60)',
		boxShadow: theme.shadows[3],
		fontSize: 16,
	},
}))(Tooltip);

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	customTextarea: {
		marginBottom: "30px",
	},
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Nome muito curto!")
		.max(50, "Nome muito longo!")
		.required("Obrigatório"),
	ticketReopenPeriod: Yup.string().required("Obrigatório")
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		channel: "whatsapp",
		wabaId: "",
		wabaNumberId: "",
		greetingMessage: "",
		farewellMessage: "",
		phoneNumber: "",
		isDefault: false,
		ticketReopenPeriod: ""
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const { user } = useContext(AuthContext);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [attachmentName, setAttachmentName] = useState(null);
	const [attachmentUrl, setAttachmentUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		console.log("Started use effect");
		getFacebookLoginStatus().then((response) => {
			if (response == null) {
				console.log("No login status for the person");
			} else {
				console.log(response);
			}
		});
	}, []);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
				setAttachmentName(data?.attachmentName);
				setAttachmentUrl(data?.attachmentUrl);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (whatsApp) {
			setAttachmentName(whatsApp.attachmentName);
			setAttachmentUrl(whatsApp.attachmentUrl);
		}
	}, [])

	const handleFacebookLogin = () => {
		fbLogin().then((response) => {
			console.log(response);
			if (response.authResponse) {
				const accessToken = response.authResponse.accessToken;
				console.log(accessToken);
				//Use this token to call the debug_token API and get the shared WABA's ID
			} else {
				console.log('User cancelled login or did not fully authorize.');
			}
		});
	}

	const handleSaveWhatsApp = async values => {
		const whatsappData = { ...values, queueIds: selectedQueueIds, companyId: user.companyId, attachmentName, attachmentUrl };

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
			setAttachmentName(attachmentName);
			setAttachmentUrl(attachmentUrl);
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const deleteAttachment = async (e) => {
		const fileName = whatsApp.attachmentName;
		if (fileName) {
			try {
				setIsLoading(true);

				await api.delete(`/whatsapp-delete/`, {
					data: {
						name: fileName
					}
				});

				setIsLoading(false);
				setAttachmentName(null);
				setAttachmentUrl(null);

			} catch (err) {
				toastError(err);
				setIsLoading(false);
			}
		}
	}

	const uploadAttachment = async (selectedMedia) => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("file", selectedMedia);
		const file = formData.get("file");
		try {
			if (!file) {
				throw new Error("Arquivo não encontrado no formData");
			}
			const response = await api.post(`/whatsapp-upload`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			const { fileName, url } = response.data;
			setAttachmentName(fileName)
			setAttachmentUrl(url)

		} catch (err) {
			console.log("Resposta inválida do servidor:", err);
			toastError(err);
		}
		setIsLoading(false);

	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.number")}
										placeholder="5513912344321"
										name="phoneNumber"
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default")}
									/>
								</div>
								<div>
									<FormControl
										variant="outlined"
										style={{ width: "100%" }}
										margin="dense"
									>
										<InputLabel id="connection-type-input-label">
											Canal
										</InputLabel>
										<Field
											disabled={!!whatsAppId}
											as={Select}
											label="Canal"
											name="channel"
											labelId="connection-type-label"
											id="connection-type"
										>
											<MenuItem value="whatsapp">WhatsApp</MenuItem>
											<MenuItem value="waba">WhatsApp Business API</MenuItem>
										</Field>
									</FormControl>
									{/* {values.channel === "waba" &&
										<Button
											style={{width: "100%", marginBottom: 3, marginTop: 3}}
											color="primary"
											variant="contained"
											className={classes.btnWrapper}
											onClick={handleFacebookLogin}
										>
											Continuar com Facebook
										</Button>
									} */}
									{
										values.channel === "waba" &&
										<Field
											as={TextField}
											label="ID do WhatsApp Business"
											disabled={!!whatsAppId}
											autoFocus
											name="wabaId"
											variant="outlined"
											margin="dense"
											style={{width: "100%"}}
										/>
									}
									{
										values.channel === "waba" &&
										<Field
											as={TextField}
											label="ID do número do WhatsApp Business"
											disabled={!!whatsAppId}
											autoFocus
											name="wabaNumberId"
											variant="outlined"
											margin="dense"
											style={{width: "100%"}}
										/>
									}
									{
										values.channel === "waba" &&
										<Field
											as={TextField}
											label="Token do WhatsApp Business"
											disabled={!!whatsAppId}
											autoFocus
											name="wabaToken"
											variant="outlined"
											margin="dense"
											style={{width: "100%"}}
										/>
									}
									<Field
										name="greetingMessage"
										render={({ field, form }) => (
											<div style={{ position: "relative" }}>
												<TextField
													label={i18n.t("queueModal.form.greetingMessage")}
													multiline
													rows={4}
													fullWidth
													error={form.touched.greetingMessage && Boolean(form.errors.greetingMessage)}
													helperText={form.touched.greetingMessage && form.errors.greetingMessage}
													variant="outlined"
													margin="dense"
													{...field}
													InputProps={{
														classes: {
															input: classes.customTextarea,
														},
													}}
												/>
												<div style={{ position: 'absolute', bottom: 15, left: 6 }}>
													<UploadAttachment
														loading={isLoading}
														currentAttachmentName={attachmentName}
														currentAttachmentUrl={attachmentUrl}
														onUploadAttachment={uploadAttachment}
														onDeleteAttachment={deleteAttachment}
													/>
												</div>
											</div>
										)}
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										rows={5}
										fullWidth
										name="farewellMessage"
										error={
											touched.farewellMessage && Boolean(errors.farewellMessage)
										}
										helperText={
											touched.farewellMessage && errors.farewellMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
								<div style={{ display: "flex", marginTop: 5 }}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.ticketReopenPeriod")}
										name="ticketReopenPeriod"
										error={touched.ticketReopenPeriod && Boolean(errors.ticketReopenPeriod)}
										helperText={touched.ticketReopenPeriod && errors.ticketReopenPeriod}
										variant="outlined"
										margin="dense"
										InputProps={{
											endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
										}}
										className={classes.textField}
									/>
									<LightTooltip  title={i18n.t("whatsappModal.form.ticketReopenPeriodTooltip")}>
										<HelpOutlineIcon style={{ marginTop: 7, marginLeft: 3}} fontSize="small"/>
									</LightTooltip >
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
