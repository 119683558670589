import React, { useState, useEffect, useContext, useRef } from 'react';
import api from "../../services/api";
import { makeStyles } from '@material-ui/core/styles';
import toastError from "../../errors/toastError";
import { QuickAnswerContext } from '../../context/QuickAnswer/QuickAnswerContext';
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({

  messageQuickAnswersWrapper: {
    boxShadow: "0px -2px 5px #888888aa",
    width: "100%",
    maxHeight: "485px",
    position: "absolute",
    bottom: "40.5px",
    background: "#FFF",
    padding: "0px",
    border: "none",
    overflow: "hidden",
    overflowY: "scroll",
    color: "#212121",
    zIndex: 1,
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 90 90 6px rgba(90, 0, 0, 0.3)",
      backgroundColor: "#483D8B",
    },
    "& li": {
      listStyle: "none",
      "& a": {
        display: "flex",
        maxLength: 50,
        padding: "25px",
        alignItems: "center",
        overflow: "hidden",
      },
    },
  },
}));

const QuickAnswersBar = () => {
  const classes = useStyles();
  const [quickAnswers, setQuickAnswers] = useState([]);
  const [filteredQuickAnswers, setFilteredQuickAnswers] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const activeIndexRef = useRef(null);
  const [onMouseEnterEvent, setOnMouseEnterEvent] = useState(false);
  const { pressedKey, setPressedKey, setQuickAnswer, setQuickAnswerAttachment, setTypeBar, typeBar } = useContext(QuickAnswerContext);

  const handleQuickAnswersClick = async (value) => {
    setQuickAnswer(value);
    setQuickAnswerAttachment(value);
    setTypeBar(null)
  };

  useEffect(() => {                                       // requisição quickAnswers
    const fetchQuickAnswers = async () => {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { showAll: "true" },
        });
        setQuickAnswers(data.quickAnswers);
        setFilteredQuickAnswers(data.quickAnswers);
      } catch (err) {
        toastError(err);
      }
    };
    fetchQuickAnswers();
  }, [])

  useEffect(() => {                                     // eventos de teclado
    if (pressedKey === "ArrowDown") {
      setActiveIndex((prevIndex) => (prevIndex + 1) % filteredQuickAnswers.length)
      setPressedKey(null)
    } else if (pressedKey === "ArrowUp") {
      setActiveIndex((prevIndex) => prevIndex === 0 ? filteredQuickAnswers.length - 1 : prevIndex - 1)
      setPressedKey(null)
    } else if (pressedKey === "Enter") {
      const filtered = filteredQuickAnswers[activeIndex];
      if (filtered) {
        handleQuickAnswersClick(filtered);
        setTypeBar(null);
        setPressedKey(null);
      }
    }
  }, [pressedKey])

  useEffect(() => {                                   // filtro
    if (typeBar && quickAnswers.length > 0) {
      const withoutBarAnswersInput = typeBar.slice(1).toLowerCase();
      if (withoutBarAnswersInput !== "") {
        const filteredAnswers = quickAnswers.filter(quickAnswer => {
          const shortcutLowerCase = quickAnswer.shortcut.toLowerCase();
          return shortcutLowerCase.startsWith(withoutBarAnswersInput)
        })
        setFilteredQuickAnswers(filteredAnswers);
      } else {
        setFilteredQuickAnswers(quickAnswers);
      }
      setActiveIndex(0);
    }
  }, [typeBar]);

  useEffect(() => {                                  // scroll automático
    const scrollToActiveIndex = () => {
      if (activeIndexRef.current) {
        activeIndexRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    scrollToActiveIndex();
  }, [activeIndex])
  
  if (typeBar) {
    return (
      <ul className={classes.messageQuickAnswersWrapper}>
        {filteredQuickAnswers.map((value, index) => {
          const hoverStyle = {
            background: "rgba(0, 0, 0, 0.06)",
            cursor: "pointer",
          }
  
          return (
            <li
              key={index}
              onClick={(e) => handleQuickAnswersClick(value)}
            >
              <a 
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = hoverStyle.background;
                  e.target.style.cursor = hoverStyle.cursor;
                  setOnMouseEnterEvent(true);
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '';
                  e.target.style.cursor = 'auto';
                  setOnMouseEnterEvent(false);
                }}
                style={index === activeIndex && !onMouseEnterEvent ? { backgroundColor: hoverStyle.background } : {}}
                ref={index === activeIndex ? activeIndexRef : null}
              >
              <strong>{`${value.shortcut} `}</strong>
              &nbsp;- {value?.attachmentName &&
                  <AttachFileIcon />
                }{` ${value.message.length > 125 ? value.message.slice(0, 125) + '...' : value.message}`}
              </a>
            </li>
          );
        })}
      </ul>
    )
  }
  return null;
}

export default QuickAnswersBar;