import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Tooltip } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { TicketsActionsContext } from "../../context/TicketsActionsContext/TicketsActionsContext";
import TicketsBulkSelectOptionsMenu from "../TicketsBulkSelectOptionsMenu/TicketsBulkSelectOptionsMenu";

const useStyles = makeStyles(theme => ({
	selectBar: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: "#fff",
		padding: theme.spacing(1),
	},
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
	},
	selectedCount: {
		display: "flex",
		alignItems: "center",
		opacity: "50%",
	}
}));

const TicketsSelectBar = ({ dispatch, ticketsList, tabOpen }) => {
	const classes = useStyles();
	const { selectMode } = useContext(TicketsActionsContext);
	const [checkedAll, setCheckedAll] = useState(false);
	const [ticketsCount, setTicketsCount] = useState(0);

	useEffect(() => {
		const selectedTicketsCount = ticketsList.filter(item => item.selected).length;
		setTicketsCount(selectedTicketsCount);

	}, [ticketsList])

	useEffect(() => {
		if (!selectMode) {
			selectAll(false);
		}
	}, [selectMode]);

	const selectAll = (isSelect) => {
		setCheckedAll(isSelect);
		dispatch({
			type: "CHECK_ALL",
			payload: { selectAll: isSelect },
		});
	}

	const handleSelectAll = (event) => {
		selectAll(event.target.checked);
	}

	return (
		<>
			{
				selectMode &&
				(
					<div className={classes.selectBar}>
						<div>
							<Tooltip
								arrow
								placement="right"
								title={i18n.t("ticketsSelectMode.selectAll")}
							>
								<Checkbox
									style={{ marginLeft: 9 }}
									size="small"
									color="primary"
									onChange={handleSelectAll}
									checked={checkedAll}
								/>
							</Tooltip>
						</div>
						<div className={classes.selectedCount}>
							{ticketsCount} {i18n.t("ticketsSelectModeCount.selecteds")}
						</div>
						<div className={classes.actionButtons}>
							<TicketsBulkSelectOptionsMenu
								ticketsList={ticketsList}
								ticketsCount={ticketsCount}
								tabOpen={tabOpen}
							>
							</TicketsBulkSelectOptionsMenu>
						</div>
					</div>
				)
			}
		</>
	)
};

export default TicketsSelectBar;