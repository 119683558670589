import React, { useState, createContext } from "react";

const ReplyMessageContext = createContext();

const ReplyMessageProvider = ({ children }) => {
	const [replyingMessage, setReplyingMessage] = useState(null);
	const [replyingVcardMessage, setReplyingVcardMessage] = useState([
	]);

	return (
		<ReplyMessageContext.Provider
			value={{
				replyingMessage,
				setReplyingMessage,
				replyingVcardMessage,
				setReplyingVcardMessage
			}}
		>
			{children}
		</ReplyMessageContext.Provider>
	);
};

export { ReplyMessageContext, ReplyMessageProvider };
