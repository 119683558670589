import React, { useState, useRef } from "react";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

import { VolumeDown, VolumeUp } from "@material-ui/icons";
import { Grid, Slider, Tooltip } from "@material-ui/core";
import useSound from "use-sound";
import alertSound from "../../assets/sound2.mp3";

const LS_NAME = 'audioVolume';

const useStyles = makeStyles(theme => ({
	popoverPaper: {
		width: "100%",
		maxWidth: 300,
		[theme.breakpoints.down("sm")]: {
			maxWidth: 300,
		},
	},
	gridItem: {
		display: "flex",
		alignItems: "center"
	}
}));

const VolumePopOver = () => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	const anchorEl = useRef();
	const [play, { stop }] = useSound(alertSound, { volume: parseFloat(localStorage.getItem(LS_NAME) || "0.5") });
	const [volume, setVolume] = useState(parseFloat(localStorage.getItem(LS_NAME) || "0.5") * 100);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);
		localStorage.setItem(LS_NAME, newValue / 100);
  };

	const handleClickSlider = () => {
		stop();
		play({ volume: parseFloat(localStorage.getItem(LS_NAME) || "0.5") });
	}

	return (
		<>
			<Tooltip
				arrow
				placement="top"
				title="Volume"
    	>
				<IconButton
					onClick={handleClick}
					ref={anchorEl}
					aria-label="Ajustar volume de notificações"
					color="inherit"
				>
					<VolumeUp/>
				</IconButton>
			</Tooltip>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<Grid container spacing={2} style={{ width: "100%", margin: 0}}>
					<Grid item className={classes.gridItem}>
						<VolumeDown />
					</Grid>
					<Grid item xs className={classes.gridItem}>
						<Slider value={volume} onClick={handleClickSlider} onChange={handleChangeVolume} aria-labelledby="continuous-slider" />
					</Grid>
					<Grid item className={classes.gridItem}>
						<VolumeUp />
					</Grid>
				</Grid>
			</Popover>
		</>
	);
};

export default VolumePopOver;