import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { Box, Button, ListItem, Switch, Typography } from "@material-ui/core";

import WorkingHourTime from "../WorkingHourTime";
import { FieldArray } from "formik";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "flex-start",
    textDecoration: "none"
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	boxContainer: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
	}
}));

const WorkingHourDay = ({ touched, errors, name, workingHour, setWorkingHour, isWorkingHourEnabled }) => {
	const classes = useStyles();

	const title = {
		sunday: "Domingo",
		monday: "Segunda-Feira",
		tuesday: "Terça-Feira",
		wednesday: "Quarta-Feira",
		thursday: "Quinta-Feira",
		friday: "Sexta-Feira",
		saturday: "Sábado"
	}

	const [checked, setChecked] = useState(workingHour.days[name].length > 0);

  const handleToggle = (event) => {
		if (!event.target.checked) {
			setWorkingHour(prevState => {
				const newState = prevState;
				newState.days[name] = [];
				return { ...newState };
			});
		} else {
			handleAddHour();
		}
    setChecked(event.target.checked);
  };

	const handleAddHour = () => {
		setWorkingHour(prevState => {
      const newState = prevState;
      newState.days[name].push({ start: undefined, end: undefined });
      return { ...newState };
    });
	};

	return (
		<>
			<ListItem disableGutters={true} className={classes.root}>
				<Box className={classes.boxContainer}>
					<Typography variant="subtitle1">{title[name]}</Typography>
					<Box>
						<Switch
						color="primary"
							checked={checked}
							onChange={handleToggle}
							disabled={!isWorkingHourEnabled}
						/>
					</Box>
				</Box>
			</ListItem>
			<FieldArray name="workingHour.days[name]">
				{({ push, remove }) => (
					<>
						{workingHour.days[name].map((time, index) => (
							<WorkingHourTime
								touched={touched}
								errors={errors}
								key={index}
								workingHour={workingHour}
								setWorkingHour={setWorkingHour}
								name={name}
								index={index}
								isWorkingHourEnabled={isWorkingHourEnabled}
								remove={remove} />
						))}
					</>
				)}
			</FieldArray>
			{
				checked &&
				<Button 
					className={classes.button} 
					type="button"
					color="primary"
					disabled={!isWorkingHourEnabled}
					onClick={handleAddHour}>
					+ Adicionar horários
				</Button>
			}
		</>
  );
};

export default WorkingHourDay;
