import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const MASTER_ROUTES = ['/companies', '/alerts'];
const MARKETING_ROUTES = ['/campaigns', '/contact-lists'];

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const { isAuth, user, loading } = useContext(AuthContext);

  return (
    <RouterRoute
      {...rest}
      render={props => {
        // Exibe o indicador de carregamento se estiver carregando ou se o usuário não estiver definido
        if (loading || !user) {
          return <BackdropLoading />;
        }

        // Verifica se o usuário não está autenticado em rotas privadas
        if (!isAuth && isPrivate) {
          const partner = localStorage.getItem("partner");
          let pathname = "/login";
          if (partner) {
            pathname = `/${partner}${pathname}`;
          }
          return <Redirect to={{ pathname, state: { from: props.location } }} />;
        }

        // Verifica se o usuário está autenticado em rotas públicas
        if (isAuth && !isPrivate) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        // Verifica se a rota está nas MASTER_ROUTES e o usuário é do tipo 'customer'
        if (
          MASTER_ROUTES.includes(rest.path.toLowerCase()) &&
          user.type === 'customer'
        ) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        // Verifica se a rota está nas MARKETING_ROUTES e se o módulo de marketing está desabilitado
        if (
          MARKETING_ROUTES.includes(rest.path.toLowerCase()) &&
          !user.company?.companyPlan?.marketingModule
        ) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        // Renderiza o componente da rota
        return <Component {...props} />;
      }}
    />
  );
};

export default Route;
