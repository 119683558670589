import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import LabelIcon from '@material-ui/icons/Label';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import { makeStyles } from "@material-ui/core";
import TagModal from "../TagModal";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
	actions: {
		marginLeft: "auto",
		display: "flex"
	},
	actionButton: {
		opacity: 0.6,
		width: 18,
		height: 18
	},
	container: {
		width: "100%",
		backgroundColor: "#eee",
		display: "flex",
		alignItems: "center"
	},
	listItem: {
		padding: '0',
	},
	padding: {
		paddingTop: "30px",
		paddingBottom: "30px"
	},
	listBox: {
		padding: "0px 0px",
		margin: 0,
		overflow: "auto",
		listStyle: "none",
		maxHeight: "40vh"
	}

}));

export function TagsContainer({ contact, ticket, gray, setContactTags, contactSocketData, tagSocketData }) {
	const classes = useStyles();
	const [tags, setTags] = useState([]);
	const [selecteds, setSelecteds] = useState([]);
	const [selectedTag, setSelectedTag] = useState(null);
	const [tagModalOpen, setTagModalOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [deletingTag, setDeletingTag] = useState(null);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (contactSocketData?.action === "update") {
			if (contact.id === contactSocketData.contact?.id) {
				if (Array.isArray(contactSocketData.contact.tags)) {
					contactSocketData.contact.tags.forEach((item) => {
						delete item.ContactTag;
					});
					setSelecteds(contactSocketData.contact.tags);
				}
			}
		}
	}, [contactSocketData]);

	useEffect(() => {
		if (tagSocketData?.action === "delete") {
			setSelecteds(selecteds.filter(selectedTag => selectedTag.id !== +tagSocketData.tagId));
			setTags([...selecteds.filter(tag => tag.id !== +tagSocketData.tagId), { name: "Digite para adicionar uma nova etiqueta", isDefaultLabel: true }]);
		}
	}, [tagSocketData]);

	useEffect(() => {
		if (contact) {
			async function fetchData() {
				await loadTags();
				if (Array.isArray(contact.tags)) {
					contact.tags.forEach((item) => {
						delete item.ContactTag;
					});
					setSelecteds(contact.tags);
					if (typeof setContactTags === "function") {
						setContactTags(contact.tags)
					}
				}
			}
			fetchData();
		}
	}, [contact]);

	const handleDeleteTag = async (tagId) => {
		try {
			await api.delete(`/tags/${tagId}`, {
				data: {
					companyId: user.companyId,
					ticketId: ticket?.id,
					contactId: contact.id
				}
			});
			toast.success(i18n.t("tags.toasts.deleted"));
		} catch (err) {
			toastError(err);
		}
	};

	const handleEditTag = (tag) => {
		setSelectedTag(tag);
		setTagModalOpen(true);
	};

	const handleCloseTagModal = () => {
		setSelectedTag(null);
		setTagModalOpen(false);
	};

	const createTag = async (data) => {
		try {
			const { data: responseData } = await api.post(`/tags`, data);
			return responseData;
		} catch (err) {
			toastError(err);
		}
	}

	const loadTags = async () => {
		try {
			const { data } = await api.get(`/tags/list`, {
				params: { companyId: user.companyId },
			});
			setTags([...data, { name: "Digite para adicionar uma nova etiqueta", isDefaultLabel: true }]);
		} catch (err) {
			toastError(err);
		}
	}

	const syncTags = async (data) => {
		try {
			const { data: responseData } = await api.post(`/tags/sync`, data);
			return responseData;
		} catch (err) {
			toastError(err);
		}
	}

	const onChange = async (value, reason) => {
		let optionsChanged = []
		if (reason === 'create-option') {
			if (isArray(value)) {
				for (let item of value) {
					if (isString(item)) {
						const newTag = await createTag({ name: item, color: '#e0e0e0' })
						optionsChanged.push(newTag);
					} else {
						optionsChanged.push(item);
					}
				}
			}
			await loadTags();
		} else {
			optionsChanged = value;
		}
		optionsChanged = optionsChanged.filter(obj => !obj.isDefaultLabel);
		if (optionsChanged.length > 0 || value.length === 0) {
			setSelecteds(optionsChanged);
			if (typeof setContactTags === "function") {
				setContactTags(optionsChanged);
			} else {
				await syncTags({ contactId: contact.id, tags: optionsChanged, ticketId: ticket.id });
			}
		}
	}

	return (
		<Paper elevation={0} className={gray ? classes.container : ''}>
			<ConfirmationModal
				title={
					`${i18n.t("tags.confirmationModal.deleteTitle")}`
				}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={() =>
					handleDeleteTag(deletingTag.id)
				}
			>
				{
					`${i18n.t("tags.confirmationModal.deleteMessage")}`
				}
			</ConfirmationModal>
			<TagModal
				open={tagModalOpen}
				onClose={handleCloseTagModal}
				reload={loadTags}
				aria-labelledby="form-dialog-title"
				setSelecteds={setSelecteds}
				selecteds={selecteds}
				selectedTag={selectedTag}
				ticket={ticket}
				contact={contact}
			/>
			<Autocomplete
				multiple
				size="small"
				options={tags}
				value={selecteds}
				classes={{ listbox: classes.listBox }}
				style={{ width: "100%"}}
				freeSolo
				onChange={(e, v, r) => {
					onChange(v, r);
				}}
				getOptionLabel={(option) => option.name}
				getOptionSelected={(option, value) => value.id === option.id}
				renderOption={(option, { selected }) => {
					return option.color ?
						<React.Fragment>
							<LabelIcon
								style={{ color: option.color, marginRight: 5 }}
							/>
							<span className={classes.color} style={{ backgroundColor: '#fff' }} />
							<div className={classes.text}>
								{option.name}
							</div>
							<div className={classes.actions}>
								<EditSharpIcon
									onClick={(e) => { e.stopPropagation(); handleEditTag(option); }}
									className={classes.actionButton}
								/>
								<DeleteOutlineSharpIcon
									onClick={(e) => {
										e.stopPropagation();
										setConfirmModalOpen(true);
										setDeletingTag(option);
									}}
									className={classes.actionButton} />
							</div>
						</React.Fragment>
						: <div onClick={(e) => { e.stopPropagation(); }}>
							{option.name}
						</div>
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							style={{ backgroundColor: option.color || '#eee' }}
							label={option.name}
							{...getTagProps({ index })}
							size="small"
						/>
					))
				}
				renderInput={(params) => {
					return gray ?
						<TextField
							size="small"
							fullWidth
							{...params}
							placeholder={i18n.t("tags.tagsContainer.placeholder")}
							InputProps={{ ...params.InputProps, disableUnderline: true }} />
						:
						<TextField
							variant="outlined"
							margin="dense"
							size="small"
							fullWidth
							{...params}
							placeholder={i18n.t("tags.tagsContainer.placeholder")}
							InputProps={{ ...params.InputProps, disableunderline: true }} />
				}}
			/>
		</Paper>
	)
}