import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, CircularProgress, Divider } from "@material-ui/core";
import CtwaContext from "../CtwaContext";
import LocationPreview from "../LocationPreview";
import VcardPreview from "../VcardPreview";
import ModalImageCors from "../ModalImageCors";
import Audio from "../Audio";
import PDFMessage from "../PDFMessage";
import { GetApp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  messageMedia: {
    objectFit: "contain",
    width: "100%",
    height: "200px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "inherit",
    padding: 10
  },
  circleLoading: {
    color: "#483D8B",
    opacity: "100%",
    position: "center",
    marginTop: 12,
  },  
}));

const MessageMedia = ({ message, handleOpenPdfModal }) => {
	const classes = useStyles();

  const checkMessageMedia = () => {
    if (message.ctwaContext) {
      const ctwaContext = JSON.parse(message.ctwaContext);
      return <CtwaContext url={ctwaContext.sourceUrl} title={ctwaContext.title} thumbnailUrl={ctwaContext.thumbnailUrl}/>
    } else if(message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]		
      let linkLocation = locationParts[1]
      
      let descriptionLocation = null
      
      if(locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]
      
      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    } else if (message.mediaType === "vcard") {
      //console.log("vcard")
      //console.log(message)
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} />
    } else {
      return checkS3Medias(message);
    }
  };

  const checkS3Medias = () => {
    const { s3Url, mediaUrl, mediaType } = message;
    if (!s3Url) {
      return (
        <div style={{ textAlign: "center"}}>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )
    } else if (mediaType === "image") {
      return <ModalImageCors imageUrl={s3Url}/>;
    } else if (mediaType === "audio") {
      return <Audio url={s3Url}/>
    } else if (mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={s3Url}
          controls
        />
      );
    } else if (mediaType === 'application' && mediaUrl?.toLowerCase().endsWith(".pdf") && s3Url) {
      return <PDFMessage
        mediaUrl={s3Url} 
        handleOpenPdfModal={handleOpenPdfModal}/>
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              style={{ width: "100%" }}
              color="primary"
              variant="outlined"
              target="_blank"
              href={s3Url}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  }

	return (
		<>
		  {checkMessageMedia()}
		</>
	);
};

export default MessageMedia;
