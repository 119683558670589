import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import UploadAttachment from "../UploadAttachment/UploadAttachment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
  customTextarea: {
		marginBottom: "30px",
	},
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Muito curto!")
    .max(15, "Muito longo!")
    .required("Obrigatório")
});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    shortcut: "",
    message: "",
  };

  const [quickAnswer, setQuickAnswer] = useState(initialState);
  const [attachmentName, setAttachmentName] = useState(null);
	const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          setQuickAnswer(data);
          setAttachmentName(data?.attachmentName);
				  setAttachmentUrl(data?.attachmentUrl);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswer();
    return () => {
			setAttachmentName(null);
			setAttachmentUrl(null);
		};
  }, [quickAnswerId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setQuickAnswer(initialState);
  };

  const handleSaveQuickAnswer = async (values) => {
    try {
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, { ...values, attachmentName, attachmentUrl });
        handleClose();
      } else {
        const { data } = await api.post("/quickAnswers", { ...values, attachmentName, attachmentUrl });
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("quickAnswersModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const deleteAttachment = async (e) => {
		const fileName = quickAnswer.attachmentName || attachmentName;
		if (fileName) {
			try {
				setIsLoading(true);

				await api.post(`/quickAnswers/delete-attachment`, {
					name: fileName,
          quickAnswerId
				});

				setIsLoading(false);
				setAttachmentName(null);
				setAttachmentUrl(null);

			} catch (err) {
				toastError(err);
				setIsLoading(false);
			}
		}
	}

	const uploadAttachment = async (selectedMedia) => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("file", selectedMedia);
    if (quickAnswerId) {
      formData.append("quickAnswerId", quickAnswerId);
    }

		const file = formData.get("file");
		try {
			if (!file) {
				throw new Error("Arquivo não encontrado no formData");
			}
			const response = await api.post(`/quickAnswers/upload-attachment`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			const { fileName, url } = response.data;
			setAttachmentName(fileName)
			setAttachmentUrl(url)

		} catch (err) {
			console.log("Resposta inválida do servidor:", err);
			toastError(err);
		}
		setIsLoading(false);

	}

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t("quickAnswersModal.title.edit")}`
            : `${i18n.t("quickAnswersModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
										name="message"
										render={({ field, form }) => (
											<div style={{ position: "relative" }}>
												<TextField
													label={i18n.t("quickAnswersModal.form.message")}
													multiline
													minRows={4}
													fullWidth
													error={touched.message && Boolean(errors.message)}
													helperText={touched.message && errors.message}
													variant="outlined"
													margin="dense"
													{...field}
													InputProps={{
														classes: {
															input: classes.customTextarea,
														},
													}}
												/>
												<div style={{ position: 'absolute', bottom: 15, left: 6 }}>
													<UploadAttachment
														loading={isLoading}
														currentAttachmentName={attachmentName}
														currentAttachmentUrl={attachmentUrl}
														onUploadAttachment={uploadAttachment}
														onDeleteAttachment={deleteAttachment}
													/>
												</div>
											</div>
										)}
									/>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QuickAnswersModal;
