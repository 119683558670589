import React, { useContext, useEffect, useReducer, useState } from "react";

import openSocket from "../../services/socket-io";

import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import ScheduleModal from "../ScheduleModal";
import { formatDateTime } from "../../helper/helper";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    return [...schedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((u) => u.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;
    const scheduleIndex = state.findIndex((q) => q.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const ScheduleTable = ({shiftId, month, year}) => {
  const classes = useStyles();

  const [schedules, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const loadSchedules = async () => {
    const { data } = await api.get(`/schedule/list`, {
      params: { 
        companyId: user.companyId,
        shiftId: shiftId,
        month: month.value,
        year: year.value
      }
    });
    dispatch({ type: "LOAD_SCHEDULES", payload: data });
  }

  useEffect(() => {
    if (!shiftId)
      return;
    (async () => {
      setLoading(true);
      try {
        loadSchedules();
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, [shiftId, month, year]);

  useEffect(() => {
    const socket = openSocket();

    socket.on(`shiftSchedule${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.shift });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE", payload: data.shiftId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedSchedule(null);
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedSchedule(null);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedule/${scheduleId}`, {
        data: { 
          companyId: user.companyId 
        } 
      });
      toast.success(i18n.t("Horário excluído com sucesso!"));
      loadSchedules();
    } catch (err) {
      toastError(err);
    }
    setSelectedSchedule(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedSchedule &&
          `${i18n.t("sheduleConsultModal.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteSchedule(selectedSchedule.id)}
      >
        {i18n.t("sheduleConsultModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ScheduleModal
        modalOpen={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        shiftId={shiftId}
				companyId={user.companyId}
        scheduleId={selectedSchedule?.id}
      />
      <Paper className={classes.mainPaper} variant="outlined">
        <Table margin="dense" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("sheduleConsultModal.table.startDate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("sheduleConsultModal.table.endDate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("sheduleConsultModal.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {schedules.map((schedule) => (
                <TableRow key={schedule.id}>
                  <TableCell align="center">{formatDateTime(schedule.startDateTime)}</TableCell>
                  <TableCell align="center">{formatDateTime(schedule.endDateTime)}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditSchedule(schedule)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedSchedule(schedule);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ScheduleTable;
