import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	List,
	ListSubheader,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import WorkingHourDay from "../WorkingHourDay";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	dialogContent: {
		maxHeight: "calc(100vh - 220px)",
		...theme.scrollbarStyles,
	},
	paper: {
		width: "100%"
	},
	listItems: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const timeComparisonTest = function(startValue, endValue) {
  if (!startValue || !endValue) return true;
  
  const [startHour, startMinute] = startValue.split(':').map(Number);
  const [endHour, endMinute] = endValue.split(':').map(Number);
  
  const startTotalMinutes = startHour * 60 + startMinute;
  const endTotalMinutes = endHour * 60 + endMinute;
  
  return endTotalMinutes > startTotalMinutes;
}

const daySchema = Yup.array().of(
  Yup.object().shape({
    start: Yup.string()
      .required('Campo obrigatório')
      .test(
        'is-time-valid',
        'Deve ser menor que o término',
        function (value) {
          const { end } = this.parent;
          return timeComparisonTest(value, end);
        }
      ),
    end: Yup.string()
      .required('Campo obrigatório')
      .test(
        'is-time-valid',
        'Deve ser maior que o início',
        function (value) {
          const { start } = this.parent;
          return timeComparisonTest(start, value);
        }
      ),
  })
);

const WorkingHourSchema = Yup.object().shape({
  awayMessage: Yup.string().required('Campo obrigatório'),
  days: Yup.object().shape({
		sunday: daySchema,
		monday: daySchema,
		tuesday: daySchema,
		wednesday: daySchema,
		thursday: daySchema,
		friday: daySchema,
		saturday: daySchema
	}),
});

const WorkingHourModal = ({ open, onClose }) => {
	const classes = useStyles();
	const initialState = {
		isEnabled: false,
		awayMessage: "",
		days: {
			sunday: [],
			monday: [],
			tuesday: [],
			wednesday: [],
			thursday: [],
			friday: [],
			saturday: []
		}
	};

	const [workingHour, setWorkingHour] = useState(initialState);

	useEffect(() => {
		const fetchWorkingHour = async () => {
			try {
				const { data } = await api.get(`/companies-working-hours`);
				setWorkingHour({ ...data, days: { ...initialState.days, ...data.days }});
			} catch (err) {
				toastError(err);
			}
		};
		fetchWorkingHour();
	}, [open]);

	const handleClose = () => {
		onClose();
		setWorkingHour(initialState);
	};

	const handleChangeIsEnabled = (e) => {
		setWorkingHour(prevState => {
			const newState = prevState;
			newState.isEnabled = e.target.checked;
			return { ...newState };
		});
	}

	const handleSave = async (values) => {
		try {
			const { days } = values;
			const filteredDays = Object.keys(days).reduce((acc, day) => {
				if (days[day].length > 0) {
					acc[day] = days[day];
				}
				return acc;
			}, {});
			await api.put(`companies-working-hours`, { isEnabled: values.isEnabled, awayMessage: values.awayMessage, days: filteredDays } );
			handleClose();
			toast.success(i18n.t("workingHourModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={() => {}}
				scroll="paper"
				classes={{ paper: classes.paper }}
			>
				<DialogTitle>
					{i18n.t("workingHourModal.title")}
				</DialogTitle>
				<Formik
					initialValues={workingHour}
					enableReinitialize={true}
					validationSchema={WorkingHourSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSave(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent className={classes.dialogContent} dividers>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isEnabled"
												onChange={handleChangeIsEnabled}
												checked={values.isEnabled}
											/>
										}
										label={i18n.t("workingHourModal.form.isEnabled")}
									/>
									<Field
										as={TextField}
										label={i18n.t("workingHourModal.form.awayMessage")}
										type="text"
										multiline
										minRows={5}
										fullWidth
										disabled={!workingHour.isEnabled}
										name="awayMessage"
										error={
											touched.awayMessage && Boolean(errors.awayMessage)
										}
										helperText={
											touched.awayMessage && errors.awayMessage
										}
										variant="outlined"
										margin="dense"
									/>
									<List subheader={<ListSubheader disableGutters={true}>Configure o horário de funcionamento da empresa</ListSubheader>}>
										{ workingHour.days && Object.keys(workingHour.days).map((day) => (
											<WorkingHourDay
												touched={touched}
												errors={errors}
												isWorkingHourEnabled={workingHour.isEnabled}
												key={day} 
												name={day} 
												workingHour={workingHour} 
												setWorkingHour={setWorkingHour}/>
										))}
									</List>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("workingHourModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{i18n.t("workingHourModal.buttons.save")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default WorkingHourModal;
