import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../ConfirmationModal";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsActionsContext } from "../../context/TicketsActionsContext/TicketsActionsContext";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import TransferTicketModal from "../TransferTicketModal";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
	},
}));

const TicketsBulkSelectOptionsMenu = ({ ticketsList, ticketsCount, tabOpen }) => {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const { setSelectMode, ticketOpened } = useContext(TicketsActionsContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [resolveTickets, setResolveTickets] = useState(false);
	const [acceptTicketModalOpen, setAcceptTicketModalOpen] = useState(false);
	const [checked, setChecked] = useState(false);

	const handleOpenOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
	};

	const handleTransferSelectedTickets = () => {
		if (ticketsCount > 0) {
			setTransferTicketModalOpen(true);
		} else {
			toast.error(i18n.t("ticketsBulkSelectOptionsMenu.toasts.notItemSelected"));
		}
	}

	const handleResolveSelectedTickets = () => {
		if (ticketsCount > 0) {
			setResolveTickets(true);
		} else {
			toast.error(i18n.t("ticketsBulkSelectOptionsMenu.toasts.notItemSelected"));
		}
	}

	const handleCloseTransferTicketModal = () => {
		setSelectMode(false);
		setTransferTicketModalOpen(false);
	};

	const handleOpenAcceptTicketsModal = () => {
		if (ticketsCount > 0) {
			setAcceptTicketModalOpen(true);
		} else {
			toast.error(i18n.t("ticketsBulkSelectOptionsMenu.toasts.notItemSelected"));
		}
	}

	const handleAcceptTickets = async () => {
		const ticketsToAccept = ticketsList.filter(ticket => ticket.selected);
		const data = {
			status: 'open',
			oldStatus: 'pending'
		}
		if (ticketsToAccept) {
			data.ticketIdsToUpdate = ticketsToAccept.map(ticket => ticket.id);
		}
		await api.put(`/tickets-bulkupdate`, data);
		setSelectMode(false);
	}

	const handleConfirmModalZeroItens = () => {
		setAnchorEl(null);
		setConfirmationOpen(false);
	}

	const handleDeleteTickets = async () => {
		try {
			const ticketsToDelete = ticketsList.filter(ticket => ticket.selected);
			if (ticketsToDelete) {
				const ticketIdsToDelete = ticketsToDelete.map(ticket => ticket.id);
				const status = ticketsToDelete?.length > 0 ? ticketsToDelete[0].status : null;
				await api.delete(`/tickets-bulkdelete`, { data: { ticketIdsToDelete, status } });
				setSelectMode(false);
			}
			if (!ticketOpened) {
				if (ticketsCount > 1) {
					toast.success(i18n.t("ticketsBulkSelectOptionsMenu.toasts.multiDeleted"))
				} else { toast.success(i18n.t("ticketsBulkSelectOptionsMenu.toasts.singleDeleted")) }
			}
		} catch (err) {
			toastError(err);
		}
	};

	const handleResolveTickets = async () => {
		try {
			const ticketsToResolve = ticketsList.filter(ticket => ticket.selected);
			const data = {
				sendOffFarewellMessage: checked
			}
			if (ticketsToResolve) {
				data.ticketIdsToResolve = ticketsToResolve.map(ticket => ticket.id);
				await api.put(`/tickets-bulkresolve`, data);
				history.push("/tickets");
				setSelectMode(false);
			}

			if (ticketsCount > 1) {
				toast.success(i18n.t("ticketsBulkSelectOptionsMenu.toasts.multiResolve"))
			} else { toast.success(i18n.t("ticketsBulkSelectOptionsMenu.toasts.singleResolve")) }
		} catch (err) {
			toastError(err);
		}
	};

	const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
	};

	return (
		<div className={classes.actionButtons}>
			<IconButton onClick={handleOpenOptionsMenu}>
				<MoreVert />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseOptionsMenu}
			>
				{user.profile.toUpperCase() === "ADMIN" && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("ticketsBulkSelectOptionsMenu.delete")}
					</MenuItem>
				)}
				{tabOpen === "open" ? [
					<MenuItem key="transfer" onClick={handleTransferSelectedTickets}>
						{i18n.t("ticketsBulkSelectOptionsMenu.transfer")}
					</MenuItem>,

					<MenuItem key="resolve" onClick={handleResolveSelectedTickets}>
						{i18n.t("ticketsBulkSelectOptionsMenu.resolve")}
					</MenuItem>
				] : tabOpen === "pending" && [
					<MenuItem onClick={handleOpenAcceptTicketsModal}>
						{i18n.t("ticketsBulkSelectOptionsMenu.accept")}
					</MenuItem>,

					<MenuItem key="resolve" onClick={handleResolveSelectedTickets}>
						{i18n.t("ticketsBulkSelectOptionsMenu.resolve")}
					</MenuItem>
				]}
			</Menu>
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketsList={ticketsList.filter(ticket => ticket.selected)}
			/>
			<ConfirmationModal
				iconType="warning"
				title={(ticketsCount > 1 ? `${i18n.t("ticketsBulkSelectOptionsMenu.acceptMultipleItensConfirmationModal.title")}`
					: `${i18n.t("ticketsBulkSelectOptionsMenu.acceptOneItemConfirmationModal.title")}`)}
				open={acceptTicketModalOpen}
				onClose={() => setAcceptTicketModalOpen(false)}
				onConfirm={handleAcceptTickets}
				confirmTitle={i18n.t("ticketsBulkSelectOptionsMenu.acceptMultipleItensConfirmationModal.buttons.acceptAll")}
			>
				{(ticketsCount > 1 ? i18n.t("ticketsBulkSelectOptionsMenu.acceptMultipleItensConfirmationModal.message") : i18n.t("ticketsBulkSelectOptionsMenu.acceptOneItemConfirmationModal.message"))}
			</ConfirmationModal>
			{ticketsCount === 0 ? (
				<ConfirmationModal
					title={i18n.t("ticketsBulkSelectOptionsMenu.zeroItensConfirmationModal.title")}
					open={confirmationOpen}
					onClose={() => setConfirmationOpen(false)}
					onConfirm={handleConfirmModalZeroItens}
					confirmTitle="Tentar novamente"
				>
					{i18n.t("ticketsBulkSelectOptionsMenu.zeroItensConfirmationModal.message")}
				</ConfirmationModal>
			) : (
				<ConfirmationModal
					iconType="warning"
					title={(ticketsCount > 1 ?
						`${i18n.t("ticketsBulkSelectOptionsMenu.multiItensConfirmationModal.title")}?`
						: `${i18n.t("ticketsBulkSelectOptionsMenu.oneItemConfirmationModal.title")}?`)}
					open={confirmationOpen}
					onClose={setConfirmationOpen}
					onConfirm={handleDeleteTickets}
					confirmTitle="Deletar mesmo assim"
				>
					{(ticketsCount > 1 ? i18n.t("ticketsBulkSelectOptionsMenu.multiItensConfirmationModal.message") : i18n.t("ticketsBulkSelectOptionsMenu.oneItemConfirmationModal.message"))}
				</ConfirmationModal>
			)}
			<ConfirmationModal
				iconType="warning"
				title={(ticketsCount > 1 ?
					`${i18n.t("ticketsBulkSelectOptionsMenu.resolveMultiItensConfirmationModal.title")}`
					: `${i18n.t("ticketsBulkSelectOptionsMenu.oneResolveItemConfirmationModal.title")}`)}
				open={resolveTickets}
				onClose={setResolveTickets}
				onConfirm={handleResolveTickets}
				confirmTitle={i18n.t("ticketsBulkSelectOptionsMenu.acceptMultipleItensConfirmationModal.buttons.acceptAll")}
			>
				<Typography component="span" display="block">
					{(ticketsCount > 1 ? i18n.t("ticketsBulkSelectOptionsMenu.resolveMultiItensConfirmationModal.message") : i18n.t("ticketsBulkSelectOptionsMenu.oneResolveItemConfirmationModal.message"))}
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleCheckboxChange}
							name="confirmationCheckbox"
							color="primary"
						/>
					}
					label={i18n.t("ticketsBulkSelectOptionsMenu.farewellMesage")}
				/>

			</ConfirmationModal>
		</div>
	);
};

export default TicketsBulkSelectOptionsMenu;
