import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "../../services/socket-io";
import clsx from "clsx";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { DragFileContext } from "../../context/DragFileContext/DragFileContext";
import Alert from '@material-ui/lab/Alert';

import { green } from "@material-ui/core/colors";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import { AuthContext } from "../../context/Auth/AuthContext";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import PDFModal from "../PDFModal";
import PDFMessage from "../PDFMessage";
import ButtonScrollDown from "../ButtonScrollDown";
import MessageMedia from "../MessageMedia";
import InfiniteScroll from "react-infinite-scroll-component";
import DragFile from "../DragFile";

const messageContainerRight = {
  marginLeft: 20,
  marginTop: 2,
  display: "block",
  position: "relative",
  "&:hover #messageActionsButton": {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#d9fdd3",
  },
  '@media (min-width: 600px)': {
    marginRight: 30,
  },

  whiteSpace: "pre-wrap",
  backgroundColor: "#d9fdd3",
  color: "#303030",
  alignSelf: "flex-end",
  borderTopLeftRadius: 7.5,
  borderTopRightRadius: 7.5,
  borderBottomLeftRadius: 7.5,
  borderBottomRightRadius: 7.5,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 5,
  paddingBottom: 5,
  boxShadow: "0 1px 0.5px rgb(11 20 26 / 13%)",
}

const messageContainerLeft = {
  marginRight: 20,
  marginTop: 2,
  display: "block",
  position: "relative",
  "&:hover #messageActionsButton": {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#FFFFFF",
  },

  whiteSpace: "pre-wrap",
  backgroundColor: "#ffffff",
  color: "#303030",
  alignSelf: "flex-start",
  borderTopLeftRadius: 7.5,
  borderTopRightRadius: 7.5,
  borderBottomLeftRadius: 7.5,
  borderBottomRightRadius: 7.5,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 5,
  paddingBottom: 5,
  boxShadow: "0 1px 0.5px rgb(11 20 26 / 13%)",
}

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messagesList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
  },

  infiniteScrollContainer: {
    backgroundImage: `url(${whatsBackground})`,
    backgroundColor: "#efeae2",
    backgroundSize: "auto",
    height: "100%",
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    scrollBehavior: "smooth"
  },

  circleLoading: {
    color: "#483D8B",
    position: "absolute",
    opacity: "100%",
    top: 200,
    left: "50%",
    marginTop: 12,
  },
  messageLeft: {
    ...messageContainerLeft,
    height: "auto",
    minWidth: 100,
    maxWidth: 600,
  },
  messageLeftMedia: {
    ...messageContainerLeft,
    minWidth: 310,
    width: "auto",
    maxWidth: 600,
    height: "auto"
  },
  messageLeftMediaPdf: {
    ...messageContainerLeft,
    width: 350,
  },
  messageLeftMediaAudio: {
    ...messageContainerLeft,
    height: "auto"
  },
  quotedContainerLeft: {
    margin: "-3px -35px 6px -6px",
    overflow: "hidden",
    borderRadius: "7.5px",
    backgroundColor: "#F5F5F5",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    width: "auto",
    maxWidth: 600,
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "10px",
    backgroundColor: "#6959CD",
  },

  messageRight: {
    ...messageContainerRight,
    height: "auto",
    minWidth: 100,
    maxWidth: 600
  },

  messageRightMedia: {
    ...messageContainerRight,
    width: "auto",
    minWidth: 310,
    maxWidth: 600,
    height: "auto"
  },
  messageRightMediaPdf: {
    ...messageContainerRight,
    width: 350,
  },
  messageRightMediaAudio: {
    ...messageContainerRight,
    height: "auto"
  },
  quotedContainerRight: {
    margin: "-3px -55px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 600,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "10px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 35px 6px 6px",
    wordBreak: "break-all"
  },

  textContentItemRight: {
    overflowWrap: "break-word",
    padding: "3px 55px 6px 6px",
  },

  textContentItemLeft: {
    display: "flex",
    alignItems: "center"
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 55px 6px 6px",
  },

  messageMedia: {
    objectFit: "contain",
    width: "100%",
    height: "200px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: "#53bdeb",
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "inherit",
    padding: 10
  },
  ticketDivider: {
    marginTop: 16,
    marginBottom: 16
  },
  forwardedHeader: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic",
    fontSize: "13px",
    color: "#8696a0",
    marginBottom: "4px"
  },
  ciphertextWrapper: {
    display: "flex",
    alignItems: "flex-end",
    fontStyle: "italic"
  },
  dragFileWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', 
    width: '100%',
    
  },
  dragFileZone: {
    width: '90%', 
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex].ack = messageToUpdate.ack;
      state[messageIndex].isDeleted = messageToUpdate.isDeleted;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup, spy, appMessageData }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const [hasMore, setHasMore] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(false);
  const [showButtonDown, setShowButtonDown] = useState(false);
  const [newMessageCount, setNewMessageCount] = useState(0);
  const lastMessageRef = useRef();
  const currentTicketId = useRef(ticketId);

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const showButtonDownRef = useRef(showButtonDown);
  const pageNumberRef = useRef(1);
  const [isDragging, setIsDragging] = useState(false);
  const timeoutRef = useRef(null);
  const { setDragFile } = useContext(DragFileContext) || {};

  useEffect(() => {
    dispatch({ type: "RESET" });
    pageNumberRef.current = 1;
    currentTicketId.current = ticketId;
    fetchMessages();
  }, [ticketId]);

  const fetchMessages = async () => {
    try {
      const pgNumber = pageNumberRef.current;
      const { data } = await api.get("/messages/" + ticketId, {
        params: { pageNumber: pgNumber, companyId: user.companyId, canSeeHistory: user.canSeeHistory, spy }
      });

      if (currentTicketId.current === ticketId) {
        pageNumberRef.current = pgNumber + 1;
        dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
        setHasMore(data.hasMore);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleAppMessageData = async (appMessageData) => {
    if (appMessageData.action === "create") {
      await Promise.resolve(dispatch({ type: "ADD_MESSAGE", payload: appMessageData.message }));

      if (showButtonDownRef.current && !appMessageData.message.fromMe) {
        setNewMessageCount(count => count + 1);
      }

      if (!showButtonDownRef.current || appMessageData.message.fromMe) {
        scrollToBottom();
      }
    }

    if (appMessageData.action === "update") {
      dispatch({ type: "UPDATE_MESSAGE", payload: appMessageData.message });
    }
  }

  useEffect(() => {
    if (spy) {
      return;
    }
    handleAppMessageData(appMessageData);
  }, [appMessageData]);

  useEffect(() => {
    if (!spy) {
      return;
    }
    const socket = openSocket();
    socket.on("connect", () => {
      setTimeout(() => {
        socket.emit(`${user.companyId}:joinChatBox`, ticketId);
      }, 1000);
    });

    socket.on(`appMessage${user.companyId}`, (data) => {
      handleAppMessageData(data);
    });

    return () => {
      showButtonDownRef.current = null;
      socket.disconnect();
    };

  }, []);

  useEffect(() => {
    showButtonDownRef.current = showButtonDown;
  }, [showButtonDown]);

  const handleOpenPdfModal = (pdfFile) => {
    setSelectedPdf(pdfFile);
    setPdfModalOpen(true);
  };

  const handleClosePdfModal = () => {
    setPdfModalOpen(false);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop >= 0) {
      setShowButtonDown(false);
      setNewMessageCount(0);
    } else {
      setShowButtonDown(true);
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const renderCiphertext = (message) => {
    return (
      <Box className={classes.ciphertextWrapper}>
        <AccessTime style={{ color: "#b5adadf5", marginRight: 5}} />
        {message.body}
        <Link style={{marginLeft: 5}} target="_blank" href="https://faq.whatsapp.com/835452491239734/">Saiba mais</Link>
      </Box>
    )
  }

  const handleMessageText = (message) => {
    const { mediaUrl, body, mediaType } = message;
    if (mediaType === 'image' || mediaType === 'audio' || mediaType === 'video') {
      return body ? body : '';
    }
    if (body) {
      return body;
    }
    if (mediaUrl) {
      return mediaUrl;
    }
    return '';
  }

  const handleMessageTemplateHeaderText = (message) => {
    const { body } = message;

    const bodyObject = JSON.parse(body);
    let headerText;

    bodyObject.components.forEach(componentArray => {
      componentArray.forEach(component => {
        if (component.type === 'HEADER') {
          headerText = component.text;
        }
      });
    });
    return headerText;
  }

  const handleMessageTemplateBodyText = (message) => {
    const { body } = message;

    const bodyObject = JSON.parse(body);
    let bodyText;

    bodyObject.components.forEach(componentArray => {
      componentArray.forEach(component => {
        if (component.type === 'BODY') {
          bodyText = component.text;
        }
      });
    });
    return bodyText
  }

  const handleMessageTemplateFooterText = (message) => {
    const { body } = message;

    const bodyObject = JSON.parse(body);
    let FooterText;

    bodyObject.components.forEach(componentArray => {
      componentArray.forEach(component => {
        if (component.type === 'FOOTER') {
          FooterText = component.text;
        }
      });
    });
    return FooterText
  }

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const getDivider = (message) => {
    const { ticket, id } = message;
    const { closedAt, user } = ticket;
    if (closedAt) {
      return (
        <>
        <Alert key={`ticket-${id}`} className={classes.ticketDivider} style={{ justifyContent: "center", textAlign: "center" }} icon={false} severity="success">
          Atendimento finalizado por {user.name} - {format(parseISO(closedAt), "dd/MM/yyyy HH:mm:ss")}
        </Alert>
        </>
      );
    }
  }

  const renderTicketDivider = (index) => {
    if (index > 0 && index <= messagesList.length - 1) {
      let ticketId = messagesList[index].ticketId;
      let previousMessage = messagesList[index - 1];
      const previousMessageTicket = previousMessage.ticket;
      if (ticketId !== previousMessage.ticketId &&
        user.canSeeHistory &&
        previousMessageTicket?.user &&
        previousMessageTicket?.status === 'closed') {
        return getDivider(previousMessage);
      }
    }
  }

  const renderTicketDividerLast = (message, index) => {
    if (index === messagesList.length - 1) {
      if (message.ticket?.status === 'closed' && user.canSeeHistory && message.ticket?.user) {
        return getDivider(message);
      }
    }
  }

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const getQuotedMessageHeight = (message) => {
    if (message.quotedMsg.mediaType === 'chat') {
      return { height: "auto" };
    }
    if (message.quotedMsg.mediaType === "audio") {
      return { height: 110 };
    }
    if (message.quotedMsg.mediaType === "vcard") {
      return { height: "auto" };
    }
    return { height: "auto", maxHeight: 300 };
  }

  const renderQuotedMessage = (message) => {
    let array = message.quotedMsg.body?.split("\n");
    let obj = [];
    let contact = "";
    for (let index = 0; index < array.length; index++) {
      const v = array[index];
      let values = v.split(":");
      for (let ind = 0; ind < values.length; ind++) {
        if (values[ind].indexOf("+") !== -1) {
          obj.push({ number: values[ind] });
        }
        if (values[ind].indexOf("FN") !== -1) {
          contact = values[ind + 1];
        }
      }
    }
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg} style={getQuotedMessageHeight(message)}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg.mediaType === "audio"
            && (
              <div className={classes.downloadMedia}>
                <audio controls>
                  <source src={message.quotedMsg.s3Url} type="audio/ogg"></source>
                  <source src={message.quotedMsg.s3Url} type="audio/mpeg"></source>
                </audio>
              </div>
            )
          }
          {message.quotedMsg.mediaType === "video"
            && (
              <video
                className={classes.messageMedia}
                src={message.quotedMsg.s3Url}
                controls
              />
            )
          }
          {message.quotedMsg.mediaType === "application"
            && !message.quotedMsg.mediaUrl?.toLowerCase().endsWith(".pdf") && message.quotedMsg.s3Url
            && (
              <div className={classes.downloadMedia}>
                <Button
                  startIcon={<GetApp />}
                  style={{ width: "100%" }}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={message.quotedMsg.s3Url}
                >
                  Download
                </Button>
              </div>
            )
          }
          {message.quotedMsg.mediaType === "application"
            && message.quotedMsg.mediaUrl?.toLowerCase().endsWith(".pdf") && message.quotedMsg.s3Url
            && <PDFMessage mediaUrl={message.quotedMsg.s3Url} handleOpenPdfModal={handleOpenPdfModal}/>
          }
          {
            message.quotedMsg.mediaType === "vcard" &&
            <VcardPreview messageId={message.id} contact={contact} numbers={obj[0]?.number} />
          }
          {message.quotedMsg.mediaType === "image" &&
            <ModalImageCors imageUrl={message.quotedMsg.s3Url} />}
          {message.quotedMsg.mediaType === "chat" && message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const renderForwardedMessage = () => {
    return (
      <div className={classes.forwardedHeader}>
        <ArrowForwardIcon style={{ fontSize: "1rem", marginRight: "3px" }}/>
        Encaminhada
      </div>
    );
  };

  const getMediaClass = (message, right) => {
    const { mediaUrl, ctwaContext } = message;

    if (mediaUrl || ctwaContext) {
      let suffix = mediaUrl?.toLowerCase().endsWith(".pdf") ? "Pdf" : "";
      suffix = message.mediaType === "audio" ? "Audio" : suffix;
      return right ? classes[`messageRightMedia${suffix}`] : classes[`messageLeftMedia${suffix}`];
    }

    return right ? classes.messageRight : classes.messageLeft;
  }

  const renderMessages = () => {
    const viewMessagesList = messagesList.map((message, index) => {
      if (!message.fromMe) {
        return (
          <React.Fragment key={message.id}>
            {renderTicketDivider(index)}
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}
            <div className={`${getMediaClass(message, false)}`}>
              {
                !spy &&
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

              }
              {isGroup && (
                <span className={classes.messageContactName}>
                  {message.contact?.name}
                </span>
              )}
              {(message.mediaUrl ||
                message.mediaType === "location" ||
                message.mediaType === "vcard" ||
                message.ctwaContext)
                && <MessageMedia message={message} handleOpenPdfModal={handleOpenPdfModal}/>}
              <div
                className={clsx(classes.textContentItemLeft, {
                  [classes.textContentItemDeleted]: message.isDeleted,
                })}
              >
                {message.isDeleted && (
                  <Block
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                  />
                )}
                <div className={classes.textContentItem}>
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.isForwarded && renderForwardedMessage()}
                  {message.mediaType === "ciphertext" && renderCiphertext(message)}
                  {message.mediaType !== "ciphertext" && <MarkdownWrapper>{handleMessageText(message)}</MarkdownWrapper>}
                </div>
                <span className={classes.timestamp}>
                  {format(parseISO(message.createdAt), "HH:mm")}
                </span>
              </div>
            </div>
            {renderTicketDividerLast(message, index)}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={message.id}>
            {renderTicketDivider(index)}
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}

            <div className={getMediaClass(message, true)}>
              {
                !spy &&
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
              }
              {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard")
                && <MessageMedia message={message} handleOpenPdfModal={handleOpenPdfModal}/>}
              <div
                className={clsx(classes.textContentItemRight, {
                  [classes.textContentItemDeleted]: message.isDeleted,
                })}
              >
                {message.isDeleted && (
                  <Block
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                  />
                )}
                {message.quotedMsg && renderQuotedMessage(message)}
                {message.isForwarded && renderForwardedMessage()}
                  {message.mediaType === 'template' ? (
                    <>
                      <Typography style={{fontWeight: 'bold', fontSize: "15px"}}>
                        <MarkdownWrapper>
                          {handleMessageTemplateHeaderText(message)}
                        </MarkdownWrapper>

                      </Typography>
                      <MarkdownWrapper>
                        {handleMessageTemplateBodyText(message)}
                      </MarkdownWrapper>

                      <Typography style={{ color: 'rgba(0, 0, 0, 0.45)'}}>
                        <MarkdownWrapper>
                          {handleMessageTemplateFooterText(message)}
                        </MarkdownWrapper>
                      </Typography>
                    </>
                  ) : (
                    <MarkdownWrapper>
                      {handleMessageText(message)}
                    </MarkdownWrapper>
                  )}
                <span className={classes.timestamp}>
                  {format(parseISO(message.createdAt), "HH:mm")}
                  {renderMessageAck(message)}
                </span>
              </div>
            </div>
            {renderTicketDividerLast(message, index)}
          </React.Fragment>
        );
      }
    });
    return viewMessagesList;
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
    setDragFile(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setDragFile(false);
      setIsDragging(false);
    }, 200);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragFile(false);
    setIsDragging(false);
  };

const handleCloseDragFile = () =>{
  setIsDragging(false);
};

return (
<div
      className={classes.messagesListWrapper}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDrop={handleDrop}
    >
      {isDragging ? (
        <div className={classes.dragFileWrapper}>
          <div className={classes.dragFileZone}>
           <Grid container>
           <DragFile onClose={handleCloseDragFile} />
      </Grid> 
          </div>
        </div>
    ) : (
      <div className={classes.messagesListWrapper}>
        <MessageOptionsMenu
          message={selectedMessage}
          anchorEl={anchorEl}
          menuOpen={messageOptionsMenuOpen}
          handleClose={handleCloseMessageOptionsMenu}
        />
        <PDFModal
          pdfUrl={selectedPdf}
          modalOpen={pdfModalOpen}
          onClose={handleClosePdfModal}
        />
        <>
          <div
            id={`messagesList-${spy}`}
            className={classes.infiniteScrollContainer}
          >
            <InfiniteScroll
              onScroll={handleScroll}
              className={classes.messagesList}
              dataLength={messagesList.length}
              next={fetchMessages}
              inverse={true} //
              hasMore={hasMore}
            loader={<div>
                  <CircularProgress className={classes.circleLoading} />
            </div>}
              scrollableTarget={`messagesList-${spy}`}
            >
              {renderMessages()}
            {
              showButtonDown &&
                <ButtonScrollDown
                  showButtonDown={showButtonDown}
                  newMessageCount={newMessageCount}
                  onClick={() => scrollToBottom()}
                />
            }
            </InfiniteScroll>
          </div>
        </>
      </div>
    )}
  </div>
);
};

export default MessagesList;