import React, { useEffect, useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ptLocale from "date-fns/locale/pt";

import { CircularProgress, FormControl, TextField, Typography } from "@material-ui/core"
import api from "../../services/api"
import toastError from "../../errors/toastError"
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n"
import BarChartTickets from "../../components/BarChartTickets"

const filterOptions = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		marginTop: 12,
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 340,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		height: 120,
		cursor: "pointer"
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
		cursor: "pointer"
	},
	waiting: {
		color: theme.palette.warning.main
	},
	finished: {
		color: theme.palette.success.main
	},
	filterItem: {
		marginRight: 8
	}
}))

const Reports = () => {
	const classes = useStyles();
	const [users, setUsers] = useState([]);
	const [allTickets, setAllTickets] = useState([]);
	const [closedTickets, setClosedTickets] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");

	const [dateStart, setDateStart] = useState(new Date().toISOString());
	const [dateEnd, setDateEnd] = useState(new Date().toISOString());

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});
					setUsers(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam]);

	useEffect(() => {
		const fetchTickets = async () => {
			try {
				const { data } = await api.get("/report-tickets", {
					params: {	dateStart, dateEnd, userId: selectedUser?.id }
			});
				setAllTickets(data.allTickets);
				setClosedTickets(data.closedTickets);
			} catch (err) {
				toastError(err);
			}
		};
		fetchTickets();
	}, [dateStart, dateEnd, selectedUser]);

	const handleDateStartChange = (date) => {
		setDateStart(date.toISOString());
	};

	const handleDateEndChange = (date) => {
		setDateEnd(date.toISOString());
	};

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
						<Grid item xs={12} md={12}>
							<Paper>
								<Typography component="h6" variant="h6" style={{ paddingLeft: 16, paddingTop: 8 }}>
									Filtros
								</Typography>
								<div style={{ display: 'flex', padding: 16, alignItems: 'center' }}>
									<Autocomplete
										variant="outlined" 
										size="small"
										fullWidth
										className={classes.filterItem}
										getOptionLabel={option => `${option.name}`}
										onChange={(e, newValue) => {
											setSelectedUser(newValue);
										}}
										options={users}
										filterOptions={filterOptions}
										freeSolo
										autoHighlight
										noOptionsText={i18n.t("transferTicketModal.noOptions")}
										loading={loading}
										renderInput={params => (
											<TextField
												margin="dense"
												{...params}
												label="Usuários"
												variant="outlined"
												autoFocus
												onChange={e => setSearchParam(e.target.value)}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{loading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
									<FormControl variant="outlined" margin="dense" className={classes.filterItem} fullWidth>
										<KeyboardDatePicker
											inputVariant="outlined"
											size="small"
											label="De"
											format="dd/MM/yyyy"
											id="date-picker-inline"
											value={dateStart}
											onChange={handleDateStartChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									</FormControl>
									<FormControl variant="outlined" margin="dense" className={classes.filterItem} fullWidth>
										<KeyboardDatePicker
											inputVariant="outlined"
											size="small"
											label="Até"
											format="dd/MM/yyyy"
											id="date-picker-inline"
											value={dateEnd}
											onChange={handleDateEndChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									</FormControl>
								</div>
							</Paper>
							<Paper className={classes.fixedHeightPaper}>
								<BarChartTickets title="Conversas criadas:" tickets={allTickets} type="created"/>
							</Paper>
							<Paper className={classes.fixedHeightPaper}>
								<BarChartTickets title="Conversas resolvidas:" tickets={closedTickets} type="closed"/>
							</Paper>
						</Grid>
					</MuiPickersUtilsProvider>
				</Grid>
			</Container>
		</div>
	)
}

export default Reports;