import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Paper, Grid } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import Alert from '@material-ui/lab/Alert';
import MessagePreview from "../MessagePreview";

const useStyles = makeStyles(theme => ({

    paperHeader: {
        marginBottom: "20px",
        padding: "15px",
    },
    paperBody: {
        padding: "15px",
    },
    paperAlert: {
        marginTop: "20px",
    },
}));

const TemplateVariavel = ({ 
    selectedTemplate, 
    countBodyVariable, 
    countHeaderVariable, 
    setBlockSendButton, 
    setDataBodyVariables, 
    setDataHeadleVariable, 
    setBodyTextModifiedByVariable, 
    setHeaderTextModifiedByVariable }) => {
    const classes = useStyles();
    const [textAlert, setTextAlert] = useState();
    const [bodyEnteredValue, setBodyEnteredValue] = useState(Array(countBodyVariable).fill(""));
    const [headerEnteredValue, setHeaderEnteredValue] = useState(Array(countBodyVariable).fill(""));
    const [bodyTextTemplate, setBodyTextTemplate] = useState("");
    const [headerTextTemplate, setHeaderTextTemplate] = useState("");
    const [footerTextTemplate, setFooterTextTemplate] = useState("");
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);

    useEffect(() => {

        const headerText = selectedTemplate?.components
        ?.filter(component => component.type === "HEADER" && component.format === "TEXT")
        ?.map(component => component.text)
        ?.join(" ") || undefined;

        const bodyText = selectedTemplate?.components
            ?.filter(component => component.type === "BODY")
            ?.map(component => component.text)
            ?.join(" ") || "";

        const footerText = selectedTemplate?.components
            ?.filter(component => component.type === "FOOTER")
            ?.map(component => component.text)
            ?.join(" ") || undefined;

        setBodyTextTemplate(bodyText);
        setHeaderTextTemplate(headerText);
        setFooterTextTemplate(footerText);
        setBodyEnteredValue(Array(countBodyVariable).fill(''))
        setHeaderEnteredValue(Array(countHeaderVariable).fill(''))
    }, [selectedTemplate, countBodyVariable, countHeaderVariable]);

    useEffect(() => {
        if (countBodyVariable > 1 || countHeaderVariable > 1 || (countBodyVariable + countHeaderVariable >1)) {
            setTextAlert(i18n.t("TemplateVariavel.alerts.several"));
        } else {
            setTextAlert(i18n.t("TemplateVariavel.alerts.single"));
        }
    }, [countBodyVariable, countHeaderVariable]);

    const handleTextChange = (index, event) => {
        const newEnteredValue = [...bodyEnteredValue];
        newEnteredValue[index] = event.target.value;
        setBodyEnteredValue(newEnteredValue);
    };

    const handleHeaderTextChange = (index, event) => {
        const newEnteredValue = [...headerEnteredValue];
        newEnteredValue[index] = event.target.value;
        setHeaderEnteredValue(newEnteredValue);
    };

    const handleBlockSendButton = useCallback((value) => {
        setBlockSendButton(value);
    }, [setBlockSendButton]);

    const handleBodyEnteredValue = useCallback((value) => {
        setDataBodyVariables(value);
    }, [setDataBodyVariables]);

    const handleHeaderEnteredValue = useCallback((value) => {
        setDataHeadleVariable(value);
    }, [setDataHeadleVariable]);

    const handleBodyTextModifiedByVariable = useCallback((text, variable) => {
        setBodyTextModifiedByVariable(replaceVariables(text, variable))
    }, [setBodyTextModifiedByVariable]);

    const handleHeaderTextModifiedByVariable = useCallback((text, variable) => {
        setHeaderTextModifiedByVariable(replaceVariables(text, variable))
    }, [setHeaderTextModifiedByVariable]);

    const validateFields = useCallback(() => {
        const allFilled = bodyEnteredValue.every(value => value.trim() !== '');
        let allHeaderFilled = countHeaderVariable > 0 ? headerEnteredValue.every(value => value.trim() !== '') : true;

        handleBlockSendButton(allFilled && allHeaderFilled && selectedTemplate ? false : true)
        setAllFieldsFilled(allFilled && allHeaderFilled ? true : false)
    }, [bodyEnteredValue, headerEnteredValue, countHeaderVariable, selectedTemplate, handleBlockSendButton]);

    useEffect(() => {
        validateFields();
        handleBodyEnteredValue(bodyEnteredValue)
        handleHeaderEnteredValue(headerEnteredValue)

        handleBodyTextModifiedByVariable(bodyTextTemplate, bodyEnteredValue)
        handleHeaderTextModifiedByVariable(headerTextTemplate, headerEnteredValue)

    }, [bodyEnteredValue,
        bodyTextTemplate, 
        headerEnteredValue, 
        headerTextTemplate,
        validateFields, 
        handleBodyEnteredValue, 
        handleHeaderEnteredValue, 
        handleBodyTextModifiedByVariable, 
        handleHeaderTextModifiedByVariable]);

    const handleTextField = () => {
        let textFields = [];
        for (let i = 0; i < countBodyVariable; i++) {
            textFields.push(
                <TextField
                    key={i}
                    label={`{{${i + 1}}}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={bodyEnteredValue[i] || ""}
                    onChange={(e) => handleTextChange(i, e)}
                />
            );
        }
        return textFields;
    }

    const handleHeaderTextField = () => {
        let textFields = [];
        for (let i = 0; i < countHeaderVariable; i++) {
            textFields.push(
                <TextField
                    key={i}
                    label={`{{${i + 1}}}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={headerEnteredValue[i] || ""}
                    onChange={(e) => handleHeaderTextChange(i, e)}
                />
            );
        }
        return textFields;
    }

    function replaceVariables(template, values) {
        return template?.replace(/\{\{\d+\}\}/g, (match) => {
            const index = parseInt(match.replace(/\{\{|\}\}/g, ''), 10) - 1;
            return `${values[index] || match}`;
        });
    }

    return (
        <div>
            <Paper variant="outlined" className={classes.paperStyle} style={{ marginTop: "20px", padding: "15px" }}>
                <Grid container spacing={2} justifyContent="center" >
                    {selectedTemplate?.components?.some(
                        (component) => component.type === "BODY"
                    ) &&
                        <Grid item md={6} xs={12}>
                            <>
                                {headerTextTemplate && countHeaderVariable >0 && (
                                    <Paper variant="outlined" className={classes.paperHeader}>
                                        <Typography variant="h6">{i18n.t("TemplateVariavel.title.titleHeader")}</Typography>
                                        {handleHeaderTextField()}
                                    </Paper>
                                )}
                            </>
                            {(countHeaderVariable >0 || countBodyVariable >0) && (
                            <Paper variant="outlined" className={classes.paperBody}>
                                <Typography variant="h6"> {headerTextTemplate ? i18n.t("TemplateVariavel.title.titleBody") : i18n.t("TemplateVariavel.title.titleSimple")} </Typography>
                                {handleTextField()}
                                <>
                                    {!allFieldsFilled && (
                                        !headerTextTemplate && (
                                            <Alert severity="warning">{textAlert}</Alert>
                                        )
                                    )}
                                </>
                            </Paper>)}
                            <>
                                {!allFieldsFilled && (
                                    headerTextTemplate && (
                                        <Paper variant="outlined" className={classes.paperAlert}>
                                            <Alert severity="warning">{textAlert}</Alert>
                                        </Paper>
                                    )
                                )}
                            </>
                        </Grid>
                    }
                    <Grid item md={countBodyVariable > 0 || countHeaderVariable > 0 ? 6 : 12} xs={12}>
                        <MessagePreview
                            message={replaceVariables(bodyTextTemplate, bodyEnteredValue)}
                            headerMessage={replaceVariables(headerTextTemplate, headerEnteredValue)}
                            footerMessage={footerTextTemplate}
                            placeholder="Selecione um Modelo"
                            placeholderEllipsis={true}/>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default TemplateVariavel;
