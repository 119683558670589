import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import LoginCalcme from "../pages/Login/calcme";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Companies from "../pages/Companies/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Shifts from "../pages/Shifts";
import CompanyEdit from "../pages/CompanyEdit";
import Integrations from "../pages/Integrations";
import ReportsTickets from "../pages/ReportsTickets";
import Agenda from "../pages/Agenda";
import Campaigns from "../pages/Campaigns";
import ContactLists from "../pages/ContactLists";
import ContactListItems from "../pages/ContactListItems";
import CampaignReport from "../pages/CampaignReport";
import Alerts from "../pages/Alerts";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/calcme/login" component={LoginCalcme} />
          <Route exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route
                exact
                path="/tickets/:ticketId?"
                component={Tickets}
                isPrivate
              />
              <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
              />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />
              <Route exact path="/Agenda" component={Agenda} isPrivate />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/companies" component={Companies} isPrivate />
              <Route exact path="/alerts" component={Alerts} isPrivate />
              <Route exact path="/companies/:companyId" component={CompanyEdit} isPrivate />
              <Route exact path="/Integrations" component={Integrations} isPrivate />
              <Route exact path="/Reports" component={ReportsTickets} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
              <Route exact path="/Shifts" component={Shifts} isPrivate />
              <Route exact path="/campaign/:campaignId/report" component={CampaignReport} isPrivate />
              <Route exact path="/Campaigns" component={Campaigns} isPrivate />
              <Route exact path="/contact-lists" component={ContactLists} isPrivate />
              <Route exact path="/contact-lists/:contactListId/contacts" component={ContactListItems} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
