import React, { useContext, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import { CircularProgress, Dialog, DialogContent, Paper, Typography, makeStyles } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  circleLoading: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		height: 200,
		alignItems: "center"
  },  
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const [qrCode, setQrCode] = useState("");
	const { user } = useContext(AuthContext);
	const classes = useStyles();

	useEffect(() => {
		setQrCode("");
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		const socket = openSocket();

		socket.on(`whatsappSession${user.companyId}`, data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.status === "CONNECTED") {
				onClose();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [whatsAppId, onClose, user.companyId]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					{qrCode ? (
						<QRCode value={qrCode} size={256} />
					) : (
						<div>
							<div className={classes.circleLoading}>
								<CircularProgress style={{color: "#483D8B"}} />
							</div>
							<span>Gerando o QR Code. Isso pode levar alguns segundos.</span>
						</div>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
