import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "395px",
    marginRight: "-15px",
    marginBottom: "-15px"
  },
  square: {
    borderRadius: 5,
    width: '65px',
    height: '65px',
    marginRight: 14,
    marginBottom: 14,
    transform: "scale(1)",
    transition: "transform 100ms ease 0s",
    "&:hover": { transform: "scale(1.1)" }
  },
}));

const ColorPalette = ({ onChange, currentColor }) => {
  const classes = useStyles();

  const colors = [
    "#fff063",
    "#ffac32",
    "#ff784e",
    "#f6685e",
    "#95c267",
    "#33ab9f",
    "#35baf5",
    "#939ed4",
    "#9877ce",
    "#aa90d7",
  ];

  const handleChange = (color) => {
    if (typeof onChange === "function") {
      onChange(color);
    }
  };

  return (
    <div className={classes.container}>
      <>
        {
          colors.map((color, index) => {
            return <div
              key={index}
              className={classes.square} 
              style={{ 
                background: color, 
                boxShadow: currentColor === color ? "black 0px 0px 0px 0px inset, black 0px 0px 5px" : "none", 
                border: currentColor === color ? "2px solid black" : 0,
                cursor: "pointer"
              }}
              onClick={ () => handleChange(color)}>
            </div>
          })
        }
      </>
    </div>
  );
};

export default ColorPalette;
