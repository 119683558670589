import React from "react";

import Dialog from "@material-ui/core/Dialog";
import PDFViewer from "../PDFViewer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.scrollbarStyles,
  },

}));

const PDFModal = ({ pdfUrl, modalOpen, onClose }) => {
  const classes = useStyles();

  const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} className={classes.container} maxWidth="lg" scroll="paper"
    PaperProps={{className: classes.container}}>
			<PDFViewer pdfUrl={pdfUrl} width={700}/>
		</Dialog>
	);
};

export default PDFModal;
