import React, { useState, createContext } from "react";

const DragFileContext = createContext();

const DragFileProvider = ({ children }) => {
	const [dragFile, setDragFile] = useState();

	return (
		<DragFileContext.Provider
			value={{
				dragFile,
				setDragFile,
			}}
		>
			{children}
		</DragFileContext.Provider>
	);
};

export { DragFileContext, DragFileProvider };