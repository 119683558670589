import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
}));

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketsList, ticketWhatsappId }) => {
	const history = useHistory();
	const [optionsUser, setOptionsUser] = useState([]);
	const [queues, setQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState('');
	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const classes = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);
	const { findAll: findAllQueues } = useQueues();
	const { loadingWhatsapps, whatsApps } = useWhatsApps({companyId: loggedInUser.companyId});

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);			
		}
		loadQueues();
		loadUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadUsers = async (queueId) => {
		if (queueId) {
			const { data } = await api.get("/users/", {
				params: { queueId },
			});
			setOptionsUser(data.users);
		} else {
			const { data } = await api.get("/users");
			setOptionsUser(data.users);
		}
	}

	const handleClose = () => {
		onClose();
		setSelectedUser('');
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid && !ticketsList) {
			return
		}	
		setLoading(true);
		try {
			if (selectedUser || selectedQueue) {
				let data = {
					status: 'pending',
					userId: selectedUser === '' ? null : selectedUser,
					queueId: selectedQueue === '' ? null : selectedQueue,
					whatsappId: selectedWhatsapp
				};
				if (ticketsList) {
					data = {
						...data,
						oldStatus: ticketsList[0].status,
						ticketIdsToUpdate: ticketsList.map(ticket => ticket.id)
					}
					await api.put(`/tickets-bulkupdate`, data);
				} else {
					await api.put(`/tickets/${ticketid}`, data);
				}
	
				setLoading(false);
				history.push(`/tickets`);
				toast.success(i18n.t("transferTicketModal.successTransferTicket"));
				handleClose();
			} else {
				setLoading(false);
				toast.error(i18n.t("transferTicketModal.selectOneQueueOrUser"));
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => {
								setSelectedQueue(e.target.value);
								setSelectedUser('');
								loadUsers(e.target.value);
							}}
							label={i18n.t("transferTicketModal.fieldQueueLabel")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
						<InputLabel>{i18n.t("transferTicketModal.fieldUserLabel")}</InputLabel>
						<Select
							value={selectedUser}
							onChange={(e) => setSelectedUser(e.target.value)}
							label={i18n.t("transferTicketModal.fieldUserLabel")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{optionsUser.map((user) => (
								<MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<Can
						role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps && 
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
