import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { QuickAnswerContext } from '../../context/QuickAnswer/QuickAnswerContext';
import { IconButton, Link, Paper, Typography } from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  sendMessageIcons: {
    color: "#483D8B",
  },
}));

const QuickAnswersAttachmentBar = ({ticketId}) => {
  const classes = useStyles();
  const { quickAnswerAttachment, setQuickAnswerAttachment } = useContext(QuickAnswerContext);

  const handleSendMessage = async () => {
    try {
      console.log(ticketId);
      await api.post(`/sendQuickAnswerAttachment`, { quickAnswerId: quickAnswerAttachment?.id, ticketId });
    } catch (err) {
      toastError(err);
    }

    setQuickAnswerAttachment(null);
  };

  if (quickAnswerAttachment?.attachmentName) {
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => {setQuickAnswerAttachment(null)}}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>
        <span>
          <Typography>
            <Link
              target="_blank"
              href={quickAnswerAttachment?.attachmentUrl}
              variant="body2"
              download={false}
            >
              {`${quickAnswerAttachment?.attachmentName}`}
            </Link>
          </Typography>
        </span>
        { !quickAnswerAttachment?.message &&
          <IconButton
            style={{ marginLeft: "auto"}}
            aria-label="send-upload"
            component="span"
            onClick={handleSendMessage}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        }
      </Paper>
    );
  }
  return null;
}

export default QuickAnswersAttachmentBar;