import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";
import { toast } from "react-toastify";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
	Typography
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import ColorPalette from "../ColorPalette";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	colorPalette: {
		backgroundColor: 'red',
		width: '200px',
		height: '200px',
	},
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Mensagem muito curta")
		.required("Obrigatório")
});

const TagModal = ({ open, onClose, selectedTag, setSelecteds, selecteds, reload, ticket, contact }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const initialState = {
		name: "",
		color: ""
	};

	const [tag, setTag] = useState(initialState);

	useEffect(() => {
		try {
			(async () => {
				if (!selectedTag?.id) return;

				const { data } = await api.get(`/tags/${selectedTag?.id}`);
				setTag(prevState => {
					return { ...prevState, ...data };
				});
			})()
		} catch (err) {
			toastError(err);
		}
	}, [selectedTag?.id, open]);

	const handleClose = () => {
		setTag(initialState);
		onClose();
	};

	const handleSaveTag = async values => {
		const tagData = { ...values, ticketId: ticket?.id, contactId: contact?.id };
		try {
			if (selectedTag?.id) {
				await api.put(`/tags/${selectedTag?.id}`, tagData);
			} else {
				await api.post("/tags", tagData);
			}
			toast.success(i18n.t("tagModal.success"));
			if (typeof reload == 'function') {
				reload();
				if (selectedTag && selectedTag.id) {
					const data = selecteds.map(obj => { return obj.id === tagData.id ? tagData : obj });
					setSelecteds(data);
				}
			}
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{(selectedTag?.id ? `${i18n.t("tagModal.title.edit")}` : `${i18n.t("tagModal.title.add")}`)}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("tagModal.form.name")}
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										onChange={(e) => setTag(prev => ({ ...prev, name: e.target.value }))}
										fullWidth
									/>
								</div>
								<br />
									<Typography variant="subtitle1" gutterBottom>
										Escolha a cor da etiqueta
									</Typography>
									<ColorPalette
										onChange={(color) => {
											setTag(prev => ({ ...prev, color: color }))
										}}
										currentColor={tag.color}
									/>
									
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("tagModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{selectedTag?.id
										? `${i18n.t("tagModal.buttons.okEdit")}`
										: `${i18n.t("tagModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default TagModal;