import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";


import api from "../../services/api";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { formatDateTime } from '../../helper/helper';
import ConfirmationModal from "../../components/ConfirmationModal";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import { Button, Chip, CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertModal from "../../components/AlertModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_ALERTS") {
    const alerts = action.payload;
    const newAlerts = [];

    alerts.forEach((alert) => {
      const alertIndex = state.findIndex((c) => c.id === alert.id);
      if (alertIndex !== -1) {
        state[alertIndex] = alert;
      } else {
        newAlerts.push(alert);
      }
    });

    return [...state, ...newAlerts];
  }

  if (action.type === "UPDATE_ALERTS") {
    const alert = action.payload;
    const alertIndex = state.findIndex((c) => c.id === alert.id);

    if (alertIndex !== -1) {
      state[alertIndex] = alert;
      return [...state];
    } else {
      return [alert, ...state];
    }
  }

  if (action.type === "DELETE_ALERT") {
    const alertId = action.payload;

    const alertIndex = state.findIndex((c) => c.id === alertId);
    if (alertIndex !== -1) {
      state.splice(alertIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    flexDirection: "column"
  },
  infiniteScrollContainer: {
    height: "100%",
    overflow: 'auto',
    display: 'flex',
    ...theme.scrollbarStyles,
    scrollBehavior: "smooth",
    flexDirection: "column"
  },
  infiniteScroll: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  circleLoading: {
    color: "#483D8B",
    position: "absolute",
    opacity: "100%",
    top: 200,
    left: "50%",
    marginTop: 12,
  }
}));

const Alerts = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [alerts, dispatch] = useReducer(reducer, []);
  const [deletingAlert, setDeletingAlert] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  useEffect(() => {
    fetchAlerts();
  }, []);

  const fetchAlerts = async () => {
    setIsLoading(true); 
    try {
      const { data } = await api.get("/all-alerts");
      dispatch({ type: "LOAD_ALERTS", payload: data.alerts });
    } catch (err) {
      toastError(err);
    } finally {
      setIsLoading(false); 
    }
  }

  useEffect(() => {
    const socket = openSocket();

    socket.on(`alert${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ALERTS", payload: data.alert });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_ALERT", payload: +data.alertId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  const handleDeleteAlert = async (alertId) => {
    try {
      await api.delete(`/alerts/${alertId}`);
      toast.success(i18n.t("alerts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingAlert(null);
  };

  const handleEditAlert = (queue) => {
    setSelectedAlert(queue);
    setAlertModalOpen(true);
  };

  const handleCloseAlertModal = () => {
    setAlertModalOpen(false);
  };

  const handleOpenAlertModal = () => {
    setSelectedAlert(null);
    setAlertModalOpen(true);
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ConfirmationModal
        title={
          deletingAlert ?
            `${i18n.t("alerts.confirmationModal.deleteTitle")} ${deletingAlert.title
            }?` : ''
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          handleDeleteAlert(deletingAlert.id)
        }
      >
        {i18n.t("alerts.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("alerts.title")}</Title>
        <AlertModal
          alertId={selectedAlert?.id}
          open={alertModalOpen}
          onClose={handleCloseAlertModal}
        />
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAlertModal}
          >
            {i18n.t("alerts.newAlertButton")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <div
          id="alertsList"
          className={classes.infiniteScrollContainer}>
          <InfiniteScroll
            className={classes.infiniteScroll}
            dataLength={alerts.length}
            next={fetchAlerts}
            loader={isLoading ?<CircularProgress className={classes.circleLoading} /> : null}
            scrollableTarget="alertsList"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {i18n.t("alerts.table.id")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.type")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.title")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.link")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.message")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.companyName")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.createdAt")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("alerts.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alerts.map((alert) => (
                  <TableRow key={alert.id}>
                    <TableCell align="center">{alert.id}</TableCell>
                    <TableCell align="center">
                      <Chip
                        size="small"
                        style={{ 
                          borderColor: alert.type === 'update' ? '#388e3c' : '#1976d2',
                          color: alert.type === 'update' ? '#388e3c' : '#1976d2',
                        }}
                        variant="outlined"
                        label={alert.type === 'update' ? "Atualização" : "Notificação"}
                        className={classes.chip}
                      />
                    </TableCell>
                    {/* <TableCell align="center">{alert.type === 'update' ? "Atualização" : "Notificação"}</TableCell> */}
                    <TableCell align="center">{alert.title}</TableCell>
                    <TableCell align="center">{alert.link}</TableCell>
                    <TableCell align="center">{alert.message}</TableCell>
                    <TableCell align="center">{alert.company?.name}</TableCell>
                    <TableCell align="center">{formatDateTime(alert.createdAt)}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditAlert(alert)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmOpen(true);
                          setDeletingAlert(alert);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Alerts;
