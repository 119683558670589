import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useHistory } from "react-router-dom";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [resolveTicket, setResolveTicket] = useState(false);
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleResolveSelectedTicket = () => {
		setResolveTicket(true);
	}

	const handleUpdateTicketStatus = async (e, status, userId) => {
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				sendOffFarewellMessage: status === "closed" ? checked : true
			});

			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpenTransferModal}>
					{i18n.t("ticketOptionsMenu.transfer")}
				</MenuItem>
				{ ticket.status === "closed" &&
					<MenuItem onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}>
						{i18n.t("messagesList.header.buttons.reopen")}
					</MenuItem>
				}
				{ ticket.status === "open" &&
					<MenuItem onClick={e => handleUpdateTicketStatus(e, "pending", null)}>
						{i18n.t("messagesList.header.buttons.return")}
					</MenuItem>
				}
				{ ticket.status === "open" &&
					<MenuItem onClick={handleResolveSelectedTicket}>
						{i18n.t("messagesList.header.buttons.resolve")}
					</MenuItem>
				}
				{ ticket.status === "pending" &&
					<MenuItem onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}>
						{i18n.t("messagesList.header.buttons.accept")}
					</MenuItem>
				}
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/>
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} #${
					ticket.id
				} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
					ticket.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticketWhatsappId={ticket.whatsappId}
			/>
			<ConfirmationModal
				iconType="warning"
				title={i18n.t("ticketOptionsMenu.resolveItemConfirmationModal.title")}
				open={resolveTicket}
				onClose={setResolveTicket}
				onConfirm={e => handleUpdateTicketStatus(e, "closed", user?.id)}
				confirmTitle={i18n.t("ticketOptionsMenu.buttons.accept")}
			>
				<Typography component="span" display="block">
					{i18n.t("ticketOptionsMenu.resolveItemConfirmationModal.message")}
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleCheckboxChange}
							name="confirmationCheckbox"
							color="primary"
						/>
					}
					label={i18n.t("ticketOptionsMenu.farewellMesage")}
				/>
			</ConfirmationModal >
		</>
	);
};

export default TicketOptionsMenu;
