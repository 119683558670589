import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		width: "100%"
	},
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	customTextarea: {
		marginBottom: "30px",
	},
	boxContainer: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
		[theme.breakpoints.up("sm")]: {
			marginTop: 5
		},
	},
	boxRow1: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
	},
	textFieldStart: {
		width: "100%"
	},
	textFieldEnd: {
		width: "100%"
	},
}));

const AlertSchema = Yup.object().shape({
	type: Yup.string().required("Obrigatório"),
	title: Yup.string().required("Obrigatório"),
	message: Yup.string().required("Obrigatório")
});

const AlertModal = ({ open, onClose, alertId }) => {
	const classes = useStyles();
	const [companies, setCompanies] = useState([]);

	const initialState = {
		type: "",
		title: "",
		message: "",
		link: "",
		company: null
	};
	const [alert, setAlert] = useState(initialState);

	const handleClose = () => {
		onClose();
	};

	const loadCompanies = async () => {
    try {
      const { data } = await api.get(`/all-companies`);
      setCompanies(data.companies);
    } catch (err) {
      toastError(err);
    }
  };

	useEffect(() => {
		(async () => {
			if (open) {
				await loadCompanies();
			}
			if (!alertId) return;
			try {
				const { data } = await api.get(`/alerts/${alertId}`);
				setAlert(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setAlert(initialState);
		};
	}, [alertId, open]);

	const handleSaveAlert = async values => {
		try {
			const request = {
				type: values.type,
				title: values.title,
				message: values.message,
				link: values.link,
				companyId: values.company?.id || null
			}
			if (alertId) {
				await api.put(`/alerts/${alertId}`, request);
			} else {
				await api.post("/alerts", request);
			}
			toast.success("Alerta salvo com sucesso");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose} scroll="paper" fullWidth maxWidth="sm" >
				<DialogTitle>
					{alertId
						? `${i18n.t("alertModal.title.edit")}`
						: `${i18n.t("alertModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={alert}
					enableReinitialize={true}
					validationSchema={AlertSchema}
					onSubmit={(values, actions) => {
						console.log(values);
						setTimeout(() => {
							handleSaveAlert(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<Grid container spacing={1}>
									<Grid item sm={8} xs={12}>
										<Field
											as={TextField}
											label={i18n.t("alertModal.form.title")}
											name="title"
											value={values?.title || ""}
											error={touched.title && Boolean(errors.title)}
											helperText={touched.title && errors.title}
											variant="outlined"
											margin="dense"
											className={classes.textFieldName}
										/>
									</Grid>
									<Grid item sm={4} xs={12}>
										<FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    >
											<InputLabel id="type-label">
                        {i18n.t("alertModal.form.type")}
                      </InputLabel>
											<Field
                        as={Select}
												value={values?.type || ""}
                        label={i18n.t("alertModal.form.type")}
                        placeholder={i18n.t("alertModal.form.type")}
                        name="type"
                        error={touched.type && Boolean(errors.type)}
                      >
												<MenuItem value="update">
													Atualização
												</MenuItem>
												<MenuItem value="notification">
													Notificação
												</MenuItem>
                      </Field>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											value={values?.message || ""}
											label={i18n.t("alertModal.form.message")}
											type="message"
											multiline
											minRows={6}
											fullWidth
											name="message"
											error={
												touched.message && Boolean(errors.message)
											}
											helperText={
												touched.message && errors.message
											}
											variant="outlined"
											margin="dense"
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											value={values?.link || ""}
											label={i18n.t("alertModal.form.link")}
											name="link"
											variant="outlined"
											margin="dense"
											className={classes.textFieldName}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={Autocomplete}
											value={values?.company || null}
											id="company"
											name="company"
											onChange={(e, value) => {
												console.log(value);
												setFieldValue(
													"company",
													value !== null ? value : null
												);
											}}
											options={companies}
											getOptionSelected={(option, value) => option.id === value.id}
											getOptionLabel={(c) => c.name || ""}
											renderInput={(params) => <TextField {...params} label="Empresa" name="company" size="small" variant="outlined" />}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions style={{ paddingRight: 22, paddingLeft: 22}}>
								<div style={{marginLeft: "auto"}}>
									<Button
										style={{ marginRight: 6}}
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("alertModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{alertId
											? `${i18n.t("alertModal.buttons.okEdit")}`
											: `${i18n.t("alertModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</div>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default AlertModal;
