import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import TemplateVariavel from "../TemplateVariavel";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	customTextarea: {
		marginBottom: "30px",
	},

	PaperStyle: {
		padding: "10px",
		borderRadius: "5px",
		marginTop: "15px",
	},

}));

const countVariables = (text) => {
	const regex = /\{\{\d+\}\}/g;
	const matches = text.match(regex);
	return matches ? matches.length : 0;
};

function createParameters(type, texts) {
    return {
        type: type,
        parameters: texts.map(text => ({
            type: "text",
            text: text
        }))
    };
}

const TemplatesModal = ({ open, onClose, whatsAppId, ticketId }) => {
	const classes = useStyles();
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState("");
	const [dinamicMaxWidthDialog, setDinamicMaxWidthDialog] = useState("sm");
	const [countBodyVariable, setCountBodyVariable] = useState(0);
	const [countHeaderVariable, setCountHeaderVariable] = useState(0);
	const [blockSendButton, setBlockSendButton] = useState(true);
	const [dataBodyVariables, setDataBodyVariables] = useState([]);
	const [dataHeadleVariable, setDataHeadleVariable] = useState([]);
	const [bodyTextModifiedByVariable, setBodyTextModifiedByVariable] = useState("");
	const [headerTextModifiedByVariable, setHeaderTextModifiedByVariable] = useState("");

	useEffect(() => {
		const fetchTemplates = async () => {
			if (!whatsAppId) return;
			try {
				const { data } = await api.get(`getTemplates/${whatsAppId}`);
				setTemplates(data.templates.data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchTemplates();
	}, [whatsAppId]);

	useEffect(() => {
		if (selectedTemplate) {
			setDataBodyVariables([]);
			setDataHeadleVariable([]);
			const totalVariables = selectedTemplate.components.reduce((acc, component) => {
				if (component.type === "BODY") {
					setCountBodyVariable(acc + countVariables(component.text))
					return acc + countVariables(component.text);
				}
				return acc;
			}, 0);

			const totalHeaderVariables = selectedTemplate.components.reduce((acc, component) => {
				setCountHeaderVariable(0);
				if (component.type === "HEADER" && component.format === "TEXT") {
					const headerText = selectedTemplate?.components
					?.filter(component => component.type === "HEADER" && component.format === "TEXT")
					?.map(component => component.text)
					?.join(" ") || undefined;
					return acc + countVariables(headerText);
				}
				return acc;
			}, 0);
			setCountHeaderVariable(totalHeaderVariables);

			setDinamicMaxWidthDialog(totalVariables || totalHeaderVariables  > 0 ? "md" : "sm");
			setBlockSendButton(false)
		} else {
			setDinamicMaxWidthDialog("sm");
			setBlockSendButton(true)
		}

	}, [selectedTemplate]);

	const handleSendTemplate = async () => {
		try {
			let headerTexts = dataHeadleVariable[0] !== "" ? dataHeadleVariable : undefined;
			let bodyTexts = dataBodyVariables[0] !== "" ? dataBodyVariables : undefined;
			let parameters = [];
			let templateText = [];


			if (headerTexts) {
				const headerParameters = createParameters("HEADER", headerTexts);
				parameters.push(headerParameters)

				templateText.push({
					type: "HEADER",
					text: headerTextModifiedByVariable
				})
			}
			if (bodyTexts){
				const bodyParameters = createParameters("BODY", bodyTexts);
				parameters.push(bodyParameters)

				templateText.push({
					type: "BODY",
					text: bodyTextModifiedByVariable
				})
			} 
			selectedTemplate.components.forEach(component => {
				if (component.type === 'FOOTER') {
					 templateText.push({
						type: "FOOTER",
						text: component.text
					 })
				}
			  });
			
			await api.post(`/sendTemplate/${ticketId}`, { selectedTemplate, parameters, templateText });
			toast.success("Template enviado com sucesso!");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleBlockSendButton = (value) => {
		setBlockSendButton(value);
	};

	const handleEnteredValue = (value) => {
		setDataBodyVariables(value);
	};

	const handleHeadleEnteredValue = (value) => {
		setDataHeadleVariable(value);
	};

	const handleBodyTextModifiedByVariable = (value) => {
		setBodyTextModifiedByVariable(value)
	};

	const handleHeaderTextModifiedByVariable = (value) => {
		setHeaderTextModifiedByVariable(value)
	};

	const handleClose = () => {
		onClose();
		setCountBodyVariable(0);
		setCountHeaderVariable(0);
		setDataBodyVariables([]);
		setDataHeadleVariable([]);
		setSelectedTemplate("");
		setBlockSendButton(true);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={dinamicMaxWidthDialog}
				fullWidth
				scroll="paper"
			>
				<DialogTitle>{i18n.t("templateModal.title")}</DialogTitle>
				<DialogContent dividers>
					<FormControl variant="outlined" style={{ width: "100%" }} margin="dense">
						<InputLabel id="connection-type-input-label">Modelo</InputLabel>
						<Select
							label="Template"
							value={selectedTemplate}
							defaultValue={null}
							onChange={(e) => {
								setSelectedTemplate(e.target.value);
							}}
						>
							{templates?.map((template) => (
								<MenuItem key={template.id} value={template}>
									{template.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TemplateVariavel
						selectedTemplate={selectedTemplate}
						countBodyVariable={countBodyVariable}
						countHeaderVariable={countHeaderVariable}
						setBlockSendButton={handleBlockSendButton}
						setDataBodyVariables={handleEnteredValue}
						setDataHeadleVariable={handleHeadleEnteredValue}
						setBodyTextModifiedByVariable={handleBodyTextModifiedByVariable}
						setHeaderTextModifiedByVariable={handleHeaderTextModifiedByVariable}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary" variant="outlined">
						{i18n.t("templateModal.buttons.cancel")}
					</Button>
					<Button
						onClick={handleSendTemplate}
						type="submit"
						color="primary"
						variant="contained"
						className={classes.btnWrapper}
						disabled={blockSendButton}
					>
						{i18n.t("templateModal.buttons.send")}
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	);
};

export default React.memo(TemplatesModal);