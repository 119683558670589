import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, IconButton } from "@material-ui/core";
import MessagesList from "../MessagesList";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { i18n } from "../../translate/i18n";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderLeft: "0",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
	closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const SpyModal = ({ open, onClose, ticket }) => {
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{i18n.t("spyModal.title")}
					<IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
				</DialogTitle>
				<DialogContent className={classes.mainWrapper} style={{ padding: 0 }} dividers>
					<ReplyMessageProvider>
						<MessagesList
							ticketId={ticket.id}
							isGroup={ticket.isGroup}
							spy
						></MessagesList>
					</ReplyMessageProvider>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default SpyModal;
