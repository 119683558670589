import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { formatDateTime } from "../../helper/helper";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		paddingRight: "4px",
		width: "50%"
	},
	textFieldColor: {
		paddingLeft: "4px",
		width: "50%"
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	select: {
		width: "100%",
		marginTop: "0px",
		marginBottom: "0px"
  },
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginBottom: theme.spacing(1),
		},
		marginBottom: "10px",
		flexDirection: "column"
	},
	button: {
		width: "200px"
	}
}));

const ScheduleModal = ({ modalOpen, onClose, shiftId, companyId, scheduleId }) => {
	const classes = useStyles();

	const [startDate, setStartDate] = useState(formatDateTime(new Date()));
	const [endDate, setEndDate] = useState(formatDateTime(new Date()));
	
	useEffect(() => {
		(async () => {
			if (!scheduleId) return;
			try {
				const { data } = await api.get(`/schedule/${scheduleId}`);
				setStartDate(formatDateTime(new Date(data.startDateTime)));
				setEndDate(formatDateTime(new Date(data.endDateTime)));
			} catch (err) {
				toastError(err);
			}
		})();

	}, [scheduleId, modalOpen]);

	const handleClose = () => {
		onClose();
	};

	const handleSaveSchedule = async e => {
		e.preventDefault();
		try {
			let data = {};

			if (!startDate) {
				toast.error("Selecione uma data de início.");
				return;
			}
			
			if (!endDate) {
				toast.error("Selecione uma data de término");
				return;
			}

			const startDateTime = new Date(startDate);
			const endDateTime = new Date(endDate);

			if (+startDateTime > +endDateTime) {
				toast.error("Data de início não pode ser maior que data de término");
				return;
			}
			
			data.startDateTime = startDateTime;
			data.endDateTime = endDateTime;
			data.shiftId = shiftId;
			data.companyId = companyId;

			if (scheduleId) {
				await api.put(`/schedule/${scheduleId}`, data);
			} else {
				await api.post("/schedule", data);
			}
			toast.success("Horário salvo com sucesso!");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={modalOpen}
				onClose={handleClose}
				scroll="paper">
				<DialogTitle>
					{scheduleId
						? `${i18n.t("scheduleModal.title.edit")}`
						: `${i18n.t("scheduleModal.title.add")}`}
				</DialogTitle>
				<DialogContent>
					<div className={classes.multFieldLine}>
						<TextField
							id="inicia-em"
							label={i18n.t("scheduleModal.form.startDate")}
							type="datetime-local"
							value={startDate}
							className={classes.textField}
							onChange={(e) => {
								setStartDate(e.target.value);
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							id="termina-em"
							label={i18n.t("scheduleModal.form.endDate")}
							type="datetime-local"
							value={endDate}
							className={classes.textField}
							onChange={(e) => {
								setEndDate(e.target.value);
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						{i18n.t("scheduleModal.buttons.cancel")}
					</Button>
					<Button
						onClick={handleSaveSchedule}
						color="primary"
						variant="contained"
						className={classes.btnWrapper}
					>
						{scheduleId
							? `${i18n.t("scheduleModal.buttons.okEdit")}`
							: `${i18n.t("scheduleModal.buttons.okAdd")}`}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ScheduleModal;
