import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AndroidIcon from '@material-ui/icons/Android';
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ModulesList({ companyPlan }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(['bot']);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List subheader={<ListSubheader>Módulos</ListSubheader>} className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-marketing" primary="Marketing" />
        <ListItemSecondaryAction>
          <Field
            as={Switch}
            checked={companyPlan?.marketingModule || false}
            name="companyPlan.marketingModule"
            inputProps={{ 'aria-labelledby': 'switch-list-label-bot' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AndroidIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-bot" primary="Bot" />
        <ListItemSecondaryAction>
          <Field
            as={Switch}
            checked={companyPlan?.botModule || false}
            name="companyPlan.botModule"
            inputProps={{ 'aria-labelledby': 'switch-list-label-bot' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}