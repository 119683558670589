import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
  Tooltip
} from "recharts";
import { parseISO, format, startOfDay } from "date-fns";
import TitleChart from "../TitleChart";
import { Typography } from "@material-ui/core";

const textColorSecondary = "rgba(0, 0, 0, 0.54)";

const useStyles = makeStyles(theme => ({
	emptyResult: {
		display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
	},
}))

const BarChartTickets = ({title, tickets, type}) => {
  const classes = useStyles()
	const theme = useTheme();

	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		const dateCounts = tickets.reduce((accumulator, ticket) => {
			const { createdAt, closedAt } = ticket;
			const dateReport = type === 'created' ? createdAt : closedAt;
			const date = new Date(dateReport).toISOString().split("T")[0];
			accumulator[date] = (accumulator[date] || 0) + 1;
			return accumulator;
		}, {});
		
		const result = Object.entries(dateCounts).map(([date, amount]) => {
			return {
				date: format(startOfDay(parseISO(date)), "dd/MM/yyyy"),
				conversas: amount
			};
		});
		setChartData(result);
	}, [tickets]);

	return (
		<React.Fragment>
			<TitleChart>{title} {tickets.length}</TitleChart>
      {
        tickets.length > 0 &&
        <ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="date" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.secondary }}
						>
							Conversas
						</Label>
					</YAxis>
          <Tooltip />
					<Bar dataKey="conversas" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
      }
      {
        tickets.length < 1 &&
        <div className={classes.emptyResult}>
          <Typography style={{ color: textColorSecondary, fontSize: 18 }} gutterBottom>
            Nenhum resultado encontrado para o filtro.
          </Typography>
        </div>
      }
		</React.Fragment>
	);
};

export default BarChartTickets;
