import React, { useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ScheduleTable from "../ScheduleTable";
import { AddAlarm } from "@material-ui/icons";
import ScheduleModal from "../ScheduleModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		paddingRight: "4px",
		width: "50%"
	},
	textFieldColor: {
		paddingLeft: "4px",
		width: "50%"
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	select: {
		width: "100%",
		marginTop: "0px",
		marginBottom: "0px"
  },
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
		marginBottom: "10px"
	},
	button: {
		width: "200px"
	}
}));

const ScheduleConsultModal = ({ modalOpen, onClose, shiftId }) => {
	const { user: loggedInUser } = useContext(AuthContext);
	const classes = useStyles();

	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

	const monthsArray = [
		{ name: "Janeiro", value: 0	},
		{ name: "Fevereiro", value: 1	},
		{ name: "Março", value: 2	},
		{ name: "Abril", value: 3	},
		{ name: "Maio", value: 4	},
		{ name: "Junho", value: 5	},
		{ name: "Julho", value: 6	},
		{ name: "Agosto", value: 7	},
		{ name: "Setembro", value: 8	},
		{ name: "Outubro", value: 9	},
		{ name: "Novembro", value: 10	},
		{ name: "Dezembro", value: 11	},
	];

	const today = new Date();
	const currentYear = today.getFullYear();
	const yearArray = [
		{ value: currentYear-1 },
		{ value: currentYear },
		{ value: currentYear+1 }
	];

	const [months] = useState(monthsArray);
	const [years] = useState(yearArray);
	const [selectedMonth, setSelectedMonth] = useState(months[today.getMonth()]);
	const [selectedYear, setSelectedYear] = useState(years[1]);

	const handleOpenScheduleModal = () => {
    setScheduleModalOpen(true);
    setSelectedSchedule(null);
  };

	const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedSchedule(null);
  };

	const handleClose = () => {
		onClose();
	};

	return (
		<div className={classes.root}>
			<Dialog 
				open={modalOpen} 
				onClose={handleClose} 
				scroll="paper"
				fullWidth={true}>
				<ScheduleModal
					modalOpen={scheduleModalOpen}
					onClose={handleCloseScheduleModal}
					shiftId={shiftId}
					companyId={loggedInUser.companyId}
					scheduleId={selectedSchedule?.id}
				/>
					<DialogTitle>
						{i18n.t("sheduleConsultModal.title")}
					</DialogTitle>
					
						<DialogContent dividers>
							<div className={classes.multFieldLine}>
								<FormControl 
									variant="outlined" 
									className={classes.select}
									margin="dense">
									<InputLabel>{i18n.t("sheduleConsultModal.fieldMonth")}</InputLabel>
									<Select
										value={selectedMonth}
										onChange={(e) => setSelectedMonth(e.target.value)}
										label={i18n.t("sheduleConsultModal.fieldMonth")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{months.map((month) => (
											<MenuItem key={month.value} value={month}>{month.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl 
									variant="outlined" 
									className={classes.select}
									margin="dense">
									<InputLabel>{i18n.t("sheduleConsultModal.fieldYear")}</InputLabel>
									<Select
										value={selectedYear}
										onChange={(e) => setSelectedYear(e.target.value)}
										label={i18n.t("sheduleConsultModal.fieldYear")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{years.map((year) => (
											<MenuItem key={year.value} value={year}>{year.value}</MenuItem>
										))}
									</Select>
								</FormControl>
								<Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick={handleOpenScheduleModal}
									startIcon={<AddAlarm />}
								>
									Incluir
								</Button>
							</div>
							<ScheduleTable 
								shiftId={shiftId}
								month={selectedMonth}
								year={selectedYear}>
							</ScheduleTable>						
						</DialogContent>
			</Dialog>
		</div>
	);
};

export default ScheduleConsultModal;
