import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List, Tooltip, Typography } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Event from "@material-ui/icons/Event";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import ScheduleIcon from '@material-ui/icons/Schedule';
import BarChartIcon from '@material-ui/icons/BarChart';
import AppsIcon from '@material-ui/icons/Apps';
import BallotIcon from '@material-ui/icons/Ballot';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ListIcon from "@material-ui/icons/ListAlt";
import PeopleIcon from "@material-ui/icons/People";
import TuneIcon from '@material-ui/icons/Tune';
import AnnouncementIcon from '@material-ui/icons/Announcement';

const useStyles = makeStyles(theme => ({
	icon: {
		color: "#fff",
    minWidth: 50
	},
  listSubheader: {
    color: "#fff",
    fontSize: "0.8rem",
    fontWeight: "300",
    paddingLeft: 20,
    textTransform: "uppercase"
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.22)"
  },
  listItem: {
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.22)" }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.22)" }
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, className } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem className={classes.listItem} button component={renderLink}>
        {icon ?
        <Tooltip
          arrow
          placement="right"
          title={primary}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> 
        </Tooltip>: null}
        <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>{primary}</Typography>} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  
  const { drawerClose, drawerOpen, setDrawerOpen } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [openAdminSettings, setOpenAdminSettings] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  useEffect(() => {
    if (openCampaignSubmenu || openAdminSettings) {
      setDrawerOpen(true);
    }
  }, [openCampaignSubmenu, openAdminSettings]);

  useEffect(() => {
    if (!drawerOpen) {
      setOpenCampaignSubmenu(false);
      setOpenAdminSettings(false)
    }
  }, [drawerOpen]);

  const handleOpenAdminSettings = (e) => {
    e.stopPropagation();
    setOpenAdminSettings((prev) => !prev)
  }

  return (
    <div onClick={drawerClose}>
      <ListItemLink
        to="/"
        primary="Dashboard"
        icon={<BallotIcon />}
      />
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
      />
      <ListItemLink
        to="/agenda"
        primary={i18n.t("mainDrawer.listItems.agenda")}
        icon={<Event />}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider className={classes.divider}/>
            {drawerOpen ? 
              (<ListSubheader className={classes.listSubheader} inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>) : null
            }
            {/* <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <SyncAltIcon />
                </Badge>
              }
            /> */}
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon />}
            />
            {user.company?.companyPlan?.marketingModule && (
              <>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenCampaignSubmenu((prev) => !prev)
                  }}
                >
                  <Tooltip
                    arrow
                    placement="right"
                    title="Marketing"
                  >
                    <ListItemIcon className={classes.icon}>
                      <EventAvailableIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>{i18n.t("mainDrawer.listItems.campaigns")}</Typography>}
                  />
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon className={classes.icon}/>
                  ) : (
                    <ExpandMoreIcon className={classes.icon}/>
                  )}
                </ListItem>
                <Collapse
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem className={classes.nested} onClick={() => history.push("/campaigns")} button>
                      <ListItemIcon className={classes.icon}>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>Campanhas</Typography>} />
                    </ListItem>
                    <ListItem
                      className={classes.nested}
                      onClick={() => history.push("/contact-lists")}
                      button
                    >
                      <ListItemIcon className={classes.icon}>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>Listas de Contatos</Typography>} />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon />}
            />
            <ListItemLink
              to="/shifts"
              primary={i18n.t("mainDrawer.listItems.shifts")}
              icon={<ScheduleIcon />}
            />
            <ListItemLink
              to="/reports"
              primary={i18n.t("mainDrawer.listItems.reports")}
              icon={<BarChartIcon />}
            />
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
            />
            <ListItemLink
              to="/integrations"
              primary={i18n.t("mainDrawer.listItems.integrations")}
              icon={<AppsIcon />}
            />
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
              <Badge overlap="rectangular" badgeContent={connectionWarning ? "!" : 0} color="error">
              <SyncAltIcon />
              </Badge>
              }
            />
            {
              user.type !== 'customer' ?
                <>
                  <ListItem
                    className={classes.listItem}
                    button
                    onClick={(e) => handleOpenAdminSettings(e)}
                  >
                    <Tooltip
                      arrow
                      placement="right"
                      title="Gerenciar"
                    >
                      <ListItemIcon className={classes.icon}>
                        <TuneIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>{i18n.t("mainDrawer.listItems.adminSettings")}</Typography>}
                    />
                    {openAdminSettings ? (
                      <ExpandLessIcon className={classes.icon} />
                    ) : (
                      <ExpandMoreIcon className={classes.icon} />
                    )}
                  </ListItem>
                  <Collapse
                    in={openAdminSettings}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem className={classes.nested} onClick={() => history.push("/companies")} button>
                        <ListItemIcon className={classes.icon}>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>{i18n.t("mainDrawer.listItems.companies")}</Typography>} />
                      </ListItem>
                      <ListItem
                        className={classes.nested}
                        onClick={() => history.push("/alerts")}
                        button
                      >
                        <ListItemIcon className={classes.icon}>
                          <AnnouncementIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "0.9rem" }}>{i18n.t("mainDrawer.listItems.alerts")}</Typography>} />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
                 : <></>
            }
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
