
const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          companyName: 'Nome da empresa',
          cnpj: 'CNPJ'
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
        info: 'No momento não é possível cadastrar uma empresa. Para mais informações entre em contato com nosso atendimento. (47) 99229-0247',
        backToLogin: 'Voltar para o login'
      },
      login: {
        title: "Bem-Vindo ao Integgri Chat",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem uma conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Conversas hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Excluir conexão de WhatsApp",
          deleteMessage: "Essa ação vai desvincular a conexão com as conversas. Essa ação não pode ser revertida. Dica: Talvez você esteja apenas querendo desconectar o seu WhatsApp, e não excluir a conexão. Para isso, procure pelo botão DESCONECTAR.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          phoneNumber: "Número de telefone",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          number: "Número do telefone",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida",
          ticketReopenPeriod: "Reiniciar o chatbot após",
          ticketReopenPeriodTooltip: "Este será o tempo considerado para reativar o chatbot quando uma nova mensagem é recebida após a conversa ser resolvida."
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      templateModal: {
        title: "Enviar modelo",
        buttons: {
          send: "Enviar",
          cancel: "Cancelar",
        },
        success: "Modelo enviado com sucesso.",
      },
      TemplateVariavel: {
        title: {
         titleSimple: "Configurar mensagem",
         titleHeader: "Configurar Cabeçalho",
         titleBody: "Configurar Corpo",
        },
        alerts: {
          single: "Campo não preenchido",
          several: "Alguns campos não foram preenchidos.",
        },
      },
      workingHourModal: {
        title: "Horário de atendimento",
        form: {
          isEnabled: "Ativar horário de atendimento",
          awayMessage: "Mensagem de ausência"
        },
        buttons: {
          save: "Salvar",
          cancel: "Cancelar"
        },
        success: "Horário de atendimento salvo com sucesso."
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
          ticketExist: "Já existe uma conversa em andamento, para o usuário ",
          ticketPending: "Já existe uma conversa com o status Aguardando para este contato!",
          clickText: "Clique aqui"
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todas as conversas relacionadas serão perdidas.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        buttonsGroup: {
          buttonImportWhatsApps: "Importar do WhatsApp",
          buttonImportFile: "Importar por Arquivo",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          tags: "Etiquetas",
          actions: "Ações",
        },
        success: "Contatos importado com sucesso"
      },
      alerts: {
        title: "Alertas",
        toasts: {
          deleted: "Alerta excluído com sucesso!",
          edited: "Alerta editado com sucesso!",
          created: "Alerta criado com sucesso!"
        },
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteMessage:
            "Tem certeza que deseja deletar esse alerta?"
        },
        newAlertButton: "Novo alerta",
        table: {
          id: "ID",
          title: "Título",
          type: "Tipo",
          link: "Link",
          message: "Mensagem",
          companyName: "Empresa",
          createdAt: "Data criação",
          actions: "Ações"
        }
      },
      companies: {
        title: "Empresas",
        toasts: {
          deleted: "Empresa excluída com sucesso!",
          edited: "Empresa editada com sucesso!",
          created: "Empresa criada com sucesso!"
        },
        searchPlaceholder: "Pesquisar...",
        newCompanyButton: "Nova empresa",
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteMessage:
            "Tem certeza que deseja deletar esta empresa? Todas as referências desse registro serão apagadas."
        },
        table: {
          id: "ID",
          name: "Nome",
          cnpj: "CNPJ",
          createdAt: "Data criação",
          plan: "Plano",
          actions: "Ações"
        }
      },
      spyModal: {
        title: "Espiar"
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },

      fileContactModal: {
        title: "Importar Contatos",
        checked: "Sobrescreva dados existentes",
        buttons: {
          selectFile: "SELECIONAR ARQUIVO",
          cancel: "Cancelar",
          import: "Importar",
          linkDownload: "Baixar arquivo de exemplo",
        },
        tipsOnImportingFile: "Utilize a importação de planilhas nos formatos .xlsx, .xls, .csv",
        success: "Contatos importados com sucesso.",
      },

      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      rdSettingsModal: {
        title: "Configurações RD CRM",
        info: "Para obter o token, você precisará entrar na sua conta do RD Station CRM, acessar o perfil, e copiar o token da instância.",
        infoContact: "Ao ligar a chave 'Criar contato', todos os novos contatos que iniciarem uma nova conversa com sua empresa, serão cadastrados no RD Station CRM.",
        form: {
          token: "Token do RD Station CRM",
          contactCreation: "Criar contato"
        },
        buttons: {
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Configuração salva com sucesso.",
      },
      n8nSettingsModal: {
        title: "Configurações n8n",
        form: {
          webhook: "Webhook do workflow"
        },
        buttons: {
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Configuração salva com sucesso.",
      },
      shiftModal: {
        title: {
          add: "Adicionar plantão",
          edit: "Editar plantão"
        },
        fieldUserLabel: "Usuário",
        noOptions: "Nenhum usuário encontrado com esse nome"
      },
      queueModal: {
        title: {
          add: "Adicionar departamento",
          edit: "Editar departamento",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      scheduleModal: {
        title: {
          add: "Incluir horário",
          edit: "Editar horário",
        },
        form: {
          startDate: "Inicia em",
          endDate: "Termina em"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          canSeeHistory: "Permite ver histórico de conversas?",
          canSeeAllTickets: "Permite ver conversas de outros usuários?",
          canSeeTicketsWithoutAssignment: "Permite ver conversas aguardando sem atribuições?"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Departamentos",
      },
      ticketsUserSelect: {
        placeholder: "Usuários",
      },
      ticketsTagSelect: {
        placeholder: "Etiquetas"
      },
      ticketsConnectionSelect: {
        placeholder: "Conexões"
      },
      tagsContacts: {
        placeholder: "Etiquetas",
      },
      tickets: {
        toasts: {
          deleted: "O conversa que você estava foi deletada.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Minha Caixa" },
          closed: {
            title: "Resolvidos",
            badge: "Resolvido"
          },
          groups: "Grupos",
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Nome, número ou ID da conversa",
        },
        tagFilterPlaceholder: "Filtrar por etiquetas",
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldUserLabel: "Usuários",
        fieldQueueLabel: "Departamentos",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione um departamento",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        selectOneQueueOrUser: "Selecione pelo menos um usuário ou departamento",
        successTransferTicket: "Ticket transferido com sucesso!",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        spyTooltip: "Espiar",
        noTicketsMessage:
          "Nenhuma conversa encontrada com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
        },
        items: {
          spyButton: "Espiar",
          pinButton: "Fixar conversa",
          unpinButton: "Desafixar conversa",
          unreadButton: "Marcar como não lida"
        }
      },
      newTicketModal: {
        title: "Criar Conversa",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
        fieldConnectionLabel: "Selecione uma conexão",
        fieldConnectionPlaceholder: "Selecione uma conexão",
      },
      alertModal: {
        title: {
          add: "Adicionar alerta",
          edit: "Editar alerta",
        },
        form: {
          title: "Título",
          type: "Tipo",
          message: "Mensagem",
          link: "Link",
          companyName: "Empresa"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        }
      },
      newAgendaEventModal: {
        title: {
          add: "Adicionar evento",
          edit: "Editar evento",
        },
        form: {
          title: "Título",
          desc: "Descrição",
          allDay: "Dia inteiro",
          start: "Início",
          end: "Fim"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          delete: "Excluir"
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida!"
        }
      },
      forwardMessageModal: {
        title: "Encaminhar mensagem",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Encaminhar",
          cancel: "Cancelar",
        },
      },
      sendContactModal: {
        title: "Enviar contato",
        fieldLabel: "Digite para pesquisar o contato",
        buttons: {
          ok: "Enviar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Conversas",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          agenda: "Agenda",
          queues: "Departamentos",
          shifts: "Plantão",
          administration: "Administração",
          campaigns: "Marketing",
          users: "Usuários",
          settings: "Configurações",
          integrations: "Integrações",
          companies: "Empresas",
          reports: "Relatórios",
          adminSettings: "Gerenciar",
          alerts: "Alertas"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      contactLists: {
        title: "Listas de Contatos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Lista",
        },
        dialog: {
          name: "Nome",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
          success: "Lista salva com sucesso!"
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      contactListItems: {
        title: "Contatos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo",
          lists: "Listas",
          import: "Importar",
        },
        dialog: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
          importMessage: "Deseja importar os contatos desta planilha? ",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      campaigns: {
        title: "Campanhas",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Nova Campanha",
          contactLists: "Listas de Contatos",
        },
        table: {
          name: "Nome",
          whatsapp: "Conexão",
          contactList: "Lista de Contatos",
          status: "Status",
          scheduledAt: "Agendamento",
          completedAt: "Concluída",
          confirmation: "Confirmação",
          actions: "Ações",
        },
        form: {
          rejectedCampaignTitleTooltip: "Verifique a lista de contatos. É necessário que um ou mais contatos da lista sejam válidos."
        },
        dialog: {
          new: "Nova Campanha",
          update: "Editar Campanha",
          readonly: "Apenas Visualização",
          form: {
            name: "Nome",
            message1: "Mensagem 1",
            message2: "Mensagem 2",
            message3: "Mensagem 3",
            message4: "Mensagem 4",
            message5: "Mensagem 5",
            confirmationMessage1: "Mensagem de Confirmação 1",
            confirmationMessage2: "Mensagem de Confirmação 2",
            confirmationMessage3: "Mensagem de Confirmação 3",
            confirmationMessage4: "Mensagem de Confirmação 4",
            confirmationMessage5: "Mensagem de Confirmação 5",
            messagePlaceholder: "Conteúdo da mensagem",
            whatsapp: "Conexão",
            status: "Status",
            scheduledAt: "Agendamento",
            confirmation: "Confirmação",
            contactList: "Lista de Contato",
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar Disparos",
            restart: "Reiniciar Disparos",
            close: "Fechar",
            attach: "Anexar arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          cancel: "Campanha cancelada",
          restart: "Campanha reiniciada",
          deleted: "Registro excluído",
        },
      },
      queues: {
        title: "Departamentos",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar departamento",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas desse departamento continuarão existindo, mas não terão mais nenhum departamento atribuído.",
        },
      },
      sheduleConsultModal: {
        title: "Horários",
        fieldMonth: "Mês",
        fieldYear: "Ano",
        startDate: "Inicia em",
        endDate: "Termina em",
        actions: "Ações",
        scheduleModal: {
          edit: "Editar horário",
          add: "Incluir horário"
        },
        table: {
          startDate: "Inicia em",
          endDate: "Termina em",
          actions: "Ações"
        },
        confirmationModal: {
          deleteTitle: "Excluir horário?",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida!"
        }
      },
      shifts: {
        title: "Plantão",
        fieldQueueLabel: "Departamento",
        fieldQueuePlaceholder: "Selecione um departamento",
        confirmationModal: {
          deleteTitle: "Excluir plantão?",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida! Todos os horários também serão excluídos."
        },
        table: {
          user: "Usuário",
          queue: "Departamento",
          greeting: "Mensagem de saudação",
          actions: "Ações"
        },
        buttons: {
          add: "Adicionar plantão"
        }
      },
      queueSelect: {
        inputLabel: "Departamentos",
      },
      whatsappSelect: {
        inputLabel: "Conexões"
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          attachmentName: "Anexo",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      tags: {
        toasts: {
          deleted: "Etiqueta excluída com sucesso!",
        },
        buttons: {
          add: "Adicionar",
        },
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteMessage: "Tem certeza que deseja deletar esta etiqueta?",
        },
        tagsContainer: {
          placeholder: "Adicionar etiquetas"
        }
      },
      tagModal: {
        title: {
          add: "Adicionar etiqueta",
          edit: "Editar etiqueta",
        },
        buttons: {
          okAdd: "Salvar",
          okEdit: "Editar",
          cancel: "Cancelar",
        },
        form: {
          name: "Nome da etiqueta",
          color: "Cor da etiqueta"
        },
        success: "Etiqueta salva com sucesso!",
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapps: "Conexões",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
          queues: "Departamentos",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. As conversas abertas deste usuário serão movidas para o departamento.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          workingHour: {
            name: "Horário de atendimento"
          }
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Tecle ''/'' para respostas rápidas",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      copyToClipboard: {
        copy: "Copiar",
        copied: "Copiado"
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        farewellMesage: "Resolver sem enviar mensagem de despedida",
        confirmationModal: {
          title: "Deletar a conversa",
          titleFrom: "do contato ",
          message:
            "Atenção! Todas as mensagens relacionadas à conversa serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
          accept: "Sim",
        },
        resolveItemConfirmationModal: {
          title: "Resolver conversa",
          message:
            "Atenção! a conversa será resolvida. Deseja realmente resolver a conversa?",
        },
      },
      ticketsSelectMode: {
        select: "Selecionar conversas",
        selectAll: "Selecionar todos",
        cancel: "Cancelar seleção"
      },
      ticketsSelectModeCount: {
        selecteds: "Conversas selecionadas",
      },
      ticketsBulkSelectOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        accept: "Aceitar",
        resolve: "Resolver",
        return: "Retornar",
        farewellMesage: "Resolver sem enviar mensagem de despedida",
        toasts: {
          multiDeleted: "Conversas excluídas com sucesso",
          singleDeleted: "Conversa excluída com sucesso",
          multiResolve: "Conversas resolvidas com sucesso",
          singleResolve: "Conversa resolvida com sucesso",
          notItemSelected: "Nenhum Item selecionado"
        },
        zeroItensConfirmationModal: {
          title: "Nenhuma conversa selecionada",
          message: "Selecione alguma conversa para ser excluída",
        },
        oneItemConfirmationModal: {
          title: "Deletar conversa selecionada",
          message:
            "Atenção! Essa operação é irreversível. A conversa selecionada será deletada. Tem certeza que deseja fazer isso?",
        },
        multiItensConfirmationModal: {
          title: "Deletar todas as conversas selecionadas",
          message:
            "Atenção! Essa operação é irreversível. Todas as conversas selecionadas serão deletadas. Tem certeza que deseja fazer isso?",
        },
        acceptMultipleItensConfirmationModal: {
          title: "Aceitar todas as conversas selecionadas",
          message: "Atenção! Todas as conversas selecionadas serão aceitas. Tem certeza que deseja fazer isso?",
          buttons: {
            acceptAll: "Sim"
          }
        },
        acceptOneItemConfirmationModal: {
          title: "Aceitar Conversa Selecionada",
          message:
            "Atenção! A conversa selecionada será aceita. Tem certeza que deseja fazer isso?",
        },

        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
        oneResolveItemConfirmationModal: {
          title: "Resolver a conversa selecionada",
          message:
            "Atenção! a conversa selecionada será resolvida. Deseja realmente resolver a conversa selecionada?",
        },
        resolveMultiItensConfirmationModal: {
          title: "Resolver as conversas selecionadas",
          message:
            "Atenção! todas as conversas selecionadas serão resolvidas. Deseja realmente resolver todas as conversas selecionadas?",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        forward: "Encaminhar",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_PERMISSION_CREATE_COMPANY: "No momento a criação da empresa não está disponível por aqui. Contate o suporte.",
        ERR_NO_TAG_FOUND: "Etiqueta não encontrada",
        ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma etiqueta com esse nome.",
        ERR_LIMIT_USERS: "Limite máximo de usuários atingido. Entre em contato com o suporte.",
        ERR_LIMIT_QUEUES: "Limite máximo de departamentos atingido. Entre em contato com o suporte.",
        ERR_LIMIT_WHATSAPPS: "Limite máximo de conexões atingido. Entre em contato com o suporte.",
        ERR_NO_COMPANY_FOUND: "Empresa não encontrada",
        ERR_NO_COMPANY_PLAN_FOUND: "Plano da empresa não foi encontrado.",
        ERR_NO_PLAN_FOUND: "Não foi encontrado nenhum plano para essa empresa.",
        ERR_SHIFT_ALREADY_EXISTS: "Plantão já existe para este usuário e departamento.",
        ERR_SHIFT_SCHEDULE_ALREADY_EXISTS: "A data de inicio ou a data de término conflitam com alguma data já salva.",
        ERR_QUEUE_INVALID_COLOR: "Cor inválida.",
        ERR_QUEUE_NAME_ALREADY_EXISTS: "Já existe um departamento com este nome.",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_MESSAGE_FOUND: "Mensagem não encontrada.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
          ERR_INVALID_SPREADSHEET_COLUMNS_NAME:
          "Algumas colunas estão faltando, verifique se contém nome e número.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_SENDING_WAPP_MSG_MEDIA:
          "Erro ao enviar mídia. Entre em contato com o suporte.",
        SEND_MEDIA_DIGIT_ERROR:
          "Erro ao enviar mídia. Verifique se o número do contato possui o dígito 9.",
        ERR_INVALID_WID: "Erro ao enviar mensagem do WhatsApp. Entre em contato com o suporte.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_BULK_DELETE_TICKETS: "Não foi possível excluir as conversas selecionadas.",
        ERR_OTHER_OPEN_TICKET: "Já existe uma conversa aberta para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_SHORTCUT_DUPLICATED: 'Atalho duplicado.',
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_SETTING_KEY_ALREADY_EXIST: "A chave dessa configuração já existe. Escolha uma chave diferente.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhuma conversa encontrada com este ID.",
        ERR_NO_TICKETS_FOUND: "Nenhuma conversa encontrada com estes IDs.",
        ERR_TICKET_WITHOUT_PERMISSION: "Você não tem permissão para acessar essa conversa",
        ERR_BULK_RESOLVE_TICKETS: "Erro ao resolver conversas selecionadas",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatória quando há mais de um departamento.",
      },
    },
  },
};

export { messages };
