import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { Box, Checkbox, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { format } from "date-fns";
import { useEffect } from "react";
import ConfirmationModal from "../ConfirmationModal";
import { DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		paddingRight: "4px",
		width: "100%"
	},
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	customTextarea: {
		marginBottom: "30px",
	},
	boxContainer: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
		[theme.breakpoints.up("sm")]: {
			marginTop: 5
		},
	},
	boxRow1: {
		width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
	},
	textFieldStart: {
		width: "100%"
	},
	textFieldEnd: {
		width: "100%"
	},
}));

const AgendaEventSchema = Yup.object().shape({
	title: Yup.string().required("Obrigatório")
});

const AgendaEventModal = ({ open, onClose, selectedSlot }) => {
	const classes = useStyles();

	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	const initialState = {
		title: "",
		desc: "",
		allDay: false,
		start: format(new Date(), "yyyy-MM-dd hh:mm"),
		end: format(new Date(), "yyyy-MM-dd hh:mm")
	};
	const [agendaEvent, setAgendaEvent] = useState(initialState);

	const handleClose = () => {
		onClose();
	};

	const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
  };

	useEffect(() => {
		setAgendaEvent({
			title: selectedSlot?.title || "",
			desc: selectedSlot?.desc || "",
			allDay: selectedSlot?.allDay || false,
			start: selectedSlot ? format(selectedSlot.start, "yyyy-MM-dd HH:mm") : format(new Date(), "yyyy-MM-dd HH:mm"),
			end: selectedSlot ? format(selectedSlot.end, "yyyy-MM-dd HH:mm") : format(new Date(), "yyyy-MM-dd HH:mm")
		})
	// eslint-disable-next-line
	}, [open]);

	const handleSaveAgendaEvent = async values => {
		try {
			if (selectedSlot?.id) {
				await api.put(`/agendaEvent/${selectedSlot?.id}`, { ...values });
			} else {
				await api.post("/agendaEvent", { ...values });
			}
			toast.success("Evento salvo com sucesso");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleDeleteAgendaEvent = async () => {
		try {
			await api.delete(`/agendaEvent/${selectedSlot?.id}`);
			toast.success("Evento excluído com sucesso");
			handleClose();
		} catch(err) {
			toastError(err);
		}
	}

	return (
		<>
			<ConfirmationModal
        title={
          selectedSlot &&
          `${i18n.t("newAgendaEventModal.confirmationModal.deleteTitle")} ${
            selectedSlot.title
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteAgendaEvent(selectedSlot?.id)}
      >
        {i18n.t("newAgendaEventModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
			<Dialog open={open} onClose={handleClose} scroll="paper" fullWidth maxWidth="sm" >
				<DialogTitle>
					{selectedSlot?.id
						? `${i18n.t("newAgendaEventModal.title.edit")}`
						: `${i18n.t("newAgendaEventModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={agendaEvent}
					enableReinitialize={true}
					validationSchema={AgendaEventSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveAgendaEvent(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Grid container spacing={1}>
									<Grid item sm={9} xs={12}>
										<Field
											as={TextField}
											label={i18n.t("newAgendaEventModal.form.title")}
											autoFocus
											name="title"
											error={touched.title && Boolean(errors.title)}
											helperText={touched.title && errors.title}
											variant="outlined"
											margin="dense"
											className={classes.textFieldName}
										/>
									</Grid>
									<Grid item sm={3} xs={12}>
										<Box className={classes.boxContainer}>
											<Typography variant="subtitle1">{i18n.t("newAgendaEventModal.form.allDay")}</Typography>
											<Field
												as={Checkbox}
												checked={values.allDay || false}
												label={i18n.t("newAgendaEventModal.form.allDay")}
												autoFocus
												name="allDay"
												variant="outlined"
												margin="dense"
											/>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="desc"
											render={({ field, form }) => (
												<div style={{ position: "relative" }}>
													<TextField
														label={i18n.t("newAgendaEventModal.form.desc")}
														multiline
														minRows={4}
														fullWidth
														error={touched.desc && Boolean(errors.desc)}
														helperText={touched.desc && errors.desc}
														variant="outlined"
														margin="dense"
														{...field}
														InputProps={{
															classes: {
																input: classes.customTextarea,
															},
														}}
													/>
												</div>
											)}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Field
											as={TextField}
											label={i18n.t("newAgendaEventModal.form.start")}
											name="start"
											InputLabelProps={{
												shrink: true,
											}}
											type="datetime-local"
											error={touched.start && Boolean(errors.start)}
											helperText={touched.start && errors.start}
											variant="outlined"
											margin="dense"
											className={classes.textFieldStart}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Field
											as={TextField}
											label={i18n.t("newAgendaEventModal.form.end")}
											name="end"
											InputLabelProps={{
												shrink: true,
											}}
											type="datetime-local"
											error={touched.end && Boolean(errors.end)}
											helperText={touched.end && errors.end}
											variant="outlined"
											margin="dense"
											className={classes.textFieldEnd}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions style={{ paddingRight: 22, paddingLeft: 22}}>
								{ selectedSlot?.id && document.body.offsetWidth >= 600 &&
									<Button
										style={{ color: "#f44336", borderColor: "#f44336"}}
										disabled={isSubmitting}
										variant="outlined"
										onClick={() => {
											setConfirmModalOpen(true);
										}}
									>
										{i18n.t("newAgendaEventModal.buttons.delete")}
									</Button>
								}
								{ selectedSlot?.id && document.body.offsetWidth < 600 &&
									<IconButton
										disabled={isSubmitting}
										style={{ color: "#f44336", borderColor: "#f44336" }}
										onClick={() => {
											setConfirmModalOpen(true);
										}}
									>
										<DeleteOutline />
									</IconButton>
								}
								<div style={{marginLeft: "auto"}}>
									<Button
										style={{ marginRight: 6}}
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("newAgendaEventModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{selectedSlot?.id
											? `${i18n.t("newAgendaEventModal.buttons.okEdit")}`
											: `${i18n.t("newAgendaEventModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</div>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default AgendaEventModal;
