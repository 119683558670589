import React from "react";
import {
  makeStyles,
} from "@material-ui/core";
import ChatBubble from "@material-ui/icons/ChatBubble";

const useStyles = makeStyles(() => ({
	ctwaContext: {
    cursor: "pointer",
    textDecoration: "none"
  },
	ctwaContextHeader: {
		display: "flex",
		alignItems: "center",
		fontStyle: "italic",
		fontSize: "13px",
		color: "#8696a0",
		marginBottom: "4px"
	},
  ctwaContextImg: {
		width: "100%",
		height: 200,
		objectFit: "cover",
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5
	},  
  ctwaContextImgContainer: {
		lineHeight: 0
	},
  ctwaContextFooter: {
		backgroundColor: "#f5f6f6",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5
	},
  ctwaContextTitle: {
		color: "rgba(17,27,33,0.87)"
	},
  ctwaContextLink: {
		fontSize: 11,
		color: "rgba(17,27,33,0.6)"
	},
}));

export default function({ url, title, thumbnailUrl }) {
	const classes = useStyles();

	return (
		<>
			<div className={classes.ctwaContextHeader}>
				<ChatBubble style={{ fontSize: "1rem", marginRight: "3px" }}/>
				Recebida via anúncio
			</div>
			<a href={url} target="_blank" className={classes.ctwaContext}>
				<div className={classes.ctwaContextImgContainer}>
					<img className={classes.ctwaContextImg} src={thumbnailUrl} />
				</div>
				<div className={classes.ctwaContextFooter}>
					<div className={classes.ctwaContextTitle}>{title}</div>
					<div className={classes.ctwaContextLink}>{url}</div>
				</div>
			</a>
		</>
	);
} 