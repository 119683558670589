import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import IntegrationCard from "../../components/IntegrationCard";
import logoRdStation from "../../assets/rd-station-logo.png";
import logoN8n from "../../assets/logo-n8n.png";
import RdSettingsModal from "../../components/RdSettingsModal";
import N8nSettingsModal from "../../components/N8nSettingsModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "100%"
  },
}));

const Integrations = () => {
  const classes = useStyles();

  const [rdSettingsModalOpen, setRdSettingsModalOpen] = useState(false);
  const [n8nModalOpen, setN8nModalOpen] = useState(false);

  const handleCloseRdSettingsModal = () => {
    setRdSettingsModalOpen(false);
  };

  const handleCloseN8nSettingsModal = () => {
    setN8nModalOpen(false);
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>Integrações</Title>
      </MainHeader>
      <RdSettingsModal
        open={rdSettingsModalOpen}
        onClose={handleCloseRdSettingsModal}
        aria-labelledby="form-dialog-title"
      ></RdSettingsModal>
      <N8nSettingsModal
        open={n8nModalOpen}
        onClose={handleCloseN8nSettingsModal}
        aria-labelledby="form-dialog-title"
      ></N8nSettingsModal>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <IntegrationCard 
          title="RD Station CRM"
          body="Com essa integração, os seus clientes são integrados automaticamente ao RD Station CRM"
          logo={logoRdStation}
          onSettingsClick={() => setRdSettingsModalOpen(true)}
        />
        <IntegrationCard 
          title="n8n"
          body="Com essa integração, cada mensagem recebida pode ser incluída num workflow do n8n"
          logo={logoN8n}
          onSettingsClick={() => setN8nModalOpen(true)}
        />

      </Paper>
    </MainContainer>
  );
};

export default Integrations;
