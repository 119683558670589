import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import alertSound from "../../assets/sound2.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Tooltip } from "@material-ui/core";
import { TicketsActionsProvider } from "../../context/TicketsActionsContext/TicketsActionsContext";
import { shouldNotifyPopOver } from "../../helper/helper";
import useTicketsNotifications from "../../hooks/useTicketsNotifications";
import openSocket from "../../services/socket-io";

const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 500,
        ...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 600,
		marginLeft: theme.spacing(5),
		marginRight: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			maxWidth: 270,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
}));

const LS_NAME = 'audioVolume';

const NotificationsPopOver = () => {
	const classes = useStyles();

	const history = useHistory();
	const { user } = useContext(AuthContext);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const [, setDesktopNotifications] = useState([]);

	const { tickets } = useTicketsNotifications();

	const [play, { stop }] = useSound(alertSound, { volume: parseFloat(localStorage.getItem(LS_NAME) || "0.5") });
	const soundAlertRef = useRef();

	const historyRef = useRef(history);

	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	useEffect(() => {
		const socket = openSocket();
		socket.on("connect", () => {
			socket.emit(`${user.companyId}:joinNotification`)
		});

		socket.on(`ticket${user.companyId}`, data => {
			if (data.action === "updateUnread" || data.action === "delete") {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		socket.on(`appMessage${user.companyId}`, data => {
			const { ticket, message, action } = data;
			if (action === "create" && !message.read &&
				shouldNotifyPopOver(ticket, user)) {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === ticket.id);
					if (ticketIndex !== -1) {
						prevState[ticketIndex] = ticket;
						return [...prevState];
					}
					return [ticket, ...prevState];
				});

				const shouldNotNotificate =
					(message.ticketId === ticketIdRef.current &&
						document.visibilityState === "visible") ||
					(ticket.userId && ticket.userId !== user?.id) ||
					ticket.isGroup;

				if (shouldNotNotificate) return;

				handleNotifications(data);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

	const handleNotifications = data => {
		const { message, contact, ticket } = data;
		const statusTicket = ticket.status === "pending" ? "(Aguardando)" : "(Atendendo)";

		const options = {
			body: message.mediaType === "chat" ? message.body.substring(0,59) : "Mídia Recebida",
			icon: "/96x96logo.png",
			tag: ticket.id,
			renotify: true,
		};

		const notification = new Notification(
			`${contact.name} ${statusTicket}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			if (ticket.status === "pending") {
				historyRef.current.push("/tickets");
			} else {
				historyRef.current.push(`/tickets/${ticket.id}`);
			}
		};

		setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		});

		stop();
		soundAlertRef.current({ volume: parseFloat(localStorage.getItem(LS_NAME) || "0.5") });
	};

	const handleClick = () => {
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		return <div onClick={handleClickAway}>{children}</div>;
	};

	return (
		<>
			<Tooltip
      	arrow
      	placement="top"
      	title="Notificações"
    	>
				<IconButton
					onClick={handleClick}
					ref={anchorEl}
					aria-label="Open Notifications"
					color="inherit"
				>
					<Badge overlap="rectangular" badgeContent={notifications.length} color="secondary">
						<ChatIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<TicketsActionsProvider>
					<List dense className={classes.tabContainer}>
						{notifications.length === 0 ? (
							<ListItem>
								<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
							</ListItem>
						) : (
							notifications.map(ticket => (
								<NotificationTicket key={ticket.id}>
									<TicketListItem ticket={ticket} isNotificationList={true}/>
								</NotificationTicket>
							))
						)}
					</List>
				</TicketsActionsProvider>
			</Popover>
		</>
	);
};

export default NotificationsPopOver;