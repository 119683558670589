import React, { useState, useEffect, useContext } from "react";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ScheduleModal from "../ScheduleModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textFieldName: {
		paddingRight: "4px",
		width: "50%"
	},
	textFieldColor: {
		paddingLeft: "4px",
		width: "50%"
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	select: {
		width: "100%",
		marginTop: "0px",
		marginBottom: "0px"
  },
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
		marginBottom: "10px"
	},
	button: {
		width: "200px"
	}
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const ShiftModal = ({ modalOpen, onClose, shiftId }) => {
	const { user: loggedInUser } = useContext(AuthContext);
	const classes = useStyles();

	const [searchParam, setSearchParam] = useState("");
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [queues, setQueues] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [selectedQueue, setSelectedQueue] = useState({});
	const [greetingMessage, setGreetingMessage] = useState("");
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  	const [selectedSchedule, setSelectedSchedule] = useState(null);

	const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedSchedule(null);
  };

	useEffect(() => {
		(async () => {
			if (!shiftId) {
				setSelectedUser({ name: ""});
				setSelectedQueue("");
				setGreetingMessage("");
			} else {
				try {
					const { data } = await api.get(`/shift/${shiftId}`);
					setOptions([data.user]);
					setSelectedUser(data.user);
					setQueues(data.user.queues);
					const queue = data.user.queues.find(q => {
						return q.id === data.queue.id;
					});
					setSelectedQueue(queue);
					setGreetingMessage(data.greetingMessage);
				} catch (err) {
					toastError(err);
				}
			}
		})();

	}, [shiftId, modalOpen]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});
					setOptions(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen, loggedInUser.companyId]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
	};

	const handleSaveShift = async e => {
		e.preventDefault();
		setLoading(true);
		try {
			let data = {};

			if (!selectedUser || selectedUser.name === "") {
				toast.error("Selecione um usuário.");
				setLoading(false);
				return;
			}
			
			if (!selectedQueue) {
				toast.error("Selecione um departamento.");
				setLoading(false);
				return;
			}
			
			data.companyId = loggedInUser.companyId;
			data.userId = selectedUser.id
			data.queueId = selectedQueue.id;
			data.greetingMessage = greetingMessage;

			if (shiftId) {
				await api.put(`/shift/${shiftId}`, data);
			} else {
				await api.post("/shift", data);
			}
			toast.success("Plantão salvo com sucesso!");
			setLoading(false);
			handleClose();
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog 
				open={modalOpen} 
				onClose={handleClose} 
				scroll="paper"
				fullWidth={true}>
				<ScheduleModal
					modalOpen={scheduleModalOpen}
					onClose={handleCloseScheduleModal}
					shiftId={shiftId}
					companyId={loggedInUser.companyId}
					scheduleId={selectedSchedule?.id}
				/>
				<form onSubmit={handleSaveShift}>
					<DialogTitle>
						{shiftId
							? `${i18n.t("shiftModal.title.edit")}`
							: `${i18n.t("shiftModal.title.add")}`}
					</DialogTitle>
					<DialogContent dividers>
						<Autocomplete
							style={{ width: "100%", marginBottom: 20 }}
							getOptionLabel={option => `${option.name}`}
							onChange={(e, newValue) => {
								setSelectedUser(newValue);
								if (newValue != null && Array.isArray(newValue.queues)) {
									setQueues(newValue.queues);
								} else {
									setQueues([]);
									setSelectedQueue(null);
								}
							}}
							value={selectedUser}
							options={options}
							filterOptions={filterOptions}
							freeSolo
							autoHighlight
							noOptionsText={i18n.t("shiftModal.noOptions")}
							loading={loading}
							renderInput={params => (
								<TextField
									{...params}
									label={i18n.t("shiftModal.fieldUserLabel")}
									variant="outlined"
									autoFocus
									onChange={e => setSearchParam(e.target.value)}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
						<FormControl variant="outlined" className={classes.select}>
							<InputLabel>{i18n.t("shifts.fieldQueueLabel")}</InputLabel>
							<Select
								style={{ marginBottom: 15 }}
								value={selectedQueue}
								onChange={(e) => setSelectedQueue(e.target.value)}
								label={i18n.t("shifts.fieldQueuePlaceholder")}
							>
								<MenuItem value={''}>&nbsp;</MenuItem>
								{queues.map((queue) => (
									<MenuItem key={queue.id} value={queue}>{queue.name}</MenuItem>
								))}
							</Select>
						</FormControl>
						<div>
							<TextField
								label={i18n.t("queueModal.form.greetingMessage")}
								type="greetingMessage"
								multiline
								value={greetingMessage}
								minRows={5}
								onChange={(e) => {
									setGreetingMessage(e.target.value);
								}}
								fullWidth
								variant="outlined"
								margin="dense"
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("queueModal.buttons.cancel")}
						</Button>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							className={classes.btnWrapper}
						>
							{shiftId
								? `${i18n.t("queueModal.buttons.okEdit")}`
								: `${i18n.t("queueModal.buttons.okAdd")}`}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
};

export default ShiftModal;
