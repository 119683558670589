import * as firebase from "firebase/app";
import * as firebaseMessaging from "firebase/messaging";

import { getFirebaseConfig, getVapidKey } from "./config";
import api from "./services/api";

async function initializeFirebase() {
    const firebaseConfig = await getFirebaseConfig();
    firebase.initializeApp(firebaseConfig);
}

async function getNotificationToken(userId) {
    let currentToken = 'noToken';
    if (Notification.permission === 'granted') {
        try {
            const messaging = firebaseMessaging.getMessaging();
            currentToken = await firebaseMessaging.getToken(messaging, { vapidKey: getVapidKey() });
            if (currentToken !== 'noToken') {
                if (userId) {
                    api.put('/updatetokenfcm', { tokenFcm: currentToken }).then(() => {
                        console.log(currentToken);
                    }, err => {
                        console.log('Error trying to save Notification Token ', err);
                    });
                }
            } else {
                console.log('Error getting Notification Token. Request Permission Again');
                await getNotificationToken(userId);
            }        
        } catch (error) {
            console.log('An error occurred when requesting to receive the token.', error);
            await getNotificationToken(userId);
        }
    }
    return currentToken;
}

async function deleteNotificationToken() {
    const messaging = firebaseMessaging.getMessaging();
    const response = await firebaseMessaging.deleteToken(messaging);
    return response;
}

async function requestPermission() {
    const permission = await Notification.requestPermission();
    return permission;
}

function onMessageListener() {
    // This method, will receive notifications when the app is maximized
    return new Promise(resolve => {
        const messaging = firebaseMessaging.getMessaging();
        firebaseMessaging.onMessage(messaging, payload => {
            console.log('Mensagem em primeiro plano: ', payload);
            resolve(payload);
        });
    });
}

export { initializeFirebase, getNotificationToken, deleteNotificationToken, requestPermission, onMessageListener }