import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { Chip } from "@material-ui/core";
import { TagsContacts } from "../../components/TagsContacts";
import ModalContactsFile from "../../components/ModalContactsFile";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  icons: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "20px",
    height: "auto",
  },

  menu: {
    marginTop: theme.spacing(6), 
    marginLeft: "-11px"
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [withoutTag, setWithoutTag] = useState(false);
  const [openModalXlsx, setOpenModalFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleImportWhatsApp = () => {
    setConfirmOpen(true);
    handleMenuClose();
  };

  const handleImportXLSX = () => {
    setOpenModalFile(true);
    handleMenuClose();
  };

  const handleCloseModalFile = () => {
    setOpenModalFile(false);
    setContactModalOpen(false);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, withoutTag, selectedTags]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber, tagIds: selectedTags, withoutTag },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, user.companyId, selectedTags, withoutTag]);

  useEffect(() => {
    const socket = openSocket();

    socket.on(`contact${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticketExist } = await api.get(`/tickets/getTicketByContactId/${contactId}`);

      if (ticketExist) {
        let message = "";
        if (ticketExist.status === "pending") {
          message = i18n.t(`contacts.toasts.ticketPending`);
          toast.error(message);
        } else {
          const userCanOpenTicket = user.queues.filter((q) => q.id === ticketExist.queueId).length > 0;
          const toastClick = () => {
						if ((user.profile.toUpperCase() === "ADMIN" )
              || (user.canSeeAllTickets && userCanOpenTicket)) {
              history.push(`/tickets/${ticketExist.id}`);
            }
          };

          message = i18n.t(`contacts.toasts.ticketExist`)
            + `${ticketExist.user ? ticketExist.user.name + '. ' : ''}`;
					if ((user.profile.toUpperCase() === "ADMIN" )
            || (user.canSeeAllTickets && userCanOpenTicket)) {
            message = message + i18n.t(`contacts.toasts.clickText`);
          }

          toast.error(message, {
            onClick: toastClick,
          });
        }
      } else {
        const { data: ticket } = await api.post("/tickets", {
          contactId: contactId,
          status: "open",
          whatsappId: user?.whatsapps?.length > 0 ? user?.whatsapps[0].id : null
        });
        history.push(`/tickets/${ticket.id}`);
      }
    } catch (err) {
      toast.error(i18n.t(`backendErrors.${err}`), {
        toastId: err
      });
    }
    setLoading(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`, {
        data: {
          companyId: user.companyId
        }
      });
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      toast.success(i18n.t("contacts.success"));
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleSelectTags = async (tags) => {
    const filtered = tags.filter(tag => tag.id !== "withoutTag");
    setSelectedTags(filtered.map(f => f.id));
    setWithoutTag(!!tags.find(t => t.id === "withoutTag"))
  }

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        contactId={selectedContactId}
      />
      <ModalContactsFile
        open={openModalXlsx}
        onClose={handleCloseModalFile}
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
            }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            variant="outlined"
            size="small"
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <TagsContacts
            onSelectTags={handleSelectTags} isWithoutTag={true} size={"small"} width={"300px"}
          />
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={handleOpenContactModal}>{i18n.t("contacts.buttons.add")}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={anchorEl ? 'split-button-menu' : undefined}
              aria-expanded={anchorEl ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleMenuClick}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Menu
            id="split-button-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            className={classes.menu}
          >
            <MenuItem onClick={handleImportWhatsApp}>
              <WhatsAppIcon className={classes.icons} />
              <Typography variant="inherit" style={{ marginLeft: "30px" }}>
                {i18n.t("contacts.buttonsGroup.buttonImportWhatsApps")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleImportXLSX}>
              <AttachFileIcon className={classes.icons} />
              <Typography variant="inherit" style={{ marginLeft: "30px" }}>
              {i18n.t("contacts.buttonsGroup.buttonImportFile")}
              </Typography>
            </MenuItem>
          </Menu>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.tags")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.tags.map((tag) => {
                    return <Chip
                      key={tag.id}
                      style={{ backgroundColor: tag.color || '#eee', marginRight: 3 }}
                      label={tag.name}
                      size="small"
                    />
                  })}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => hadleEditContact(contact.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <Can
                        role={user.profile}
                      perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                          >
                          <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;