import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";

import {
  makeStyles,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
  CircularProgress,
  Button,
  DialogActions,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}));

const RdSettingsModal = ({
  open,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [apiTokenRd, setApiTokenRd] = useState("");
  const [contactCreationRd, setContactCreationRd] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      return;
    } 
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/settings/rd`);
        const { apiTokenRd, contactCreationRd } = data;
        if (isMounted.current) {
          setApiTokenRd(apiTokenRd.value);
          setContactCreationRd(contactCreationRd.value === "true" ? true : false);
        }
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };

    fetchSettings();
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSaveRdSettings = async () => {
    setLoading(true);
    try {
      const { data } = await api.post("/settings/rd", { apiTokenRd, contactCreationRd });
      if (onSave) {
        onSave(data);
      }
      handleClose();
      setLoading(false);
      toast.success(i18n.t("rdSettingsModal.success"));
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t("rdSettingsModal.title")}
        </DialogTitle>
        <DialogContent dividers>
          <Alert
            severity="info"
            style={{ marginBottom: 7 }}>
            {`${i18n.t("rdSettingsModal.info")}`}
          </Alert>
          <Alert
            severity="info"
            style={{ marginBottom: 7 }}>
            {`${i18n.t("rdSettingsModal.infoContact")}`}
          </Alert>
          <TextField
						variant="outlined"
						margin="dense"
						size="small"
						fullWidth
            onChange={(e) => setApiTokenRd(e.target.value)}
            value={apiTokenRd}
            label={i18n.t("rdSettingsModal.form.token")}/>
          <FormControlLabel
              style={{ marginRight: 7, color: "gray" }}
              label={i18n.t("rdSettingsModal.form.contactCreation")}
              control={
                <Switch
                  checked={contactCreationRd}
                  onChange={(e) => {
                    setContactCreationRd(e.target.checked);
                  }}
                  name="contactCreationRd"
                  color="primary"
                />
              }
            />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            {i18n.t("rdSettingsModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            onClick={() => handleSaveRdSettings()}
          >
            {i18n.t("rdSettingsModal.buttons.okEdit")}
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RdSettingsModal;
