import React, { useState, createContext } from "react";

const SendMediaContext = createContext();

const SendMediaProvider = ({ children }) => {
	const [sendMedia, setSendMedia] = useState();

	return (
		<SendMediaContext.Provider
			value={{
				sendMedia,
				setSendMedia,
			}}
		>
			{children}
		</SendMediaContext.Provider>
	);
};

export { SendMediaContext, SendMediaProvider };