import React, { useState } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import 'date-fns/locale/pt-BR';
import { ptBR } from 'date-fns/locale'
import AgendaEventModal from "../../components/AgendaEventModal";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { useReducer, useEffect, useContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Skeleton from "@material-ui/lab/Skeleton";
import { Fab, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import openSocket from "../../services/socket-io";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "100%"
  },
  floatingActionButton: {
    right: 60,
    bottom: 56,
    zIndex: 5,
    position: "fixed"
  }
}));

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const messages = {
  allDay: 'Dia Inteiro',
  previous: '<',
  next: '>',
  today: 'Hoje',
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'Não existem eventos nesse período.',
  showMore: (total) => `+ (${total}) Eventos`
}

const reducer = (state, action) => {
  if (action.type === "LOAD_AGENDA_EVENTS") {
    const events = action.payload;
    const newEvents = [];

    events.forEach((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end)
      const eventIndex = state.findIndex((q) => q.id === event.id);
      if (eventIndex !== -1) {
        state[eventIndex] = event;
      } else {
        newEvents.push(event);
      }
    });

    return [...state, ...newEvents];
  }

  if (action.type === "UPDATE_AGENDA_EVENTS") {
    const event = action.payload;
    event.start = new Date(event.start);
    event.end = new Date(event.end);
    const eventIndex = state.findIndex((u) => u.id === event.id);

    if (eventIndex !== -1) {
      state[eventIndex] = event;
      return [...state];
    } else {
      return [event, ...state];
    }
  }

  if (action.type === "DELETE_AGENDA_EVENT") {
    const agendaEventId = action.payload;
    const agendaEventIndex = state.findIndex((e) => e.id === agendaEventId);
    if (agendaEventIndex !== -1) {
      state.splice(agendaEventIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const getDateMax = () => {
  const date = new Date();
  date.setHours(23,0,0);
  return date;
}

const getDateMin = () => {
  const date = new Date();
  date.setHours(5,0,0);
  return date;
}

const Agenda = () => {
  const classes = useStyles();
  const [agendaEventOpen, setAgendaEventOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agendaEvents, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);

  const handleOpenModal = (slot) => {
    setSelectedSlot(slot);
    setAgendaEventOpen(true);
  }

  const HandleOnRangeChange= async (value) => {
    const { start, end } = value;
    if (start && end) {
      const { data } = await api.post("/listAgendaEvent", { start, end });
      dispatch({ type: "LOAD_AGENDA_EVENTS", payload: data });
    }
  }

  // const Event = (props) => {
  //   return (
  //     <div onContextMenu={(e) => handleOnContextMenu(e)}>
  //       <div >{props.title}</div>
  //     </div>
  //   )
  // }

  const HandleOnView= (value) => {
    console.log("onView: " + JSON.stringify(value));
  }

  const handleCloseAgendaEventModal = () => {
    setAgendaEventOpen(false);
    setSelectedSlot(null)
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const start = startOfMonth(new Date());
        const end = endOfMonth(new Date());
        const { data } = await api.post("/listAgendaEvent", { start, end });
        dispatch({ type: "LOAD_AGENDA_EVENTS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on(`agendaEvent${user.companyId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_AGENDA_EVENTS", payload: data.agendaEvent });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_AGENDA_EVENT", payload: data.agendaEventId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user.companyId]);

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>Agenda</Title>
      </MainHeader>
      <AgendaEventModal
        selectedSlot={selectedSlot}
        open={agendaEventOpen}
        onClose={handleCloseAgendaEventModal}
      />
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        {
          !loading &&
          <Calendar
            style={{ height: '100%', width: '100%' }}
            messages={messages}
            selectable
            culture={"pt-BR"}
            localizer={localizer}
            events={agendaEvents}
            max={getDateMax()}
            min={getDateMin()}
            onSelectSlot={(slot) => handleOpenModal(slot)}
            onSelectEvent={(event) => handleOpenModal(event)}
            onRangeChange={(value) => HandleOnRangeChange(value)}
            onView={(value) => HandleOnView(value)}
            startAccessor="start"
            endAccessor="end"
          />
        }
        {loading && <Skeleton animation="wave"/>}
        <Tooltip
          arrow
          placement="right"
          title="Novo evento"
        >
          <Fab
            className={classes.floatingActionButton}
            size="large"
            color="primary"
            aria-label="add"
            onClick={() => setAgendaEventOpen(true)}>
            <AddIcon />
          </Fab>

        </Tooltip>
      </Paper>
    </MainContainer>
  );
};

export default Agenda;
