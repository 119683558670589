import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import {
  Grid,
  useMediaQuery,
  Button,
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Avatar from "@material-ui/core/Avatar";
import { PictureAsPdf, InsertDriveFile } from '@material-ui/icons';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import { QuickAnswerContext } from "../../context/QuickAnswer/QuickAnswerContext";
import TemplatesModal from "../TemplatesModal";
import { SendMediaContext } from "../../context/SendMediaContext/SendMediaContext"
import { DragFileContext } from "../../context/DragFileContext/DragFileContext";
import SendContactModal from "../SendContactModal";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BannerModal from "../BannerModal";
import integgriPayBanner from "../../assets/integgri-pay-banner.jpg";

const Mp3Recorder = new MicRecorder({ bitRate: 256 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",  // // "aplica cor atrás da barra de escrita do chat, testar com cor aleatória para perceber, junção da cor da barra de escrita."
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // // borderTop: "40px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: "#eee",  // // aplica cor na barra inferior do chat, da para colocar borda se trocar a cor do fundo //
    width: "100%",
    display: "flex",
    padding: "3px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 5,
    marginRight: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 8,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "#483D8B",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",

  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#6747D7",
    padding: 10,

  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1.5,
    paddingTop: 8,
    paddingLeft: 25,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 3,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    alignItems: "center",
  },

  replyginMsgBody: {
    padding: 10,
    margin: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginVcardPic: {
    margin: 5,
    height: 90,
    width: 90,
    display: "flex",
    marginLeft: "auto"
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "5px",
    height: 100,
    backgroundColor: "#6747D7",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "5px",
    height: 100,
    backgroundColor: "#6747D7",
  },

  messageContactName: {
    display: "flex",
    color: "#6747D7",
    fontWeight: 500,
  },

  inputTemplateWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    flexDirection: "column"
  },

  sendMediaWrapper: {
    background: "#eee",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1.5,
    paddingTop: 8,
    paddingLeft: 25,
    paddingRight: 7,
  },

  sendMediaContainer: {
    flex: 1,
    marginRight: 3,
    overflowY: "auto",
    ...theme.scrollbarStyles,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    alignItems: "center",
  },

  sendMediaIcon: {
    color: "#483D8B",
  },
  listItemIcon: {
    minWidth: 40
  }
}));

const MessageInput = ({ ticket, isLastMessageWithin24hours }) => {
  const classes = useStyles();
  const { status: ticketStatus, whatsapp } = ticket || {};
  const { ticketId } = useParams();

  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOptions, setAnchorElOptions] = useState(null);
  const { setReplyingMessage, replyingMessage, replyingVcardMessage } = useContext(ReplyMessageContext);
  const { setSendMedia, sendMedia } = useContext(SendMediaContext);
  const { setDragFile, dragFile } = useContext(DragFileContext);
  const { setPressedKey, quickAnswer, setQuickAnswer, typeBar, setTypeBar, quickAnswerAttachment, setQuickAnswerAttachment } = useContext(QuickAnswerContext);
  const { user } = useContext(AuthContext);
  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const isMobile = useMediaQuery('(max-width:600px)');
  const iconMobile = isMobile ? true : false;
  const [isSendContactModalOpen, setIsSendContactModalOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState(false);
  const [bannerModalOpen, setBannerModalOpen] = useState(false);

  useEffect(() => {
    if (sendMedia) {
      setMedias(prevFiles => [
        ...prevFiles,
        ...Object.entries(sendMedia).map(([key, value]) => value)
      ]);
    }
  }, [sendMedia]);

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
      setTypeBar(null);
    };
  }, [ticketId, setReplyingMessage]);

  useEffect(() => {
    if (quickAnswer) {
      setInputMessage(quickAnswer.message);
      setQuickAnswer(null);
    }
  }, [quickAnswer]);

  const handleKeyPress = (e) => {
    const pressedKey = e.key;
    if (typeBar) {
      setPressedKey(pressedKey);
    }
    if (typeBar && (pressedKey === "ArrowUp" || pressedKey === "ArrowDown")) {
      e.preventDefault();
    }
    if (pressedKey === "Enter" && !typeBar && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }

  const handleChangeInput = (e) => {
    const { value } = e.target;
    setInputMessage(value);
    setTypeBar(value.indexOf("/") === 0 ? value : null);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    handleCloseMenuOptions();
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(prevFiles => [
      ...prevFiles,
      ...selectedMedias
    ]);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    // setLoading(true);
    setInputMessage("");

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
      quickAnswerId: quickAnswerAttachment?.id
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setQuickAnswerAttachment(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      const audioContext = new AudioContext({ sampleRate: 44100 });
      const source = audioContext.createMediaStreamSource(stream);

      await audioContext.audioWorklet.addModule('/audio-processor.js');
      const audioWorkletNode = new AudioWorkletNode(audioContext, 'my-audio-processor');

      const silentGainNode = audioContext.createGain();
      silentGainNode.gain.value = 0;
      
      source.connect(audioWorkletNode);
      audioWorkletNode.connect(silentGainNode);
      silentGainNode.connect(audioContext.destination);
      
      await Mp3Recorder.start();
      setRecording(true);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const handleOpenTemplateModal = () => {
    setTemplateModalOpen(true);
  };

  const handleCloseTemplateModal = useCallback(() => {
    setTemplateModalOpen(false);
  }, [setTemplateModalOpen]);

  const handleRemoveFile = (fileToRemove, all) => {
    if (all) {
      setMedias([]);
      setSendMedia(null);
    }
    else {
      setMedias(prevFiles => {
        const updatedFiles = prevFiles.filter(file => file !== fileToRemove);
        if (updatedFiles.length === 0) {
          setSendMedia(null);
        }
        return updatedFiles;
      });
    }
  }

  const handleOpenSendContactModal = () => {
    handleCloseMenuOptions();
    setIsSendContactModalOpen(true);
  };

  const handleCloseSendContactModal = () => {
    setIsSendContactModalOpen(false);
  };

  const handleOpenMenuOptions = (event) => {
    setAnchorElOptions(event.currentTarget);
    setMenuOptions(true);
  };

  const handleCloseMenuOptions = () => {
    setAnchorElOptions(null);
    setMenuOptions(false);
  };

  const renderReplyingMessage = (message) => {
    let vCardProfilePicUrl = null;
    let fnValue = "";
    if (message.mediaType === "vcard") {
      let bodyArray = message.body.split("\n");
      const filterFnValue = bodyArray.filter((el) => el.includes("FN:"));
      fnValue = filterFnValue[0]?.split("FN:")[1] || null;

      const filteredVcardId = replyingVcardMessage.find(obj => obj.messageId === message.id)
      if (filteredVcardId) {
        vCardProfilePicUrl = filteredVcardId.profilePicUrl;
      }
    }
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.mediaType === "chat" ? (
              message.body
            ) : (
              fnValue
            )}
          </div>
          {message.mediaType === "vcard" ? (
            <Avatar variant="square" src={vCardProfilePicUrl} className={classes.replyginVcardPic} />
          ) : (null)
          }
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };


  const renderPreview = (file) => {
    const previewStyle = { maxWidth: iconMobile === true ? '40px' : '70px', maxHeight: iconMobile === true ? '40px' : '70px' };

    if (file.type.startsWith('image/')) {
      return <img src={URL.createObjectURL(file)} alt={file.name} style={previewStyle} />;
    } else if (file.type.startsWith('video/')) {
      return (
        <video controls style={previewStyle}>
          <source src={URL.createObjectURL(file)} type={file.type} />
        </video>
      );
    } else if (file.type === 'application/pdf') {
      return <PictureAsPdf style={{ fontSize: iconMobile === true ? 30 : 40 }} />;
    } else {
      return <InsertDriveFile style={{ fontSize: iconMobile === true ? 30 : 40 }} />;
    }
  };


  const renderSendMedia = (files, onCancel) => {
    return (
      <Grid container className={classes.sendMediaWrapper} >
        <IconButton
          className={classes.sendMediaIcon}
          style={{ right: 10 }}
          onClick={onCancel}
          disabled={loading}
        >
          <CancelIcon onClick={() => handleRemoveFile(null, true)} />
        </IconButton>
        <Grid className={classes.sendMediaContainer}>
          {files.map((file, index) => (
            <Grid
              key={index}
              style={{
                padding: 16,
                margin: '8px',
                position: 'relative',
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderPreview(file)}
              <Typography variant="body2" style={{ marginTop: 8, fontSize: iconMobile === true ? 10 : 13 }}>
                {file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}
              </Typography>
              <IconButton
                style={{ position: 'absolute', top: -10, right: 5 }}
                onClick={() => handleRemoveFile(file, false)}
                disabled={loading}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
          {loading ? (
            <Grid>
              <CircularProgress size={26} style={{ margin: '0 8px' }} />
            </Grid>
          ) : (
            <IconButton onClick={handleUploadMedia} style={{ margin: '0 8px' }} className={classes.sendMediaIcon}>
              <SendIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  if (whatsapp?.channel === "waba" && !isLastMessageWithin24hours) {
    return (
      <Paper elevation={0} square className={classes.inputTemplateWrapper}>
        <TemplatesModal
          open={templateModalOpen}
          onClose={handleCloseTemplateModal}
          whatsAppId={whatsapp.id}
          ticketId={ticketId}
        />
        <Button target="_blank" size="small" color="primary" onClick={handleOpenTemplateModal}>Enviar modelo</Button>
        <span style={{ textAlign: "center" }}>Após passar 24 horas da última mensagem do contato, só é possível enviar mensagem usando modelo</span>
      </Paper>
    )
  }

  const handleClickOpenBannerModal = () => {
    setBannerModalOpen(true);
  };

  const handleCloseBannerModal = () => {
    setBannerModalOpen(false);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragFile(true);
  };

  if (medias.length < 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  else {
    return (
      <Paper
        square
        elevation={0}
        className={medias.length === 0 ? classes.mainWrapper : 'default'}
        onDragEnter={handleDragEnter}
      >
        {!dragFile && (
          <>
            {replyingMessage && renderReplyingMessage(replyingMessage)}
            {medias.length > 0 && renderSendMedia(medias)}
            <div className={classes.newMessageBox}>
              <BannerModal
                open={bannerModalOpen}
                onClose={handleCloseBannerModal}
                banner={integgriPayBanner}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorElOptions}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "bottom",
                }}
                open={menuOptions}
                onClose={handleCloseMenuOptions}
              >
                <MenuItem>
                  <input
                    multiple
                    type="file"
                    id="upload-button"
                    disabled={loading || recording || ticketStatus !== "open"}
                    className={classes.uploadInput}
                    onChange={handleChangeMedias}
                  />
                  <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }} htmlFor="upload-button">
                    <ListItemIcon className={classes.listItemIcon}>
                      <AttachFileIcon />
                    </ListItemIcon>
                    <ListItemText primary="Arquivo" />
                  </label>
                </MenuItem>
                <MenuItem onClick={handleOpenSendContactModal}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contato" />
                </MenuItem>
              </Menu>
              <Hidden only={["sm", "xs"]}>
                <IconButton
                  aria-label="emojiPicker"
                  component="span"
                  disabled={loading || recording || ticketStatus !== "open"}
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <MoodIcon className={classes.sendMessageIcons} />
                </IconButton>
                {showEmoji ? (
                  <div className={classes.emojiBox}>
                    <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                      <Picker
                        perLine={16}
                        showPreview={false}
                        showSkinTones={false}
                        onSelect={handleAddEmoji}
                      />
                    </ClickAwayListener>
                  </div>
                ) : null}
                <Grid>
                  <IconButton
                    onClick={handleOpenMenuOptions}
                    disabled={loading || recording || ticketStatus !== "open"}
                  >
                    <AddIcon className={classes.sendMessageIcons} />
                  </IconButton>
                  <IconButton
                    onClick={handleClickOpenBannerModal}
                    disabled={loading || recording || ticketStatus !== "open"}
                  >
                    <AttachMoneyIcon className={classes.sendMessageIcons} />
                  </IconButton>
                  <SendContactModal
                    modalOpen={isSendContactModalOpen}
                    onClose={handleCloseSendContactModal}
                    ticketId={ticketId} />
                </Grid>
                <FormControlLabel
                  style={{ marginRight: 7, color: "gray", marginLeft: 5 }}
                  label={i18n.t("messagesInput.signMessage")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={signMessage}
                      onChange={(e) => {
                        setSignMessage(e.target.checked);
                      }}
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              </Hidden>
              <Hidden only={["md", "lg", "xl"]}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleOpenMenuClick}
                >
                  <MoreVert></MoreVert>
                </IconButton>
                <Menu
                  id="simple-menu"
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuItemClick}
                >
                  <MenuItem onClick={handleMenuItemClick}>
                    <IconButton
                      aria-label="emojiPicker"
                      component="span"
                      disabled={loading || recording || ticketStatus !== "open"}
                      onClick={(e) => setShowEmoji((prevState) => !prevState)}
                    >
                      <MoodIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick}>
                    <input
                      multiple
                      type="file"
                      id="upload-button"
                      disabled={loading || recording || ticketStatus !== "open"}
                      className={classes.uploadInput}
                      onChange={handleChangeMedias}
                    />
                    <label htmlFor="upload-button">
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={loading || recording || ticketStatus !== "open"}
                      >
                        <AttachFileIcon className={classes.sendMessageIcons} />
                      </IconButton>
                    </label>
                  </MenuItem>
                  <Grid style={{ marginLeft: 15 }}>
                    <IconButton
                      onClick={handleOpenSendContactModal}
                      disabled={loading || recording || ticketStatus !== "open"}
                    >
                      <ContactPhoneIcon className={classes.sendMessageIcons} />
                    </IconButton>
                    <SendContactModal
                      modalOpen={isSendContactModalOpen}
                      onClose={handleCloseSendContactModal}
                      ticketId={ticketId} />
                  </Grid>
                  <MenuItem onClick={handleMenuItemClick}>
                    <FormControlLabel
                      style={{ marginRight: 7, color: "gray" }}
                      label={i18n.t("messagesInput.signMessage")}
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          checked={signMessage}
                          onChange={(e) => {
                            setSignMessage(e.target.checked);
                          }}
                          name="showAllTickets"
                          color="primary"
                        />
                      }
                    />
                  </MenuItem>
                </Menu>
              </Hidden>
              <div className={classes.messageInputWrapper}>
                <InputBase
                  inputRef={(input) => {
                    input && input.focus();
                    input && (inputRef.current = input);
                  }}
                  className={classes.messageInput}
                  placeholder={
                    ticketStatus === "open"
                      ? i18n.t("messagesInput.placeholderOpen")
                      : i18n.t("messagesInput.placeholderClosed")
                  }
                  multiline
                  maxRows={5}
                  value={inputMessage}
                  onChange={handleChangeInput}
                  onKeyDown={handleKeyPress}
                  disabled={recording || loading || medias.length > 0 || ticketStatus !== "open"}
                  onPaste={(e) => {
                    ticketStatus === "open" && handleInputPaste(e);
                  }}

                />
              </div>
              {inputMessage ? (
                <IconButton
                  aria-label="sendMessage"
                  component="span"
                  onClick={handleSendMessage}
                  disabled={loading}
                >
                  <SendIcon className={classes.sendMessageIcons} />
                </IconButton>
              ) : recording ? (
                <div className={classes.recorderWrapper}>
                  <IconButton
                    aria-label="cancelRecording"
                    component="span"
                    fontSize="large"
                    disabled={loading}
                    onClick={handleCancelAudio}
                  >
                    <HighlightOffIcon className={classes.cancelAudioIcon} />
                  </IconButton>
                  {loading ? (
                    <div>
                      <CircularProgress className={classes.audioLoading} />
                    </div>
                  ) : (
                    <RecordingTimer />
                  )}

                  <IconButton
                    aria-label="sendRecordedAudio"
                    component="span"
                    onClick={handleUploadAudio}
                    disabled={loading}
                  >
                    <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  aria-label="showRecorder"
                  component="span"
                  disabled={loading || ticketStatus !== "open"}
                  onClick={handleStartRecording}
                >
                  <MicIcon className={classes.sendMessageIcons} />
                </IconButton>
              )}
            </div>
          </>
        )}
      </Paper>
    );
  }
};

export default MessageInput;
